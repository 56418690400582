import React from "react";
import {
  InputAdornment,
  TextField,
  IconButton,
  TextareaAutosize,
} from "@mui/material";

function StandardTextfield({
  id,
  label,
  placeholder,
  inputRef,
  name,
  value,
  onChange,
  textFieldWidthClass,
  required,
  multiline,
  minRows,
  maxRows,
  helperText,
  defaultCursor,
  disabled,
  errorBool,
  type,
  textSize,
  size,
  variant,
  onKeyDown,
  sx,
  shrinkLabel,
  readOnly,
  startIcon,
  startIconOnClick,
  endIcon,
  endIconOnClick,
  maxlength,
  charCount,
  width = "100%",
  showIconButton = true,
  isTextareaAutosize,
  minNumberAllowed,
  rows,
  showHelperTextAlways //used to align textfields in table row when one (field) is displaying helper text and another in the same row isn't
}) {
  return (
    <>
      <TextField
        id={id}
        onKeyDown={onKeyDown}
        variant={variant ? variant : "outlined"}
        disabled={disabled}
        inputRef={inputRef}
        type={type ? type : "text"}
        helperText={
          showHelperTextAlways ? helperText :
          charCount && !errorBool ?
            charCount :
            errorBool && helperText !== "required" && helperText
        }
        FormHelperTextProps={{
          style:
            charCount && !errorBool ?
              {
                fontFamily: "Lato",
                fontSize: "1rem",
                fontWeight: "300",
                position: "absolute",
                right: "0",
                top: "15px",
              } :
              {
                fontFamily: "Lato",
                fontSize: "1rem",
                fontWeight: "300",
              },
        }}
        error={errorBool}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        rows={rows}
        required={required}
        placeholder={placeholder}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        size={size}
        className={textFieldWidthClass}
        sx={sx}
        inputProps={{
          style: {
            fontFamily: "Lato",
            fontSize:
              textSize === "large" ?
                "1.4rem" :
                textSize === "small" ?
                  "1.2rem" :
                  "1.32rem",
            fontWeight: "300",
            cursor: defaultCursor ? "default" : "inherit",
            width: width,
            // backgroundColor: "#F8F8F8",
          },
          maxLength: maxlength ? maxlength : null,
        }}
        InputLabelProps={{
          shrink: shrinkLabel,
          style: {
            fontFamily: "Lato",
            fontSize:
              textSize === "large" ?
                "1.4rem" :
                textSize === "small" ?
                  "1.2rem" :
                  "1.32rem",
            fontWeight: "300",
          },
        }}
        InputProps={{
          inputProps: {
            min: minNumberAllowed
          },
          inputComponent: isTextareaAutosize && TextareaAutosize,
          readOnly,
          style: {
            fontFamily: "Lato",
            fontSize:
              textSize === "large" ?
                "1.4rem" :
                textSize === "small" ?
                  "1.2rem" :
                  "1.32rem",
            fontWeight: "300",
            height: textSize === "small" ? "3.5rem" : "fit-content",
          },
          startAdornment: startIcon && (
            <InputAdornment position="start">
              {startIconOnClick && showIconButton ? (
                <IconButton
                  sx={{ height: "2.55rem", width: "2.55rem" }}
                  onClick={startIconOnClick}
                >
                  {startIcon}
                </IconButton>
              ) : (
                <div
                  onClick={startIconOnClick && startIconOnClick}
                  style={{ cursor: startIconOnClick && "pointer", height: "2.55rem", width: "2.55rem" }}
                >
                  {startIcon}
                </div>
              )}
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="start">
              {endIconOnClick && showIconButton ? (
                <IconButton
                  sx={{ height: "2.55rem", width: "2.55rem" }}
                  onClick={endIconOnClick}
                >
                  {endIcon}
                </IconButton>
              ) : (
                <div
                  onClick={endIconOnClick && endIconOnClick}
                  style={{ cursor: endIconOnClick && "pointer", height: "2.55rem", width: "2.55rem" }}
                >
                  {endIcon}
                </div>
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default StandardTextfield;
