import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import PillButton from "../../../Components/Buttons/PillButton";
import StandardTextfield from "../../../Components/Input/TextField";
import authActions from "../store/actions";
import { deleteFromErrors } from "../../../Helpers/helpers";
import { isValidEmail } from "../../../Utility/validations";

function ForgotPassword({ setPage }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.alert.loading);
  const [errors, setErrors] = useState({});
  const [loginInfo, setLoginInfo] = useState({ email: "" });
  const { email } = loginInfo;


  const handleChange = (name, value) => {
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };

  const handleSubmit = () => {
    const tempErrors = { ...errors };
    Object.keys(loginInfo).forEach((field) => {
      if (loginInfo[field] === "") tempErrors[field] = "required";
    });
    if (email.length > 0 && !isValidEmail(email)) tempErrors.email = "Email is invalid";
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    dispatch(authActions.sendResetPasswordLink(email));
  };

  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) handleSubmit();
  };

  return (
    <>
      <Typography variant="h3" sx={{ textAlign: "center" }}>Forgot Password</Typography>
      <Typography variant="caption" sx={{ color: "rgb(112, 112, 112)", textAlign: "center" }}>
        Enter your email to receive reset password link
      </Typography>
      <StandardTextfield
        label="Email"
        value={email}
        onChange={(e) => handleChange("email", e.target.value)}
        errorBool={errors?.email?.length > 0}
        helperText={errors?.email}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption" sx={{ cursor: "pointer", width: "fit-content", mt: 1 }} onClick={() => setPage(1)}>Back to Login?</Typography>
        <PillButton
          onClick={handleSubmit}
          text={isLoading ?
            <CircularProgress size="2rem" sx={{ color: "white" }} /> :
            "Submit"
          } padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }} />
      </Box>
    </>
  );
}

export default ForgotPassword;
