// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: {
    login: "/api/2.0/identities/user/login",
    token: "/api/2.0/identities/user/get-token"
  },
  auth: {
    orgDetails: "/api/2.0/organizations/issuers",
    resetPassword: "/api/2.0/identities/user/secret/reset",
    changePassword: "/api/2.0/identities/user/secret"
  },
  userManagement: {
    profile: "/api/2.0/identities/info",
    users: "/api/2.0/identities",
    roles: "/api/2.0/roles"
  },
  entries: {
    get: "/entries",
    visitor: "/visitor",
    visits:"/visits",
    purposes:"/purposes",
    mock: {
      visitor: "/digital-credentials/student-id"
    },
    graphs: "/entries/graphs"
  },
  zones: {
    get: "/zones",
  },
  device: {
    devices: "/devices",
    assign: "/devices/assign",
    unassign: "/devices/unassign",
    qr:"/devices/qr",
    pin: "/devices/pin",
    sendMessage:"/devices/notify",
  },
  zone: {
    zone:"/zones",
    checkpoint:"/zones/checkpoints",
  },
  notifications: {
    notifications: "/api/2.0/indisi-events/access-control-portal",
    subscribe: "/api/2.0/indisi-events/access-control-portal/subscribe",
    device: "/api/2.0/indisi-event/subscribe/device"
  },
  misc: {
    visitsConfigs: "/visits-configs"
  }
};
