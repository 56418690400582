import React, {useState, useEffect} from "react";
import { AppBar, Box, Button, Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ActualSidebar from "./actualSidebar";

function Sidebar() {
  const theme = useTheme();
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenSidebarDrawer(open);
  };

  useEffect(()=> {
    if (openSidebarDrawer) setOpenSidebarDrawer(false);
  },
  [window.location.pathname]);

  return smallerThanMDScreen ?
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static" sx={{backgroundColor: "background.first", borderRadius: ".8rem"}}>
        <Toolbar>
          <IconButton
            size="medium"
            edge="start"
            sx={{mr: 1}}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{display:'flex'}}>
            <Typography variant="h1" color={"text.regular"} component="div" sx={{ flexGrow: 1 }}>
            trential
          </Typography>
            <Typography variant="h1" color={"rgb(2, 89, 179)"} component="div" sx={{ flexGrow: 1, marginLeft:'1rem' }}>
            access
          </Typography>
          </Box>
          <Button color="inherit" sx={{typography: "h4", fontWeight: "400", color: "text.button"}}>Login</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"left"}
        open={openSidebarDrawer}
        onClose={toggleDrawer(false)}
      >
        <ActualSidebar />;
      </Drawer>
    </Box> :
    <ActualSidebar />;
}

export default Sidebar;
