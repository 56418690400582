import moment from "moment";

export const adjustZone = (zoneInfo) => {

    let zone = {
        ...zoneInfo, accessibility: {
            ...zoneInfo.accessibility,
            time: {
                from: moment(zoneInfo.accessibility.time.from).format('HH:mm'),
                to: moment(zoneInfo.accessibility.time.to).format('HH:mm'),
                timezone: "Asia/Kolkata"
            }
        }
    }
    if (zone.accessibility.dates.frequency == 'custom')
    {
        zone = {
            ...zone, accessibility: {
                ...zone.accessibility,
                dates: {
                    ...zone.accessibility.dates,
                    from: zone.accessibility.dates.from.getTime(),
                    to: zone.accessibility.dates.to.getTime()
                }
            }
        }
    }
    if (zone.accessibility.dates.frequency !== 'custom')
    {
        zone = {
            ...zone, accessibility: {
                ...zone.accessibility,
                dates: {
                    frequency: zone.accessibility.dates.frequency
                }
            }
        }
    }

    // console.info(zone,"ZONE")
    for( let i = 0 ; i < zone.checkpoints.length; i++ ){

        if( zone.checkpoints[i]?.description?.trim() == '' ){
            // console.info('DESC', zone.checkpoints[i]?.description )
            delete zone.checkpoints[i]?.description
        }

    }

    return zone
}