import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../../Assets/svg/downArrow.svg";
import { ReactComponent as TrentialLogo } from "../../../Assets/svg/access-portal-logo.svg";
import { ReactComponent as UpArrow } from "../../../Assets/svg/upArrow.svg";
import { sidebarData } from "../../../Config/data/sidebar";
import styles from "./styles-new";
// import { decodeAccessToken, getAccessToken } from "../../../Utility/tokenUtils";
import Profile from "../../../Containers/Profile";
import "./issuerPopupStyles.css";
import { useSelector } from "react-redux";
import PatchedTooltip from "../../PatchedTooltip";

function ActualSidebar() {
  const auth = useSelector(state => state.auth);
  const {allowedRoutes} = auth ?? [];
  const [active, setActive] = useState([
    "Issuance Services",
    "Verification Services",
  ]);

  const matches = useMediaQuery('(min-width: 1250px)');

  const accessibleSidebarData = useMemo(()=>{
    return sidebarData.map((row) => {
      let isAccessible = false;
      if(allowedRoutes?.includes(row.link)) isAccessible = true;
      return {...row, isAccessible}
    })
  }, [allowedRoutes])

  const isChildActive = (index) => {
    const foundChild = sidebarData[index].children.find(
      (child) => child.link === window.location.pathname
    );
    return foundChild ? true : false;
  };

  const handleOnClick = (row) => {
    if(!row.isAccessible) return;
    if (active.includes(row.label)) {
      setActive((current) =>
        current.filter((value) => value !== row.label)
      );
    } else {
      setActive((oldArray) => [...oldArray, row.label]);
    }
  }

  return (
    <Box sx={styles.outerBox}>
      <Box sx={{ ...styles.topBox, height: matches ? "9%":"5%"}}>
        <TrentialLogo height={matches ? "3rem" : "2rem"} width={matches ? "3rem" : "2rem"} />
        <Box sx={{display:'flex'}}>
        <Typography
          variant={matches ? "h2" : "h3"}
          color="text.regular"
          sx={{ fontWeight: 700, marginRight:'.5rem' }}
        >
          trential
        </Typography>
        <Typography
          variant={matches ? "h2" : "h3"}
          color="rgb(2, 89, 179)"
          sx={{ fontWeight: 700 }}
        >
          access
        </Typography>
        </Box>
        {/* <Divider sx={{marginTop: "1rem", marginBottom: "2.2rem"}} /> */}
      </Box>
      <Box sx={{ padding: "0px 3.5rem" }}>
        <Divider sx={{ marginTop: "1%" }} />
      </Box>
      <div style={styles.middleBox}>
        {accessibleSidebarData.map((row, index) => (
          <div key={row.label} style={{ marginBottom: "2.8rem" }}>
           <PatchedTooltip
            title={!row.isAccessible ? "You don't have permission to use this. Contact Administrator." : ""}
           >
              <Box
                key={row.label}
                style={{ display: "flex", justifyContent: "space-between", cursor: !row.isAccessible ? "not-allowed" : "pointer" }}
                onClick={() => handleOnClick(row)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <Box sx={styles.bullet}>{row.icon}</Box>
                  <div>
                    {row.children ? (
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <Typography
                          variant="h3"
                          color={
                            isChildActive(index) ?
                              "primary.main" :
                              "text.headingLight"
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          {row.label}
                        </Typography>
                      </Box>
                    ) : (
                      <Button
                        style={{ textDecoration: "none"}}
                        LinkComponent={Link}
                        to={row.link}
                        disabled={!row.isAccessible}
                        sx={{
                            "&:hover .routeText": {
                              color:
                                window.location.pathname !== row.link && row.isAccessible &&
                                "primary.light",
                            },
                          }}
                      >
                        <Typography
                          variant="h3"
                          className="routeText"
                          color={
                            window.location.pathname === row.link ?
                              "primary.main" :
                              "text.headingLight"
                          }
                        >
                          {row.label}
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
                <Box sx={{ marginTop: "5px", cursor: "pointer" }}>
                  {row.children ? (
                    active.includes(row.label) ? (
                      <DownArrow />
                    ) : (
                      <UpArrow />
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>

           </PatchedTooltip>
            {row.children && !active.includes(row.label) && (
              <Box sx={styles.children}>
                {row.children.map((child) => (
                  <Link
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    to={child.link}
                    key={child.label}
                  >
                    <Typography
                      variant="h4"
                      color={
                        window.location.pathname === child.link ?
                          "primary.main" :
                          "text.headingLight"
                      }
                      sx={{
                        "&:hover": {
                          color:
                            window.location.pathname !== child.link &&
                            "primary.light",
                        },
                      }}
                    >
                      {child.label}
                    </Typography>
                  </Link>
                ))}
              </Box>
            )}
          </div>
        ))}
      </div>
      <Profile />
    </Box>
  );
}

export default ActualSidebar;
