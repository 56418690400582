import React, { useState, useEffect, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, ClickAwayListener, ListItem, ListItemText, Checkbox, useMediaQuery, useTheme } from "@mui/material"
import PillButton from "../../Components/Buttons/PillButton"
import styles from './styles'
import { ReactComponent as UpArrow } from '../../Assets/svg/upArrow.svg'
import { ReactComponent as DownArrow } from '../../Assets/svg/downArrow.svg'
import AddDeviceModal from "../../Components/Device/AddDeviceModal"
import WarningModal from "../../Components/WarningModal"
import actions from "./store/actions"
import AssignDeviceModal from "../../Components/Device/AssignDeviceModal"
import { useSnackbar } from "notistack"
import DeviceTableRow from "./tableRow"
import mockMapsImage from "../../Assets/img/mockGoogleMaps.png"
import DeviceMap from "./DeviceMap"
import Marker from "./DeviceMap/marker"
import { LightTooltip } from "../../Components/Tooltip";
import { changeToSentenceCase } from "../../Helpers/helpers"
import FilterListIcon from "@mui/icons-material/FilterList";
import NoData from "../../Components/NoData"
import PatchedTooltip from "../../Components/PatchedTooltip"

function DeviceManagement() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const {componentAccessMap} = useSelector(s => s.auth);
  const { devices: devicesData, totalCount } = useSelector(s => s.devices)
  const theme = useTheme();
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [expandBottomDrawer, setExpandBottomDrawer] = useState(false)
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false)
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false)
  const [showNewPinWarning, setShowNewPinWarning] = useState(false)
  const [showUnassignedStatusWarning, setShowUnassignedStatusWarning] = useState(false)
  const [showArchiveDeviceWarning, setShowArchiveDeviceWarning] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const categoryOptions = ['assigned', 'unassigned', 'archived']
  const [category, setCategory] = React.useState(null);
  const [selectedFilter, setselectedFilter] = React.useState([]);
  const columns = ["Devices", "Device ID", "Battery", "Zone", "Checkpoint", "PIN",
    "Status", "Last Updated At", "Actions"]
  const selectedDevice = useRef({})

  const fetchDevices = useCallback((selectedFilter) => {   
    console.info(selectedFilter, 'selectedFilter')
      dispatch(actions.fetchDevices({seed: 0, count: 40, status:selectedFilter}))
    },[dispatch])

  useEffect(() => {
    fetchDevices()
  }, [fetchDevices])


  const handleBeginAction = (device, setter) => {
    selectedDevice.current = { ...device }
    setter(true)
  }

  const clickHandlers = {
    beginAssignDevice: (device) => handleBeginAction(device, setOpenAssignDeviceModal),
    beginGeneratePin: (device) => handleBeginAction(device, setShowNewPinWarning),
    beginArchiveDevice: (device) => handleBeginAction(device, setShowArchiveDeviceWarning),
    beginUnassignDevice: (device) => handleBeginAction(device, setShowUnassignedStatusWarning),
    beginShowMessage: (device) => handleBeginAction(device, setShowSendMessage)
  }

  const handleArchiveStatus = () => {
    let updatedStatus = ""
    if (selectedDevice.current.status !== "Archived") updatedStatus = "archive"
    else
    {
      // if(selectedDevice.current.zone && selectedDevice.current.checkpoint) updatedStatus = "assigned"
      // else 
      updatedStatus = "unarchive"
    }
    dispatch(actions.updateDeviceStatus(selectedDevice.current.deviceId, updatedStatus)).then(res => {
      setShowArchiveDeviceWarning(false)
      if (res === "success")
      {
        fetchDevices(selectedFilter)
        enqueueSnackbar(`Device ${updatedStatus === "archive" ? "Archived" : "Unarchived"}!`, {
          variant: "success"
        })
      }
    })
  }

  const handleSendMessage = (message) => {
    dispatch(actions.sendMessage(selectedDevice.current.deviceId, message)).then(res => {
      setShowSendMessage(false)
      if (res === "success")
      {
        // fetchDevices()
        enqueueSnackbar(`Message sent successfully!`, {
          variant: "success"
        })
      }
    })
  }

  const handleUnassignDevice = () => {
    const reqData = {
      device: selectedDevice.current._id
    }
    dispatch(actions.updateAssignedStatus(false, reqData)).then((res) => {
      setShowUnassignedStatusWarning(false)
      if (res === "success")
      {
        fetchDevices(selectedFilter)
        enqueueSnackbar("Device unassigned!", {
          variant: "success"
        })
      }
    })
  }

  const handleGeneratePin = () => {
    dispatch(actions.generateDevicePin(selectedDevice.current.deviceId)).then(res => {
      setShowNewPinWarning(false)
      if (res.status === "success")
      {
        fetchDevices(selectedFilter)
        enqueueSnackbar("New PIN generated!", {
          variant: "success"
        })
      }
    })
  }

  const handleFilter = (e) => {
    let tempFilterArr = [...selectedFilter];
    if (e.target.checked === true)
    {
      tempFilterArr.push(e.target.value);
      setselectedFilter(tempFilterArr);
    } else
    {
      const filtered = selectedFilter.filter((option) => {
        return option !== e.target.value;
      });
      tempFilterArr = filtered;
      setselectedFilter(tempFilterArr);
    }
  };


  useEffect(() => {

    // console.info(selectedFilter,"SF")
    dispatch(actions.fetchDevices({ seed: 0, count: 30, status: selectedFilter }))

  }, [selectedFilter])
  


  return (
    <Box sx={styles.outerBox}>
      {
        showNewPinWarning &&
        <WarningModal
          open={showNewPinWarning}
          setOpen={setShowNewPinWarning}
          subtitle={"Update PIN"}
          caption={"Regenerating the PIN will log the user out of the app and they will have to login using the new PIN. Are you sure you want to proceed?"}
          handler={handleGeneratePin}
        />
      }
      {
        showArchiveDeviceWarning &&
        <WarningModal
          open={showArchiveDeviceWarning}
          setOpen={setShowArchiveDeviceWarning}
          subtitle={`${selectedDevice.current.status === "Archived" ? "Unarchive" : "Archive"} Device`}
          caption={`Are you sure you want to ${selectedDevice.current.status === "Archived" ? "unarchive" : "archive"} this device?`}
          handler={handleArchiveStatus}
        />
      }

      {
        showSendMessage &&
        <WarningModal
          open={showSendMessage}
          setOpen={setShowSendMessage}
          subtitle={`Send Message`}
          caption={`Please input the message you want to send below.`}
          handler={(message) => handleSendMessage(message)}
          type={'edit'}
        />
      }


      {
        showUnassignedStatusWarning &&
        <WarningModal
          open={showUnassignedStatusWarning}
          setOpen={setShowUnassignedStatusWarning}
          subtitle={"Unassign Device"}
          caption={"Are you sure you want to unassign this device?"}
          handler={handleUnassignDevice}
        />
      }


      {
        openAddDeviceModal &&
        <AddDeviceModal
          open={openAddDeviceModal}
          setOpen={setOpenAddDeviceModal}
        />
      }
      {
        openAssignDeviceModal &&
        <AssignDeviceModal
          open={openAssignDeviceModal}
          setOpen={setOpenAssignDeviceModal}
          device={selectedDevice.current}
          selectedFilter={selectedFilter}
        />
      }
      <Box sx={styles.addButtonWrapper}>
      <PatchedTooltip
        title={!componentAccessMap?.devices?.addNew ? "You don't have permission to use this. Contact Administrator" : ""}
      >
        <PillButton
          text={"Add a New Device"}
          onClick={() => {
            if (!componentAccessMap?.devices?.addNew) return;
            setOpenAddDeviceModal(true)
          }}
          disabled={!componentAccessMap?.devices?.addNew}
        />
      </PatchedTooltip>
      </Box>
      <div style={styles.container}>
        <div style={styles.content}>
          <DeviceMap
            devices={devicesData}
            generatePin={clickHandlers.beginGeneratePin}
            handleAssign={clickHandlers.beginAssignDevice}
            handleUnassign={clickHandlers.beginUnassignDevice}
          />
        </div>
        <div
          style={expandBottomDrawer ? styles.expandedBottomDrawer : styles.bottomDrawer}
        >
          <div style={styles.iconButtonBox}>
            <IconButton
              onClick={() => setExpandBottomDrawer(prev => !prev)}
              sx={styles.iconButton}
            >
              {
                expandBottomDrawer ?
                  <DownArrow /> :
                  <UpArrow />
              }
            </IconButton>
          </div>
          <div
            style={{...styles.tableWrapper, overflowY: expandBottomDrawer ? "auto" : "hidden"}}
          >
            <Table
              size="small"
              stickyHeader
            >
              <colgroup>
                  <col width="6.8%" />
                  <col width="8%" />
                  <col width="9%" />
                  <col width="9%" />
                  <col width="10%" />
                  <col width="14%" />
                  <col width="12%" />
                  <col width="12%" />
                  <col width="5%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columns.map((title, index) => (
                    <TableCell
                      key={index}
                      sx={{ padding: ".7rem .5rem", borderColor: "border.main" }}
                    >
                    <Box sx={{display:"flex", justifyContent:'center'}}>

                        {title === "Actions" ? "" :
                        title === "Status"?
                              <>
                              { selectedFilter?.length > 0 &&
                              <Box sx={{ height: "1.6rem", width: "1.6rem", backgroundColor: "primary.main", borderRadius: "50%", display: "grid", placeItems: "center", marginRight: '1rem',marginTop:'2px' }}>
                                <Typography variant="caption" color="#ffffff">{selectedFilter?.length}</Typography>
                              </Box>}
                              <Typography
                                variant="h4"
                                color="text.heading"
                                sx={{ fontWeight: "700", margin: "auto 0", whiteSpace: 'nowrap' }}
                              >
                              {title}
                              </Typography>
                              </>
                        :
                            <Typography
                              variant="h4"
                              color="text.heading"
                              sx={{ fontWeight: "700", margin: "auto 0", whiteSpace: 'nowrap' }}
                            >
                        {title}
                            </Typography>
                        }
                   
                      {
                        title == 'Status' &&
                        <ClickAwayListener onClickAway={() => setShowFilterOptions(false)}>
                          <Box mr={1}>
                            <LightTooltip
                              open={showFilterOptions}
                              title={
                                <div style={{ padding: "1rem" }}>
                                  <div style={styles.filterOptionBox}>
                                    {categoryOptions.map((option, index) =>
                                      <React.Fragment key={index}>
                                        <ListItem key={index} sx={{ padding: 0 }}>
                                          <ListItemText primary={
                                            <div style={styles.statusWrapper}>
                                              <Box
                                                sx={{
                                                  ...styles.statusDot,
                                                  backgroundColor: option == "unassigned" ? "success.bright" : option == "assigned" ? "error.regular" : option == "archived" ? "primary.main" : "border.main"
                                                }}
                                              />
                                            <Typography
                                              key={index}
                                              variant="subtitle1"
                                              color={selectedFilter.includes(option) ? "primary.light" : "text.heading"}
                                              sx={styles.sortOption}
                                              ml={1}
                                              mr={2}
                                            >
                                                {changeToSentenceCase(option)}
                                              </Typography>
                                            </div>
                                          } sx={{ fontSize: "1.12rem" }} 
                                          />
                                          <Checkbox
                                            checked={selectedFilter.includes(option) ? true : false}
                                            name="Filter"
                                            onChange={handleFilter}
                                            value={option}
                                            color="primary"
                                            inputProps={{ "aria-label": "option checkbox" }}
                                            sx={{ padding: 0 }}
                                          />
                                        </ListItem>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>}
                              placement="bottom"
                              arrow>
                                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} ml={1} mt={.2}>
                                    <IconButton color="inherit" onClick={() => setShowFilterOptions(true)}>
                                      <FilterListIcon  sx={{ width: '1.5rem', height: '1.6rem' }} />
                                    </IconButton>
                                  </Box>
                            </LightTooltip>
                          </Box>
                        </ClickAwayListener>
                      }
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {1 === 2 ?
                  [...Array(10)].map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((key) => (
                        <TableCell
                          align="left"
                          key={key}
                          sx={{ padding: ".75rem" }}
                        >
                          <Skeleton animation="wave" sx={styles.skeleton} />
                        </TableCell>
                      ))}
                    </TableRow>
                  )) :
                  totalCount !== 0 &&
                  devicesData?.map((row, index) => {
                    return (
                      <DeviceTableRow
                        key={row._id}
                        row={row}
                        clickHandlers={clickHandlers}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            {
              totalCount === 0 && 
              <div style={{position: "absolute"}}>
               <NoData size={smallerThanSMScreen? "60%":"25%"} />
              </div>
            }
          </div>
        </div>
      </div>
    </Box>
  )
}

export default DeviceManagement