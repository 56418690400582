/* eslint-disable */
import * as actionTypes from "./actionTypes";

let initialState = {
  loading: false,
  success: false,
  error: {}
};

const setLoading = (state, action) => {
  return { ...state, loading: action.data };
};

const setAlert = (state, action) => {
  if(action.data.success !== undefined) return {...state, success: action.data.success}
  else return {...state, error: action.data}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_ALERT:
      return setAlert(state, action);      
    default:
      return state;
  }
};
