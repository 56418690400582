import React, { useEffect } from "react";
import { TextField, Autocomplete, InputAdornment, CircularProgress, Typography } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

function AutocompleteField({
  options,
  name,
  placeholder,
  selectedValue,
  fieldValue,
  size,
  onValueChange,
  onFieldInputChange,
  getOptionLabel,
  renderOption,
  errorBool,
  helperText,
  freesolo,
  disabled,
  isLoading,
  filterOptions,
  filterSelectedOptions,
  variant,
  label,
  hasDebouncedSearch,
  fieldValueCompareText,
  getData,
  getDataParams,
  sx,
  shrinkLabel,
  heightDropdown,
  onOpenDropdown,
  isOptionEqualToValue
}) {
  useEffect(() => {
    if (hasDebouncedSearch && fieldValue === fieldValueCompareText) return; // don't search when the user clicks on a dropdown value to select it
    if (fieldValue?.length >=2 && hasDebouncedSearch) {
      debouncedSchemaSearch();
    }
  }, [fieldValue]);

  const debouncedSchemaSearch = useDebouncedCallback(
    (params) => {
      if (fieldValue.length >= 2 && hasDebouncedSearch) getData(getDataParams);
    },
    // delay in ms
    700
  );

  return (
    <Autocomplete
      onOpen={onOpenDropdown}
      disabled={disabled}
      size={size}
      name={name}
      freeSolo={freesolo}
      disablePortal
      options={options}
      noOptionsText={<Typography variant="subtitle2">No options</Typography>}
      ListboxProps={{
        sx: { fontSize: size === "small" ? ".92rem" : "1.3rem", maxHeight: heightDropdown ? heightDropdown : "auto" },
      }}
      value={selectedValue}
      onChange={onValueChange}
      inputValue={fieldValue}
      onInputChange={onFieldInputChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => {
        if(isOptionEqualToValue) return isOptionEqualToValue(option, value)
        if (value === undefined || value === "" || value === null) return true;
        return option === value;
      }}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset > legend > span": {
                // fontSize: 'var(--body-font-size-small)',
                paddingRight:'1px !important',
              }
            }
          }}
          variant={variant ?? "filled"}
          size={size ?? "medium"}
          inputProps={{
            ...params.inputProps,
            style: {
              fontSize: size === "small" ? "var(--body-font-size-small)" : "var(--body-font-size-mid)",
              fontWeight: "300",
              height: "1.9rem",
            },
          }}
          label={label}
          placeholder={placeholder}
          error={errorBool}
          helperText={!["required", "Required"].includes(helperText) && helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={"1.25rem"} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            style: {
              fontSize: size === "large" ? "1.4rem" : size === "small" ? "1.2rem" : "1.32rem",
              fontWeight: "300",
            },
          }}
          InputLabelProps={{
            shrink: shrinkLabel,
            sx: {
              fontSize: 'var(--body-font-size-small)',
              fontWeight: 400,
              marginLeft:'.2rem'
            },
          }}
        />
      )}
    />
  );
}

export default AutocompleteField;
