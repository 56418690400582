import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from '@mui/icons-material/Close';

function DateTimeField({ label, value, error, disabled, disablePast, disableFuture, minDate, maxDate, handleChange, shrinkLabel, sx = {}, minDateTime, maxDateTime, minTime,id, endIcon, endIconOnClick, showIconButton }) {

  const [openPicker, setOpenPicker] = useState(false); 
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value || null}
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        ampm={false} 
        inputFormat='dd-MM-yyyy HH:mm'
        onChange={handleChange}
        minTime={minTime}
        open={openPicker}
        onClose={()=>{
          if(openPicker) setOpenPicker(false)
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            id={id}
            sx={sx}
            error={error}
            onClick={(e) => {
              if(!disabled) setOpenPicker(true)
              }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: {
                fontFamily: "Lato",
                fontSize: 'var(--body-font-size-small)',
                cursor: disabled ? "default" : "pointer" 
                // fontSize: "1.32rem",
              },
            }}
            placeholder=""
            InputLabelProps={{
              shrink: shrinkLabel,
              style: {
                fontFamily: "Lato",
                fontSize: 'var(--body-font-size-small)',
                fontWeight: 400,
                paddingTop: '1px',
                paddingLeft:'.5rem'
                // fontSize: "1.32rem",
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontFamily: "Lato",
                // fontSize: "1.32rem",
              },
              endAdornment: endIcon && (
            <InputAdornment position="start">
              {endIconOnClick && showIconButton ? (
                <IconButton
                  sx={{ height: "2.55rem", width: "2.55rem" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(endIconOnClick) endIconOnClick();
                    }}
                >
                  {endIcon}
                </IconButton>
              ) : (
                <div
                    onClick={(e) => {
                    e.stopPropagation();
                    if(endIconOnClick) endIconOnClick();
                    }}
                  style={{ cursor: endIconOnClick && "pointer" }}
                >
                  {endIcon}
                </div>
              )}
            </InputAdornment>
          ),
            }}
          />}
      />
    </LocalizationProvider>
  );
}

export default DateTimeField;
