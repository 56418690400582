import React, { Fragment } from "react";
import styles from "./styles";
import { Box, Typography, FormControlLabel, FormControl, Radio, RadioGroup } from "@mui/material"; 

export default function RadioButton(props) {
    const { group, groupItems, checkedDefault, value, setValue } = props;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div>
            {groupItems.length && (
                <FormControl component="fieldset">
                    <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>{group}</Typography>
                    </Box>
                    <RadioGroup
                        aria-label={group}
                        name={group}
                        value={value}
                        onChange={handleChange}
                    >
                        {groupItems.map((item, index) => (
                            <Fragment key={index}>
                                <FormControlLabel
                                    value={item.name}
                                    control={<Radio size="small" />}
                                    label={<Typography sx={{ color: '#202B67'}} variant='subtitle1'>{item.label}</Typography>}
                                    disabled={item.disabled && "disabled"}
                                />
                            { item.showInfo &&   <label className={styles.informationControl}>{item.information}</label>}
                            </Fragment>
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        </div>
    );
}

