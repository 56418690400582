import React, { useEffect, useState } from "react";
import styles from "../../Components/Common/sidebar/styles-new";
import { useStore,useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import sampleUserImage from "../../../src/Assets/img/sampleUser.jpeg";
import { ReactComponent as ProfileIcon } from "../../../src/Assets/svg/sidebar-profileIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../src/Assets/svg/sidebarLogoutIcon.svg";
import { ReactComponent as NotificationIcon } from "../../../src/Assets/svg/sidebar-otificationIcon.svg";
import authActions from "../Auth/store/actions";
import { fetchOrgData } from "../../APIs/getOrgData";
import NotificationsModal from "../../Components/NotificationsModal";

function Profile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const store = useStore();
  const [openIssuerPopup, setOpenIssuerPopup] = useState(true);
  const [openNotificationsModal, setOpenNotificationsModal] = useState(false)
  const unreadCount = useSelector(state => state.notifications.unreadCount)

  const IssuerPopupRow = ({rowIcon, text, onClick}) =>
    <Box onClick={onClick} sx={styles.issuerPopupRow}>
      <div style={{display: "flex", columnGap: ".75rem"}}>
        {rowIcon}
        <Typography variant="subtitle2">{text}</Typography>
      </div>
      {
        text === "Notifications" && unreadCount > 0 &&
        <Box sx={{height: "2.3rem", width: "2.3rem", backgroundColor: "error.regular", borderRadius: "50%", display: "grid", placeItems: "center"}}>
        <Typography variant="caption">{unreadCount}</Typography>
      </Box>
      }
    </Box>;

  const getClippedEmail = () => {
    const email = auth?.email;
    if (email?.length >= 25) return `${email?.slice(0, 25)}...`;
    else return email;
  };

  useEffect(()=>{
    dispatch(fetchOrgData());
  }, []);

  return (
    <>
     {
      openNotificationsModal && 
      <NotificationsModal
        open={openNotificationsModal}
        setOpen={setOpenNotificationsModal}      
      />
     }
    <Box sx={styles.bottomBox}>
      <Box className={openIssuerPopup ? "issuerPopup issuerPopup-active" : "issuerPopup"}>
        {openIssuerPopup &&
                    <>
                        <IssuerPopupRow
                        rowIcon={<NotificationIcon style={styles.popupIcon}  />}
                        text={"Notifications"}
                        onClick={() => {
                          setOpenNotificationsModal(true)
                        }}
                        />
                        <IssuerPopupRow
                        rowIcon={<ProfileIcon style={styles.popupIcon} />}
                        text={"Profile"}
                        onClick={() => {
                        history.push("/edit-profile");
                        }}
                        />
                       <IssuerPopupRow
                        rowIcon={<LogoutIcon style={styles.popupIcon} />}
                        text={"Logout"}
                        onClick={() => {
                        dispatch(authActions.logout());
                        window.clearInterval(window.intervalId);
                        }}
                       />
                        <Divider sx={{backgroundColor: "#fff", width: "100%"}} />
                    </>
        }
      </Box>
      <Box sx={styles.innerBottomBox}
      //  onClick={() => setOpenIssuerPopup(!openIssuerPopup)}
       >
        <div>
          <Typography variant="subtitle1" color="text.button" sx={{ marginBottom: ".2rem" }}>SIS Admin</Typography>
          <Tooltip title={auth?.email?.length >= 25 ? auth?.email : "" }>
            <Typography variant="h4" color="text.button">{getClippedEmail()}</Typography>
          </Tooltip>
        </div>
        <img src={auth?.logo ? auth?.logo : sampleUserImage}
          alt={"user"} style={{ width: "3.5rem", height: "3.5rem", objectFit: "cover", borderRadius: "50%" }} />
      </Box>
    </Box>
    </>
  );
}

export default Profile;
