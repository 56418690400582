import { Box, Typography } from "@mui/material";
import React, { useState, memo } from "react";
import styles from "./styles";
import ZonesCard from "./zone/zonesCard";


function Card(props) {
  const { data, cardType, fetchPageData, clickHandlers } = props;

  // const handleOnClick = () => {
  //   if (clickHandlers.card) clickHandlers.card(data);
  // };

  const getOuterBoxStyle = () => {
    const bgColor = "background.first";
    return { ...styles.outer, backgroundColor: bgColor };
  };

  let cardComponent = () => <></>;

  switch (cardType)
  {
    case "zones":
      cardComponent = <ZonesCard {...props} />;
      break;
  }

  return (
    <Box sx={getOuterBoxStyle}
    // onClick={handleOnClick}
    >
      {cardComponent}
    </Box>
  );
}

export default memo(Card);
