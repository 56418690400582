import { Button } from "@mui/material";

function PillButton({ text, onClick, startIcon, invertedColor, disabled, padding, shadowVariant, borderRadius, transparent, color, border, backgroundColor, disableHover, typography, sx = {} }) {
  const getPadding = () => {
    let updatedPadding = ".75rem 2.2rem";
    if (padding) {
      if (padding.vertical && !padding.horizontal) updatedPadding = `${padding.vertical} 2.2rem`;
      else if (padding.horizontal && !padding.vertical) updatedPadding = `.75rem ${padding.horizontal}`;
      else if (padding.vertical && padding.horizontal) updatedPadding = `${padding.vertical} ${padding.horizontal}`;
    }
    return updatedPadding;
  };

  return (
    <Button
      sx={{
        "width": "fit-content",
        "textTransform": "none",
        "borderRadius": borderRadius ?? "2rem",
        "padding": getPadding(),
        "typography": typography ?? "h4",
        "color": color ? color : invertedColor ? "primary.main" : shadowVariant ? "text.heading" : "text.button",
        "fontWeight": 700,
        fontSize: 'var(--body-font-size-large)',
        fontFamily:"Lato",
        "backgroundColor": backgroundColor ? backgroundColor : transparent ? "transparent" : disabled ? "gray" : (invertedColor || shadowVariant) ? "background.first" : "primary.main",
        "transition": "all .2s ease-out",
        "boxShadow": shadowVariant ? "0px 1px 1px rgba(0, 0, 0, 0.3), 0px 1px 1px 1px rgba(0, 0, 0, 0.1)" : "inherit",
        "border": shadowVariant ? "none" : border ? border : ".5px solid",
        "&:hover": disableHover ? {
          backgroundColor: backgroundColor ?? "inherit",
          boxShadow: "none"
        } :
          {
            "backgroundColor": transparent ? "transparent" : disabled ? "grey" : invertedColor ? "background.second" : shadowVariant ? "background.first" : "primary.light",
            "boxShadow": shadowVariant ? "0px 2px 3px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.1)" : "inherit",
            "border": shadowVariant ? "none" : border ? border : ".5px solid",
          },
        ...sx
      }}
      startIcon={startIcon ?? null}
      onClick={onClick}
      variant={invertedColor ? "outlined" : "contained"}
      disabled={disabled}
    >{text}
    </Button>
  );
}

export default PillButton;
