import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { Button, Box, Divider, Typography, useTheme, useMediaQuery, Stack, IconButton, Chip, Collapse } from "@mui/material";
import DashboardImage from "../../Assets/svg/dashboardArt.svg";
import styles from "./styles";
import PillButton from "../../Components/Buttons/PillButton";
import "./styles.css";
import { ContentCopy, Close } from "@mui/icons-material";
import ChipSelect from "../../Components/Input/ChipInput/chip";
import EntryManagement from "./entryManagement";
import ChangePassword from "./ChangePassword";

function EditProfile() {
  const name = "User";
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useSelector((state) => state.auth);
  const {componentAccessMap} = auth;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updatedImageUrl, setUpdatedImageUrl] = React.useState(auth?.logo);
  const [openPasswordModal, setopenPasswordModal] = useState(false);

  console.info(auth,"AUTH")

  const [accountFields, updateAccountFields] = useState({
    accountName: auth?.name,
    organisationName: auth?.orgName,
    userEmail: auth?.email,
    organisationID: auth?.id
  });

  const copy = async (data) => {
    try
    {
      await navigator.clipboard.writeText(data);
      const msg = "Organisation UID copied to clipboard.";
      enqueueSnackbar(
        msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1)),
        {
          variant: "success",
          style: { overflowWrap: "anywhere", zIndex: "9999999 !important" },
          className: "snackbar",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          action: (key) => (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <Close fontSize="small" />
            </IconButton>
          ),
        }
      );
    } catch (err)
    {
      console.error("Failed to copy: ", err);
    }
  };

  const staticOrgFields = [
    {
      name: "Account Name",
      value: accountFields.accountName
    },
    {
      name: "Organization Name",
      value: accountFields.organisationName
    },
    {
      name: "User Email",
      value: accountFields.userEmail
    }
  ]

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", rowGap: "1.5%" }}>
      {/* //this box can be refactored into a common component. */}
      {/* {!smallerThanSMScreen &&
        <Box sx={styles.topBox}>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "15%", height: "100%", justifyContent: "center" }}>
            <div>
              <Typography color={"text.heading"} variant="h2" sx={{ marginBottom: ".75rem" }}>{`Hi ${name}`}</Typography>
              <Typography color={"text.heading"} variant="subtitle1">Welcome to Access Control Portal</Typography>
            </div>
          </div>
          <img src={DashboardImage} alt="dashboard_img" style={{ height: "110%" }} />
        </Box>
      } */}
      <Box sx={styles.bottomBox}>
        <Box sx={{ marginBottom: "2%", padding: "0 1.4rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2" color="text.heading" sx={{ marginTop: "15px" }}>Account Details</Typography>
            {/* <PillButton text="Edit Profile" shadowVariant="white" transparent={true} /> */}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Stack
            direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
            justifyContent="space-between"
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Box>
              {
                staticOrgFields.map(row => 
                 <Box sx={styles.detailItemBox}>
                  <Typography variant="h4" sx={styles.detailHeading}>{row.name}</Typography>
                  <Typography variant="h4" sx={styles.disabledDetail}>{row.value}</Typography>
                 </Box>  
                )
              }
              {/* <Box sx={styles.detailItemBox}>
                <Typography variant="h4" sx={styles.detailHeading}>Password</Typography>
                <Stack
                  direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
                  justifyContent="space-between"
                >
                  <Typography variant="h4">
                    <input type="text" style={styles.disabledDetail} value={accountFields.password} disabled='true' />
                  </Typography>
                  <Button sx={styles.passwordChange} onClick={() => {
                    console.info("CLICKED");
                  }}>Change Password</Button>
                </Stack>
              </Box> */}
              {/* <Box sx={styles.detailItemBox}>
                <Typography variant="h4" sx={styles.detailHeading}>Contact Number</Typography>
                <Typography variant="h4">
                  <input type="text" style={styles.disabledDetail} value={accountFields.contactNumber} disabled='true' />
                </Typography>
              </Box> */}
              <Box sx={styles.detailItemBox}>
                <Typography variant="h4" sx={styles.detailHeading}>Organisation ID</Typography>
                <Box display="flex">
                  <Typography variant="h4" sx={styles.disabledDetail}>
                    {accountFields.organisationID}
                  </Typography>
                  <IconButton sx={{marginTop:'-.5rem'}} aria-label="copy-clipboard" size="small" color="primary" onClick={() => copy(accountFields.organisationID)}>
                    <ContentCopy fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={styles.detailItemBox}>
                <Box>
                  <Typography variant="h4" sx={{ ...styles.detailHeading, width: smallerThanSMScreen ? "50px" : "170px" }}>Password</Typography>
                </Box>
                <Box sx={styles.disabledDetail}>
                  {/* <input type="text" style={styles.disabledDetail} value={accountFields.password} disabled='true' /> */}
                  {
                    [...Array(8)].map((x, i) =>
                      <span key={i + 1} className="dot" />
                    )}
                  <Button sx={styles.passwordChange} onClick={() => {
                    setopenPasswordModal(true);
                  }}>Change Password</Button>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.imageBox}>
              <Box className="container">
                <img src={updatedImageUrl} alt="dashboard_img" width="200px" height="200px" />
                {/* <Box className="overlay"></Box>
                <Box className="button">
                  <PillButton transparent color='white' text="Edit Image" shadowVariant onClick={triggerClick} border />
                  <input ref={fileRef} id="photo-upload" type="file" onChange={(e) => {
                    setUpdatedImageUrl(URL.createObjectURL(e.target.files[0]));
                  }} accept="image/png, image/jpeg,image/jpg"
                    hidden />
                </Box> */}
              </Box>
            </Box>
          </Stack>
        </Box>
        <EntryManagement />
      </Box>
      {
        openPasswordModal &&
        <ChangePassword
          open={openPasswordModal}
          setOpen={setopenPasswordModal}
        // data={}
        />
      }
    </Box>
  );
}

export default EditProfile;
