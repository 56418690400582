/* eslint-disable import/no-anonymous-default-export */
export default {
    outerBox: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: "1.5%",
        overflowY: "auto",
    },
    leftMiddleBox: {
        // border: ".1rem solid #DADADA",
        // borderRadius: ".8rem",
        height: {
            xs: "fit-content",
            md: "100%"
        },
        overflowY: {
            xs: "visible",
            md: "auto"
        },
        width: {
            sm: "100%",
            md: "60%"
        },
        display: "flex",
        flexFlow: "column",
        rowGap: "1rem"
    },
    zoneStatsBox: {
        border: "1px solid",
        borderColor: "border.main",
        backgroundColor: "background.first",
        padding: "1.65rem",
        display: "flex",
        flexFlow: "column",
        rowGap: "1.5rem"
    },
    rightMiddleBox: {
        display: "flex",
        flexFlow: "column",
        paddingRight:{
            sm: 0,
            md: '1.75rem'
        },
        height: {
            sm: "fit-content",
            md: "98%",
        },
        right: 0,
        position: {
            sm: "static",
            md: "fixed"
        },
        width: {
            sm: "100%",
            md: "31.6%"
        }

    },
    devicesContainer: {
        border: ".1rem solid #DADADA",
        borderRadius: ".8rem",
        padding: "1.75rem",
        backgroundColor: "background.first",
        // width: {
        //     sm: "100%",
        //     md: "40%"
        // },
    },
    notificationContainer: {
        border: ".1rem solid #DADADA",
        borderRadius: ".8rem",
        backgroundColor: "background.first",
        minHeight: 0,
        flex: "1"
        // width: {
        //     sm: "100%",
        //     md: "40%"
        // },
    },
    innerRightMiddleBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    notificationsOuterBox: {
        display: "flex",
        flexFlow: "column",
        minHeight: 0,
        flex: 1,
        overflowY: "auto"
    },
    deviceInfo: {
        display: "flex",
        justifyContent: "space-evenly",
        margin:'20px 0px'
    },
    deviceIndividualInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    deviceValue:{
        textAlign: "center",
        color:'#013366'
    },
    buttonsDevices: {
        display: "flex",
        columnGap: "2rem",
        justifyContent: "flex-end",
        width: "100%",
    },
    buttonsPin: {
        display: "flex",
        columnGap: "2rem",
        justifyContent: "space-between",
        width: "100%",
        marginTop: '2rem'
    },
    pinWrapper: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
    },
    pinLeft: {
        display: "flex",
        columnGap: ".7rem",
        width: "5.5rem"
    },
    statusDot: {
        height: ".82rem",
        width: ".82rem",
        backgroundColor: "red",
        borderRadius: "50%"
    },
    iconButton: {
        height: "2.8rem",
        width: "2.8rem",
    },
    icon: {
        height: "1.75rem",
        width: "1.75rem",
        color: "#888888"
    },
    dataColumn: {
        display: "flex", 
        flexFlow: "column", 
        rowGap: "1rem"
    },
    zoneInfo: {
        display: "flex", 
        justifyContent: "space-between", 
        padding: "0 .5rem"
    },
    zoneStats: {
        display: "flex", 
        justifyContent: "space-between", 
        width: "59%"
   }
}