import { useMemo } from 'react';
import { Box, Button, IconButton, Menu, TableCell, Typography, Divider } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './styles';
import CustomSwitch from '../../Components/Switch';
import Pin from '../../Components/Pin';
import Battery from '../../Components/Battery';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import PatchedTooltip from '../../Components/PatchedTooltip';
import PillButton from '../../Components/Buttons/PillButton';

function DeviceTableCell({ info, infoKey, clickHandlers, states, setters, appVersion }) {

  console.info(infoKey, 'info-key')
  const { showPin, archiveAnchorEl } = states
  const { setShowPin, setArchiveAnchorEl } = setters
  const { componentAccessMap } = useSelector(s => s.auth);

  const statusColor = useMemo(() => {
    let color = ""
    if (info?.status === "Unassigned") color = "success.bright"
    else if (info?.status === "Assigned") color = "error.regular"
    else if (info?.status === "Archived") color = "primary.main"
    else color = "border.main"
    return color
  }, [info?.status])

  const showActions = Boolean(archiveAnchorEl);

  const {
    beginAssignDevice,
    beginGeneratePin,
    beginArchiveDevice,
    beginUnassignDevice,
    beginShowMessage
  } = clickHandlers

  let Component = null

  if (infoKey === "_id") return null
  if (infoKey === "signedIn") return null

  switch (infoKey)
  {
    case "assignedStatus":
      Component = (
        <PatchedTooltip
          title={(info[infoKey] ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign) ? "You don't have permission to use this. Contact Administrator" : ""}
        >
          <CustomSwitch
            size="small"
            disabled={info.status === "Archived" || (info[infoKey] ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign)}
            checked={info[infoKey]}
            onClick={() => {
              if (info.status === "Archived" || (info[infoKey] ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign))
              {
                return;
              }
              // if(info.zone && info.checkpoint){
              if (info[infoKey])
              {
                beginUnassignDevice(info)
              } else
              {
                beginAssignDevice(info)
              }
            }}
          />
        </PatchedTooltip>
      )
      break;

    case "battery":
      Component = (
        <Battery value={info?.battery} status={info.status} />
      )
      break;

    case "pin":
      Component = (
        <Pin
          info={info}
          generate={beginGeneratePin}
          disableUpdatePIN={!componentAccessMap?.devices?.editActions}
        />
      )
      break;

    case "status":
      Component = (
        <>
          <div
            style={styles.statusWrapper}>
            <Box
              sx={{
                ...styles.statusDot,
                backgroundColor: statusColor
              }}
            />
            <Typography
              variant="h4"
              sx={{ fontWeight: "400" }}
            >
              {info[infoKey] ?? "-"}
            </Typography>
          </div>
          <Box sx={{ borderRadius: '1rem', background: info?.signedIn ? '#EAF4FF' : '#F0F0F0', color: '#fff', padding: '.3rem 1rem' }} ml={1}>
            {
              info?.signedIn ?
                <Typography variant='caption' sx={{ cursor: 'default' }} color='#073B72'>Signed In</Typography> :
                <Typography variant='caption' sx={{ cursor: 'default' }} color='#404052'>Signed Out</Typography>
            }
          </Box>
        </>
      )
      break;

    case "actions":
      Component = (
        <>
          {
            info.status == "Archived" ?

              <UnarchiveOutlinedIcon color="primary" onClick={() => {
                setArchiveAnchorEl(null)
                if (showPin) setShowPin(false)
                beginArchiveDevice(info)
              }}
                sx={{ cursor: 'pointer', marginLeft: '2.3px' }}
              />
              :
              <IconButton sx={styles.iconButton}
                onClick={(event) => {
                  setArchiveAnchorEl(event.currentTarget);
                }}>
                <MoreVertIcon />
              </IconButton>
          }
          <Menu
            anchorEl={archiveAnchorEl}
            open={showActions}
            onClose={() => setArchiveAnchorEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <PatchedTooltip
              title={!componentAccessMap?.devices?.editActions ? "You don't have permission to use this. Contact Administrator" : ""}
            >
              <Button
                endIcon={<ArchiveIcon />}
                sx={{ columnGap: info.status == "Archived" ? "5rem" : "6.5rem", padding: "1rem 1.5rem" }}
                onClick={() => {
                  if (!componentAccessMap?.devices?.editActions) return;
                  setArchiveAnchorEl(null)
                  if (showPin) setShowPin(false)
                  beginArchiveDevice(info)
                }}
                color="primary"
                disabled={!componentAccessMap?.devices?.editActions}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "400", color: !componentAccessMap?.devices?.editActions ? "inherit" : "text.heading" }}
                >
                  {info.status === "Archived" ? "Unarchive" : "Archive"}
                </Typography>
              </Button>
            </PatchedTooltip>
            <Divider />
            <PatchedTooltip
              title={!componentAccessMap?.devices?.editActions ? "You don't have permission to use this. Contact Administrator" : ""}
            >
              <Button
                endIcon={<SendIcon />}
                sx={{ columnGap: "3rem", padding: "1rem 1.5rem" }}
                onClick={() => {
                  if (info.status === "Archived" || !componentAccessMap?.devices?.editActions) return;
                  setArchiveAnchorEl(null)
                  beginShowMessage(info)
                }}
                color="primary"
                disabled={info.status == "Archived" || !componentAccessMap?.devices?.editActions}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "400",
                    color: info.status == "Archived" || !componentAccessMap?.devices?.editActions ? "inherit" : "text.heading"
                  }}
                >
                  Send Message
                </Typography>
              </Button>
            </PatchedTooltip>
            <Divider />
            <Typography
              variant="subtitle2"
              sx={styles.appVersionText}
            >
              {`App version : ${appVersion ?? "-"}`}
            </Typography>
          </Menu>
        </>
      )
      break;
    default:
      Component = (
        <Typography
          variant="h4"
          sx={{ fontWeight: "400", maxWidth: infoKey === "deviceId" ? "4.5rem" : "fit-content" }}
        >
          {
            (infoKey === "deviceId" ?
              `${info[infoKey]}`.length === 1 ? `0${info[infoKey]}` : info[infoKey]
              : info[infoKey]) || "-"
          }
        </Typography>
      )
      break;
  }

  return (
    <TableCell
      align={"center"}
      sx={{ padding: ".65rem .5rem", whiteSpace: 'nowrap' }}
    >
      <Box sx={{ display: "flex", justifyContent: 'center' }}>
        {Component}
      </Box>
    </TableCell>
  )
}

export default DeviceTableCell