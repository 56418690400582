const drawerStyle = {
    gridArea: "1/1",
    backgroundColor: "white",
    width: "100%",
    borderRadius: ".8rem .8rem 0 0",
    height: "21vh",
    border: "1px solid",
    borderColor: "#DADADA",
    transition: "all .3s ease-out",
    padding: "1.25rem",
    overflowY: "hidden",
    display: "flex",
    flexFlow: "column"
  }

const styles = {
  outerBox: {
    height: "100%",
    backgroundColor: "background.first",
    borderRadius: ".8rem",
    border: "1px solid",
    borderColor: "border.main",
    overflow: "hidden",
  },
  addButtonWrapper: {
    position: "absolute",
    zIndex:100,
    right: "3.4rem",
    top: {
      xs: "8.5rem",
      sm: "10rem",
      md: "3rem",
    }
  },
  container: {
    display: "flex",
    flexDirection:"column",
    height: "100%",
  },
  content: {
    backgroundColor: "antiquewhite",
    height: "100%",
    width: "100%",
  },
  bottomDrawer: drawerStyle,
  expandedBottomDrawer: {
    ...drawerStyle,
    height: "300vh",
  },
  iconButtonBox: {
    textAlign: "center"
  },
  iconButton: {
    height: "2.8rem",
    width: "2.8rem",
  },
  tableWrapper: {
    flexGrow: "1",
    marginTop: ".5rem"
  },
  statusDot: {
    height: ".82rem",
    width: ".82rem",
    backgroundColor: "red",
    borderRadius: "50%"
  },
  statusWrapper: {
    display: "flex", 
    alignItems: "center", 
    columnGap: ".8rem"
  },
  appVersionText: {
    textAlign: "center", 
    padding:'10px',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#888888'
  }
}

export default styles