import * as actionTypes from "./actionTypes";

const initialState = {
  zonesData: null,
  zone: null,
  // itemCount: 0,
  duration: "Daily",
  filters: {
    active: true
  },
  blacklistedCandidates: {
    list: [],
    totalCount: 0
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.FETCH_ZONES:
    return fetchZones(state, action);
  case actionTypes.FETCH_ZONE:
    return fetchZone(state, action);
  case actionTypes.UPDATE_DURATION: 
    return updateDuration(state, action);     
  case actionTypes.UPDATE_ZONE_FILTERS: 
    return updateZoneFilters(state, action);  
  case actionTypes.FETCH_BLACKLISTED_CANDIDATES: 
    return fetchBlacklistedCandidates(state, action);  
  default:
    return state;
  }
};


const fetchZones = (state, action) => {
  return {
    ...state,
    zonesData: action.data,
    // itemCount: action.itemCount,
  };
};

const fetchZone = (state, action) => {
  return {
    ...state,
    zone: action.data,
  };
};

const updateDuration = (state, action) => {
  return {
    ...state,
    duration: action.data,
  };
};

const updateZoneFilters = (state, action) => {
  return {
    ...state,
    filters: {...action.data}
  }
}

const fetchBlacklistedCandidates = (state, action) => {
  return {
    ...state,
    blacklistedCandidates: {
      list: [...action.data],
      totalCount: action.total,
    },
  };
}
