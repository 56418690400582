import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Typography } from '@mui/material';
import styles from './styles'
import PillButton from '../../Components/Buttons/PillButton';
import StandardTextfield from '../../Components/Input/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { deleteRole, fetchRoles, fetchUsers as fetchUsersAction } from './store/actions'
import AddRoleModal from '../../Components/UserManagement/AddRoleModal';
import Roles from './rolesList';
import WarningModal from '../../Components/WarningModal';
import { useSnackbar } from 'notistack';
import { allPermissionsInfo } from '../../Components/UserManagement/AddRoleModal/info';
import AddUserModal from '../../Components/UserManagement/AddUserModal';
import UsersTable from './usersTable';
import usePagination from '../../Helpers/hooks/usePagination';
import PatchedTooltip from '../../Components/PatchedTooltip';

function UserManagement() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar()
    const { componentAccessMap } = useSelector(s => s.auth);
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const [showEditRoleModal, setShowEditRoleModal] = useState(false);
    const [showViewRoleModal, setShowViewRoleModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const selectedRole = useRef(null);
    const [isLoading, setIsLoading] = useState({ users: false, roles: false })

    const handler = (seed, count, searchValue) => {
        setIsLoading((prev) => ({ ...prev, users: true }));
        dispatch(fetchUsersAction({ seed, count, searchValue })).then(res => {
            if (res === "success") setIsLoading((prev) => ({ ...prev, users: false }));
        })
    };

    const {
        page,
        setPage,
        handleCloseSearch,
        handlePageChange,
        rowsPerPage,
        setRowsPerPage,
        rowCounts,
        searchText,
        handleChangeSearchText,
    } = usePagination({ handler })

    const getRoles = useCallback(() => {
        setIsLoading((prev) => ({ ...prev, roles: true }));
        dispatch(fetchRoles()).then(res => {
            if (res)
            {
                const roles = (res?.list).map(row => {
                    const allPerms = structuredClone(allPermissionsInfo);
                    let permNames = [];
                    const perms = row.permissions;
                    Object.keys(allPerms).forEach(permKey => {
                        const permissions = allPerms[permKey].permissions;
                        if (permissions.every(r => perms.includes(r)))
                        {
                            allPerms[permKey].isEnabled = true;
                            permNames.push(allPerms[permKey].title)
                        }
                    })
                    return {
                        id: row._id,
                        name: row.name,
                        description: "",
                        permissions: allPerms,
                        permissionNames: permNames
                    }
                })
                setRoles(roles)
                setIsLoading((prev) => ({ ...prev, roles: false }));
            };
        })
    }, [dispatch])

    useEffect(() => {
        getRoles()
    }, [dispatch, getRoles])

    const handleBeginAction = (role, setter) => {
        console.info(role,'this is the role')
        const { permissions, name, id, description } = role;
        selectedRole.current = {
            permissions,
            name,
            description,
            id
        }
        setter(true)
    }

    const clickHandlers = {
        beginEditRole: (role) => handleBeginAction(role, setShowEditRoleModal),
        beginDeleteRole: (role) => handleBeginAction(role, setShowWarningModal),
        beginViewRole: (role) => handleBeginAction(role, setShowViewRoleModal),
    }

    const handleDeleteRole = () => {
        console.info(selectedRole.current.id)
        dispatch(deleteRole(selectedRole.current.id)).then(res => {
            setShowWarningModal(false);
            if (res === "success")
            {
                getRoles();
                enqueueSnackbar("Role deleted.", { variant: "success" })
            }
        })
    }

    return (
        <Box sx={styles.outerBox}>
            {
                showAddRoleModal &&
                <AddRoleModal
                    open={showAddRoleModal}
                    setOpen={setShowAddRoleModal}
                    getRoles={getRoles}
                />
            }
            {
                showEditRoleModal &&
                <AddRoleModal
                    open={showEditRoleModal}
                    setOpen={setShowEditRoleModal}
                    permissions={selectedRole.current.permissions}
                    mode="edit"
                    name={selectedRole.current.name}
                    description={selectedRole.current.description}
                    getRoles={getRoles}
                />
            }
            {
                showViewRoleModal &&
                <AddRoleModal
                    open={showViewRoleModal}
                    setOpen={setShowViewRoleModal}
                    mode="view"
                    permissions={selectedRole.current.permissions}
                />
            }
            {
                showAddUserModal &&
                <AddUserModal
                    open={showAddUserModal}
                    setOpen={setShowAddUserModal}
                    fetchUsers={handler}
                />
            }
            {
                showWarningModal &&
                <WarningModal
                    open={showWarningModal}
                    setOpen={setShowWarningModal}
                    subtitle={"Delete Role"}
                    caption={"Are you sure you want to delete this role?"}
                    handler={handleDeleteRole}
                />
            }
            <Typography variant="h2">User Management</Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <StandardTextfield
                    placeholder={"Search for User"}
                    value={searchText}
                    onChange={handleChangeSearchText}
                    startIcon={<SearchIcon color="primary" />}
                    sx={{ width: "42%" }}
                />
                <PatchedTooltip
                    title={!componentAccessMap?.userManagement?.addUser ? "You don't have permission to use this. Contact Administrator." : ""}
                >
                    <PillButton
                        text="Add User"
                        onClick={() => {
                            if (!componentAccessMap?.userManagement?.addUser) return;
                            setShowAddUserModal(true)
                        }}
                        disabled={!componentAccessMap?.userManagement?.addUser}
                    />
                </PatchedTooltip>
            </Box>
            <UsersTable
                isLoading={isLoading.users}
                functions={{
                    fetchUsers: handler,
                    handlePageChange,
                }}
                states={{
                    page, setPage, rowCounts,
                    rowsPerPage, setRowsPerPage,
                }}
            />
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Role Overview</Typography>
                <PatchedTooltip
                    title={!componentAccessMap?.userManagement?.addRole ? "You don't have permission to use this. Contact Administrator." : ""}
                >
                    <PillButton
                        text="Add Role"
                        onClick={() => {
                            if (!componentAccessMap?.userManagement?.addRole) return;
                            setShowAddRoleModal(true)
                        }}
                        disabled={!componentAccessMap?.userManagement?.addRole}
                    />
                </PatchedTooltip>
            </Box>
            <Roles
                states={{ roles, setRoles, isLoading: isLoading.roles }}
                functions={{ clickHandlers }}
            />
        </Box>
    );
}

export default UserManagement