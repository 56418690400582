import {useState, useEffect} from "react"
import Download from '@mui/icons-material/Download';
import FilterList from '@mui/icons-material/FilterList';
import { Collapse, Grid, IconButton, Autocomplete, TextField, Box, useTheme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import 'animate.css';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PillButton from '../../Components/Buttons/PillButton';
import Search from '../../Components/Input/Autocomplete/search';
import DateTimeField from '../../Components/Input/DateTimeField';
import DropdownTextfield from '../../Components/Input/Dropdown/index-new';
import { states } from '../../Helpers/utils';
import { getEntriesList, getZoneList, getVisitsConfigs } from './Store/actions';
import {getVisitsData} from '../Entries/Store/actions'
import AutocompleteField from '../../Components/Input/Autocomplete';
import { camelCaseToString } from "../../Helpers/helpers";
import MultipleSelectChip from '../../Components/Input/Multiselect';
import CloseIcon from '@mui/icons-material/Close';
import PatchedTooltip from "../../Components/PatchedTooltip";

export default function Filter({ fetchData, 
  
  filterValues, setFilterValue,
  
  initialInput }) {

  const theme = useTheme();
  const smallerThanMD = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const {componentAccessMap} = useSelector(s => s.auth);
  const [showFilters, setShowFilters] = React.useState(false)

  const [input, setInput] = React.useState(initialInput)
  const rowsPerPage = useSelector(x => x.entries.rowsPerPage)
  const resetPage = useSelector(x => x.entries.resetPage)
  const filters = useSelector(x => x.entries.filters)
  const changeValue = (field, value) => {
    // console.info(field,value,'LINE-21')
    // field.includes("type") ? value.toLowerCase() : 
    let fieldValue;
    if(field.includes("Time") && value === null) {
      fieldValue = null
    }
    else fieldValue = field.includes("Time") ? new Date(Date.parse(value)).toUTCString() : value
    setInput({ ...input, [field]: fieldValue })
  }

  const getData = () => {
    fetchData(0, rowsPerPage, "", {
      zone: "",
      typeOfVisitor: [],
      state: "",
      from: null,
      to: null,
      purpose: []
    }).then(()=>{
      dispatch({
        type: 'RESET_PAGE',
        data: { resetPage: !resetPage },
      });
    })
  }
  const handleReset = () => {
    setInput(initialInput);
    setFilterValue(initialInput);
    setSelectedNames([])
    setSelectedVisitors([])
    getData();
  }

  const onSubmit = React.useCallback(
    () => {
      // console.info(input,"INPUT")
      fetchData(0, rowsPerPage, { ...input, zone: input?.zone?._id })
      // setShowFilters(false)
      dispatch({
        type: 'RESET_PAGE',
        data: { resetPage: !resetPage },
      });
      setFilterValue(input)
    },
    [fetchData, input, rowsPerPage],
  )
  
  React.useEffect(()=>{
    getData()
  },[])


  // console.info(new Date(input?.inTimeFrom), "date")
  const [ purposeList, setPurposeList ] = useState([]) 
  const [ visitorList, setVisitorList ] = useState([])
  const [allPurposeList, setAllPurposeList] = useState([])
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedVisitors, setSelectedVisitors] = useState([]);

  useEffect(() => {
    const initFunc = async () => {
      if (componentAccessMap?.entries?.visitsConfigsFilters) {
        dispatch(getVisitsConfigs("visitPurpose")).then((res) => {
          let arr;
          arr =  res?.list.map((i) => i?.texts?.[0]?.text)
          setPurposeList(arr)
          setAllPurposeList(arr)
        })
      
        dispatch(getVisitsConfigs("typeOfVisitor")).then((res) => {
          let arr;
          arr =  res?.list.map((i) => i?.texts?.[0]?.text)
          setVisitorList(arr)
        })
      }
    };
    initFunc();
  }, [dispatch])

  useEffect(() => {
    changeValue('purpose', selectedNames)
  },[selectedNames])

  useEffect(() => {
    changeValue('typeOfVisitor', selectedVisitors)
  }, [selectedVisitors])
  

  return (

    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      ><Typography variant="body1" sx={{
        fontSize: "var(--body-font-size-heading)",
        fontFamily: "Lato",
        fontWeight: 700
      }}>Entry Details</Typography>
        <span>
          <IconButton className="icon-button-custom"
            onClick={() => setShowFilters(!showFilters)}>
            <FilterList sx={{ color: "black", width: "2rem", height: "2rem" }} />
          </IconButton>
          <PatchedTooltip
            title={!componentAccessMap?.entries?.export ? "You don't have permission to use this. Contact Administrator" : ""}
          >
            <IconButton className="icon-button-custom"
              sx={{color: "primary.main" }}
              disabled={!componentAccessMap?.entries?.export}
              onClick={() => {
                if (!componentAccessMap?.entries?.export) return;
                getEntriesList(0, 0, filters ?? {}, true)
              }}>
              <Download sx={{ color: "inherit", width: "2rem", height: "2rem" }} />
            </IconButton>
          </PatchedTooltip>
        </span>
      </Grid>

      <Collapse in={showFilters}
        easing={{
          enter: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          exit: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
        }}
        timeout={500}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
         <PatchedTooltip title={!componentAccessMap?.entries?.zonesFilter ? "You don't have permission to use this. Contact Administrator" : ""}
          style={{width: smallerThanMD ? "100%" : "24%"}}
         >
          <Search
            sx={{ width: { xs: "100%", md: "100%" }, margin: '0.5em 0',zIndex: "1"}}
            placeholder="Enter Zone"
            disabled={!componentAccessMap?.entries?.zonesFilter}
            value={input?.zone}
            label="Zone"
            apiTrigger={async (searchThis, newSeedValue) => {
              const res = await getZoneList(
                newSeedValue,
                10,
                searchThis
              );
              // console.info(res,"ZONE")
              return res;
            }}
            getName={(n) => {

              return {
                primary: n.name ?? "",
                secondary: "",
              };
            }}
            updateSelectedKey={(zoneInfo) => {
              changeValue('zone', zoneInfo)
              setSelectedNames([])
              if(zoneInfo == null){
                setPurposeList(allPurposeList)
              }
              else if (zoneInfo?.visitPurposes){
                let arr;
                arr = zoneInfo?.visitPurposes.map((i) => i.name)
                setPurposeList(arr)
              }else{
                setPurposeList([])
              }
            }}
          />
         </PatchedTooltip>
          {/* <Search
            sx={{ width: { xs: "100%", md: "24%" }, margin: '0.5em 0', zIndex: "1" }}
            placeholder="Enter Type"
            value={input.search}
            label="Type"
            apiTrigger={async (searchThis, newSeedValue) => {
              const res = await getTypeList(
                newSeedValue,
                10,
                searchThis
              );
              return res;
            }}
            getName={(n) => {
              return {
                primary: n ?? "",
                secondary: "",
              };
            }}
            updateSelectedKey={(v) => {
              changeValue('search', v)
            }}
          /> */}
          <AutocompleteField
            sx={{ width: { xs: "100%", md: "24%" }, margin: '0.5em 0', zIndex: "1" }}
            label="Status"
            options={['Signed In', 'Signed Off', 'Manual Sign Off']}
            placeholder={"Select Status"}
            variant="outlined"
            // sx={{ margin: '0.5em 0', width: { xs: "100%", md: "48.7%" } }}
            size={"medium"}
            // fieldValue={camelCaseToString(input.state)}
            selectedValue={input.state}
            onValueChange={(e, newValue) => {
              // console.info(newValue,"VAL")
              changeValue('state', newValue)
            }}
          />
          <DateTimeField
            sx={{ margin: '0.5em 0', width: { xs: "100%", md: "24%" } }}
            label="From"
            shrinkLabel
            value={input.inTimeFrom}
            handleChange={(val) => changeValue('inTimeFrom', val)}
            maxDateTime={new Date(input?.inTimeTo) ?? null}
            endIcon={input.inTimeFrom && input.inTimeFrom !== null && <CloseIcon />}
            showIconButton
            endIconOnClick={() => changeValue('inTimeFrom', null)}
          />
          <DateTimeField
            sx={{ margin: '0.5em 0', width: { xs: "100%", md: "24%" } }}
            label="To"
            shrinkLabel
            value={input.inTimeTo}
            handleChange={(val) => changeValue('inTimeTo', val)}
            minDateTime={new Date(input?.inTimeFrom)}
            disableFuture
            disabled={!input.inTimeFrom}
            endIcon={input.inTimeTo && input.inTimeTo !== null && <CloseIcon />}
            showIconButton
            endIconOnClick={() => changeValue('inTimeTo', null)}
          />
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between',flexDirection: {xs:'column', md:'row'} }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: { xs: "100%", md: "49.3%" }, margin: 0, padding: 0 }}>
            {/* <Search
              sx={{ margin: '0.5em 0', width: { xs: "100%", md: "48.7%" } }}
              placeholder="Purpose"
              value={input?.zone}
              label="Purpose"
              apiTrigger={async (searchThis, newSeedValue) => {
                console.info(searchThis, newSeedValue, "DATA")
                const res = dispatch(getVisitsData()).then((res) => {
                  let ob = { list: res?.purposeOfVisit }
                  return ob
                })
                return res
              }}
              getName={(n) => {

                return {
                  primary: n.name ?? "",
                  secondary: "",
                };
              }}
              updateSelectedKey={(v) => {
                changeValue('zone', v)
              }}
            /> */}
            <Box sx={{ margin: '0.5em 0', width: { xs: "100%", md: "48.7%" } }}>
             <PatchedTooltip
                title={!componentAccessMap?.entries?.visitsConfigsFilters ? "You don't have permission to use this. Contact Administrator" : ""}
              >
              <MultipleSelectChip
               names={purposeList} labelName={'Purpose'}
               selectedNames={selectedNames} setSelectedNames={setSelectedNames}
               disabled={!componentAccessMap?.entries?.visitsConfigsFilters}
              />
             </PatchedTooltip>
            </Box>
            <Box sx={{ margin: '0.5em 0', width: { xs: "100%", md: "48.7%" } }}>
             <PatchedTooltip
                 title={!componentAccessMap?.entries?.visitsConfigsFilters ? "You don't have permission to use this. Contact Administrator" : ""}
             >
              <MultipleSelectChip
               names={visitorList} labelName={'Visitor Type'}
               selectedNames={selectedVisitors} setSelectedNames={setSelectedVisitors}
               disabled={!componentAccessMap?.entries?.visitsConfigsFilters}
              />
             </PatchedTooltip>
            </Box>

          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ width: { xs: "100%", md: "50%" } }}
          >
            <PillButton
              text={"Search"}
              typography={"body2"}
              sx={{ margin: '1em' }}
              onClick={() => {
                // setInput(initialInput);
                onSubmit()
              }}
            />
            <PillButton
              text={"Reset"}
              invertedColor
              backgroundColor={"#ffffff"}
              typography={"body2"}
              onClick={handleReset}
            />
          </Grid>
        </Box>
      </Collapse>
    </Grid>

  );
}