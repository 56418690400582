const styles = {
    dialogContent: {
        padding: "1.5rem 3.5rem",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2.75rem",
        width: "57rem",
        rowGap: "2.3rem",
        overflowY: "hidden"
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem",
        paddingTop: "1.35rem",
        paddingLeft: "3.5rem",
        paddingRight: "2.5rem",
        marginBottom: "0.25rem"
      },
}

export default styles;