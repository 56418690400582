import axios from "axios";
import API from "../../../api";
import { baseURLs } from "../../../Axios";
import * as actionTypes from "./actionTypes";
import { getAccessToken } from "../../../Utility/tokenUtils";

const addNewDevice = () => {
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.verifier}${API.device.qr}`,
    );

    if (response?.data?.data) return response.data.data
    // else if (response.response.status == '409')
    // {
    //   const res = dispatch(qrDetail(imei))
    //   return res
    // }
  };
};

// const qrDetail = (imei) => {
//   return async (dispatch) => {
//     const response = await axios.get(
//       `${baseURLs.verifier}${API.device.devices}/qr-detail?code=${imei}`
//     );

//     if (response?.data?.data) return response.data.data
// }
// }

const updateAssignedStatus = (isAssigned, reqData) => {
  const api = isAssigned ? API.device.assign : API.device.unassign
  return async (dispatch) => {
    const response = await axios.put(
      `${baseURLs.verifier}${api}`,
      reqData,
    );
    if (response?.data?.code === 0) return "success"
  };
}

const updateDeviceStatus = (deviceId, status) => {

  const reqData = {
    'deviceId': deviceId
  }
  return async (dispatch) => {
    const response = await axios.put(
      `${baseURLs.verifier}${API.device.devices}/${status}`,
      reqData,
    );
    if (response?.data?.code === 0) return "success"
  };
}

const sendMessage = (deviceId, message) => {

  const reqData = {
    'deviceId': deviceId,
    'message' : message
  }

  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.verifier}${API.device.sendMessage}`,
      reqData,
    );
    if (response?.data?.code === 0) return "success"
  };


}

const generateDevicePin = (deviceId) => {
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.verifier}${API.device.pin}`,
      { deviceId },
    );
    if (response?.data?.code === 0) return { status: "success", pin: response.data.data.pin }
  };
}

const fetchDevices = (paramsObj) => {
  let seed = 0; let count = 5; let sortBy; let order; let searchValue; let status; let doNotDispatch;
  const token = `Bearer ${getAccessToken()}`;
  if (paramsObj !== undefined)
  {
    seed = paramsObj.seed ?? 0;
    count = paramsObj.count ?? 5;
    searchValue = paramsObj.searchValue
    sortBy = paramsObj?.sortBy;
    order = paramsObj?.order;
    status = paramsObj.status
    doNotDispatch = paramsObj.doNotDispatch;
  }

  // console.info(token,'token')
  return async (dispatch) => {
    let queryString = "";
    if (searchValue)
    {
      queryString += `&searchValue=${searchValue}`;
    }
    if (seed !== undefined && count !== undefined) queryString += `&seed=${seed}&count=${count}`;
    if (sortBy) queryString += `&sort=${sortBy}`;
    if (order) queryString += `&order=${order}`;
    if (status) {
      for(let i=0; i<status.length;i++){
      if(i===0){
      queryString += `&status=${status[0]}`
      }else{
        queryString += `,${status[i]}`
      }
      }
    };
    const response = await axios.get(
      `${baseURLs.verifier}${API.device.devices}?${queryString}`,
      { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.data)
    {
      if (!doNotDispatch)
      {
        dispatch({
          type: actionTypes.FETCH_DEVICES,
          data: response.data.data.list,
          total: response.data.data.total
        });
      }
      return response.data;
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addNewDevice,
  updateAssignedStatus,
  updateDeviceStatus,
  generateDevicePin,
  fetchDevices,
  sendMessage
}


