import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function GoogleMaps(props) {
  const { devices } = useSelector(s => s.devices)
  const { mapOptions, markerRederer, clusterRederer } = props;
  const MAP = {
    defaultZoom: 16,
    defaultCenter: mapOptions.center,
    options: (maps) => ({
      // scrollwheel: false,
      gestureHandling: 'cooperative',
      // --- Move the full screen button to the left - the button doesn't work with the config below if clicked on twice on smaller displays (document not active error  is shown) ---
      // fullscreenControlOptions: {
      //   position: maps.ControlPosition.TOP_LEFT,
      // },
      fullscreenControl: false,
    }),
  };

  const [clusters, setClusters] = useState([])
  const restParams = useRef({})
  const firstRender = useRef(true);

  const getClusters = (options) => {
    const clusters = supercluster(props.markersData, {
      radius: process.env.REACT_APP_MAP_CLUSTER_RADIUS,
      minZoom: 0, // min zoom to generate clusters on
      maxZoom: 1000, // max zoom level to cluster the points on
      // radius: 100, // cluster radius in pixels
      extent: 1, // tile extent (radius is calculated relative to it)
      nodeSize: 1
    });
    console.log('getClusters', clusters(options))
    return clusters(options);
  };


  const createClusters = (options) => {
    setClusters(options.bounds
      ? getClusters(options).map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${numPoints}_${points[0]._id}`,
        points,
      }))
      : []);
  };

  const handleMapChange = (rest) => {
    const { center, zoom, bounds } = rest;
    restParams.current = rest;
    console.log('handleMapChange: center, zoom, bounds, rest', center, zoom, bounds, rest);
    createClusters({
      center,
      zoom,
      bounds,
    }, rest)
  };

  useEffect(()=>{
    if(firstRender.current){
      firstRender.current = false;
      return;
    }
    if(restParams?.current?.center) handleMapChange(restParams.current)
  },[devices])

  console.log('MAP, clusters', MAP, clusters)
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        defaultZoom={MAP.defaultZoom}
        defaultCenter={MAP.defaultCenter}
        options={MAP.options}
        onChange={handleMapChange}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
      >
        {clusters.map(item => {
          if (item.numPoints === 1) {
            return markerRederer(item.points[0]);
          }

          return clusterRederer(item);
        })}

      </GoogleMapReact>
    </div>
  )
}