import React, { useState } from 'react'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StandardTextfield from "../TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { chipComp, handleAddField, handleDelete, updateChipField } from "./handlers";
import { Button, Box, Divider, Typography, useTheme, useMediaQuery, Stack, IconButton, Chip, CircularProgress } from "@mui/material";
import { deleteFromErrors } from '../../../Helpers/helpers';
import styles from "../../../Containers/EditProfile/styles";
import { useDispatch } from 'react-redux';
import { addPurposeData, deletePurposeData, updatePurposeData, getPurposesData } from '../../../Containers/Entries/Store/actions';
import PatchedTooltip from '../../PatchedTooltip';


function ChipSelect({fields, setFields,chipType, disableAddButton}) {

    console.info(fields, "fields")

    const [newField, setNewField] = useState("");
    const [errorInUpdate, setErrorInUpdate] = useState(false); 
    const [loading,setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [updateValue,setUpdateValue] = useState("");
    const [showAddMoreButton, setShowAddMoreButton] = useState(true);
    const [fieldCurrentlyBeingEdited,setFieldCurrentlyBeingEdited] = useState();
    const dispatch = useDispatch();

    const handleAdd = () => {
        if(chipType === "purposes")
        {
            dispatch(addPurposeData({name:newField}))
            .then((res) => {
                if(res){ 
                const fieldToAdd = {name: newField, _id: res["_id"]};
                handleAddField({ 
                    newField:fieldToAdd, 
                    fields, setFields, setErrors, setNewField, setShowAddMoreButton 
                })
                } 
             
            })
        }
        else handleAddField({ newField, fields, setFields, setErrors, setNewField, setShowAddMoreButton })
    }

    const handleUpdate = (fieldBeingUpdated) => {
        if(updateValue?.length===0) {setErrorInUpdate(true); return;}
        setLoading(true);
        let updatedFields = [...fields];
        const fieldIndex = fields.findIndex(field => field.name === fieldBeingUpdated.name);
        updatedFields[fieldIndex] = {name: updateValue,_id: fields[fieldIndex]._id}
        dispatch(updatePurposeData(fieldBeingUpdated._id,{name: updateValue})).then((res)=> {
            if(res) {setFields(updatedFields);setFieldCurrentlyBeingEdited(undefined)}
            setLoading(false)
            setErrorInUpdate(false);
            }
        )
        setUpdateValue("");
        setLoading(false)
    }
    
    const handleRemove = (field,type) => {
        if(chipType === "purposes") {
            dispatch(deletePurposeData(field._id)).then(res => {
                dispatch(getPurposesData()).then((res) => {
                    setFields(res?.list)
                })
            })
        }
        else handleDelete({ field, fields, setFields })
    }

    const handleCancel = () => {
        setNewField("");
        setShowAddMoreButton(true);
    }

    const handleChange = (value, name, setter) => {
        if (name === "newField") setter(value);
        else setter((prev) => ({ ...prev, [name]: value }));
        if (errors?.[name]) deleteFromErrors(errors, setErrors, name);
    };

    const generateDeleteFunction = (field, index) => {
        switch (index)
        {
            case "add": setFields((x) => [...x, field]); break;
            default: handleRemove(field)
        }
    };

    return (
        <div>
            {
                fields? fields.map((field, index) => {
                    if(chipType === "purposes" && field.name && fieldCurrentlyBeingEdited === field.name) 
                        return updateChipField(field,handleUpdate,updateValue,setUpdateValue,errorInUpdate,setErrorInUpdate,loading,setFieldCurrentlyBeingEdited)
                    return chipComp(field, null, index, generateDeleteFunction,chipType,setFieldCurrentlyBeingEdited)
                }):
                <CircularProgress size="2rem" sx={{mr:3}} />
                
            }
            {!showAddMoreButton ?
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StandardTextfield
                        value={newField}
                        inputRef={!fieldCurrentlyBeingEdited && ((input) => input && input.focus())}
                        onChange={(e) => handleChange(e.target.value, "newField", setNewField)}
                        size="small"
                        errorBool={errors?.newField?.length > 0}
                        helperText={errors?.newField}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) handleAdd()
                        }}
                    />
                    <IconButton style={{ height: "3rem", width: "3rem" }} onClick={() => handleAdd()}>
                        <CheckCircleIcon sx={{ height: "2rem", width: "2rem", color: "primary.main" }}
                        />
                    </IconButton>
                    <IconButton style={{ height: "3rem", width: "3rem" }} onClick={handleCancel}>
                        <CancelIcon sx={{ height: "2rem", width: "2rem", color: "primary.main" }}
                        />
                    </IconButton>
                </Box> :
               <PatchedTooltip
                title={disableAddButton ? "You don't have permission to use this. Contact Administrator" : ""}
               >
                <Chip
                    sx={styles.addMoreChip}
                    label={
                        <Typography variant="subtitle2" color="text.heading">
                            {"Add More"}
                        </Typography>
                    }
                    disabled={disableAddButton}
                    onClick={() => {
                        if(disableAddButton) return;
                        setShowAddMoreButton(false)
                    }}
                    onDelete={() => {
                        if(disableAddButton) return;
                        setShowAddMoreButton(false)
                    }}
                    deleteIcon={
                        <AddCircleOutlineIcon
                            style={{ height: "1.7rem", color: "#444444" }}
                        />
                    }
                />
               </PatchedTooltip> 
            }
        </div>
    )
}

export default ChipSelect