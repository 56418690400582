import { componentToRolesMap, getIn, getIncludedRoles } from "../../../Config/roleBasedAccess/componentLevel.js";
import { routeToRolesMap, userLevelToRoutesMap } from "../../../Config/roleBasedAccess/routeLevel.js";
import { doArraysShareElements } from "../../../Helpers/helpers.js";
import {
  axiosUserManagementInstance, baseURLs
} from "../../../Axios";
import axios from 'axios';
import API from "../../../api";

import {
    generateLoginToken, getAccessToken, getPermissions
  } from "../../../Utility/tokenUtils.js";
  import * as actionTypes from "./actionTypes";

  const authSuccess = (token, email, roles, allowedRoutes, componentAccessMap) => {
    return {
      type: actionTypes.AUTH_SUCCESS,
      token,
      email,
      roles,
      allowedRoutes,
      componentAccessMap
    };
  };
  
  const authFail = (error) => {
    return {
      type: actionTypes.AUTH_FAIL,
      error: error,
    };
  };

const login = (email, password) => {
    return async (dispatch, getState) => {
      
      try {
        const response = await generateLoginToken(email, password);
        if (response && response.data) {
          // const decodedAccessToken = await decodeAccessToken(getAccessToken());
          // const userLevel = decodedAccessToken.realm_access.roles[0] === "indisi-super-admin" ? "superAdmin" : "user";

          // const roles = decodedAccessToken?.resource_access?.["indisi-users"]?.roles;
          // const filteredRoles = roles?.filter(role => role?.split("access-")?.[0] === "") ?? [];
          // const userManagementRoles = roles?.filter(role => role?.includes("roles-") || role?.includes("user-")) ?? [];

          const permissions = await getPermissions();
          // console.info(permissions, "PERMISSIONS");
          const userLevel = permissions?.includes("super-admin") ? "superAdmin" : "user";
          // console.info(userLevel, "RESPONSE");
          localStorage.setItem("userLevel", userLevel);
          const roles = permissions;
          const filteredRoles = roles?.filter((role) => role?.split("access-")?.[0] === "") ?? [];
          const userManagementRoles = roles?.filter((role) => role?.includes("roles-") || role?.includes("identities-")) ?? [];
          console.info(userManagementRoles,"USER-MANAGEMENT-ROLES")
          
          if (filteredRoles?.length === 0 && userManagementRoles?.length === 0) {
           return window.snackbar("error", "You don't have access to this portal. Contact admin.")
          }
          localStorage.setItem("userLevel", userLevel);

          //allowed routes
          let allowedRoutes = [];
          userLevelToRoutesMap[userLevel].forEach(route => {
            const routeRoles = routeToRolesMap[route.path];
            const alwaysAllowedRoutes = [
              "/dashboard",
              "/edit-profile",
            ]
            let isAccessible = alwaysAllowedRoutes.includes(route.path) ? true : false;
            if(routeRoles) {
              if (route.path === "/user-management") {
                console.info(routeRoles,'ROLE')
                isAccessible = routeRoles.every(role => userManagementRoles.includes(role))
              } //user management related roles
              else {
                isAccessible = doArraysShareElements(filteredRoles, routeRoles)
              } //access related roles
            }
            if(isAccessible) allowedRoutes.push(route.path) 
          })

          //accessible components map
          const componentAccessMap = structuredClone(componentToRolesMap);
          Object.keys(componentToRolesMap).forEach((componentKey) => {
            Object.keys(componentToRolesMap[componentKey]).forEach(sectionKey => {
              const section = componentAccessMap[componentKey][sectionKey];
              let isAllowed = false;
              for(let i = 0; i < section.length; i++) {
                const role = section[i];
                if (componentKey === "userManagement") {
                  isAllowed = userManagementRoles.includes(role);
                }
                else {
                  const includedRoles = getIncludedRoles(role);
                  isAllowed = doArraysShareElements(filteredRoles, includedRoles);
                }
                if(!isAllowed) {
                  componentAccessMap[componentKey][sectionKey] = false;
                  break;
                }
                else {
                  if (i === section.length - 1) componentAccessMap[componentKey][sectionKey] = true;
                }
              }
            })
          })
          console.info(allowedRoutes,'Allowed-Routes')
          dispatch(authSuccess(getAccessToken(), email, filteredRoles, allowedRoutes, componentAccessMap));
        } else {
          console.error("errs inside 124", response);
        }
      } catch (err) {
        console.error("errs", err);
        dispatch(authFail(err.message));
      }
    };
  };

const logout = () => {
    localStorage.clear();
  
    return {
      type: actionTypes.AUTH_LOGOUT,
    };
  };

const sendResetPasswordLink = (email) => {
  return async (dispatch) => {
    try
    {
      const response = await axios.post(
        `${baseURLs.userManagement}${API.auth.resetPassword}`,
        {
          username:email
        }
      );
      if (response?.data?.code === 0)
      {
        window.snackbar("success", response?.data?.message);
      } else
      {
        window.snackbar("error", response?.data?.message);
      }
    } catch (err)
    {
      console.info(err)
    }
  };
};

const resetPassword = (txId, password, confirmPassword, history) => {
  return async (dispatch) => {
    const authData = {
      txId:txId,
      newPassword: password
    };
    try
    {
      const response = await axiosUserManagementInstance.put(
        API.auth.resetPassword,
        authData
      );
      if (response.data.code === 0)
      {
        window.snackbar("success", "Password reset successfully");
        history.replace("/user/login");
      } else
      {
        window.snackbar("error", response.data.message);
      }
    } catch (err)
    {
      window.snackbar("error", err.message);
    }
  };
};

const changePassword = (data) => {
  return async (dispatch) => {
    try
    {
      const response = await axiosUserManagementInstance.patch(
        API.auth.changePassword,
        data,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (response.data.code === 0)
      {
        window.snackbar("success", response.data.message);
        return response;
      }
    } catch (err)
    {
      window.snackbar("error", err.message);
    }
  };
};

const actions = {
    login,
    logout,
    sendResetPasswordLink,
    resetPassword,
    changePassword
}
export default actions

