import React, { useEffect } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Card from "./Card";
import styles from "./styles";

function PaginatedCardGrid({ handler, data, totalCount, unrelatedModalsOpen, clickHandlers, cardType, showSort, showFilterTemplates, showFilterIssuance, sortOptions, categoryOptions, zoneState }) {

  const theme = useTheme();
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchData = () => {
    handler();
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Box sx={styles.outerBox}>
      <Box>
        <Grid container spacing={smallerThanMDScreen ? 3 : 2}>
          {data?.map((info, index) =>
            <Grid key={index} item xs={12} sm={6} md={6}>
              <Card
                data={info}
                cardType={cardType}
                zoneState={zoneState}
                clickHandlers={clickHandlers}
              // fetchPageData={fetchDataMemoized}
              // archive={archive}
              />
            </Grid>
          )
          }
        </Grid>
      </Box>
    </Box>
  );
}

export default PaginatedCardGrid;
