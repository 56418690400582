import { MenuItem, TextField } from "@mui/material";
import { camelCaseToString } from "../../../Helpers/helpers";

function DropdownTextfield({
  label,
  name,
  value,
  onChange,
  optionsArray,
  variant,
  noBorder,
  disabled,
  required,
  errorBool,
  helperText,
  errorText,
  placeholder,
  textFieldWidthClass,
  sx,
  size,
  textSize
}) {
  const copiedArray = optionsArray ? [...optionsArray] : [];
  if (optionsArray && !optionsArray.includes("- Select -")) {
    copiedArray.unshift("- Select -");
  }

  return (
    <>
      <TextField
        required={required}
        select
        error={errorBool}
        placeholder={placeholder}
        helperText={errorBool && helperText !== "required" && helperText}
        FormHelperTextProps={{
          style: {
            fontSize: "1rem",
            fontWeight: "300",
          },
        }}
        disabled={disabled}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        SelectProps={{
          // native: true,
          required: true,
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset > legend > span": {
              // fontSize: 'var(--body-font-size-small)',
              paddingRight: '1px !important'
            }
          },
          ...sx
        }}
        variant={variant ? variant : "outlined"}
        size={size ?? "small"}
        className={textFieldWidthClass}
        inputProps={
          noBorder ?
            {
              style: {
                // fontSize: "1.32rem",
                // color: "#68A691",
                // backgroundColor: "inherit",
                // height: "1.9rem",
              },
            } :
            {
              style: {
                // fontSize: textSize === "large" ? "1.32rem" : "1.2rem",
                // fontWeight: "300",
                // height: "1.9rem",
                // backgroundColor: "#F8F8F8",
              },
            }
        }
        InputLabelProps={{
          // style: { fontSize: "1.19rem", fontWeight: "300" },
          shrink: true,
          sx: {
            fontSize: 'var(--body-font-size-small)',
            margin: 0,
            fontWeight: 400,
            paddingTop: '1px',
            marginLeft: '.2rem'
          }
        }}
        InputProps={{
          disableunderline: noBorder,
          sx: {
            fontSize: 'var(--body-font-size-small)',
          },
          // style: { fontSize: "1.19rem", fontWeight: "300" },
        }}
      >
        {copiedArray ?
          copiedArray.map((option) => {
            {/* console.log('typeof option ',typeof option , option) */}
            return option === "- Select -" ? (
              <MenuItem key={option} value="" disabled sx={{typography: "h4", fontWeight: "400"}}>
              Select
              </MenuItem>
            ) :
              typeof option == 'string' ?
                (<MenuItem key={option} value={option} sx={{typography: "h4", fontWeight: "400"}}>
                  {camelCaseToString(option)}
                </MenuItem>) : 
                (<MenuItem key={option.id} value={option.id} sx={{typography: "h4", fontWeight: "400"}}>
                  {option.label}
                </MenuItem>)

          }) :
          []}
      </TextField>
    </>
  );
}

export default DropdownTextfield;
