import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import PillButton from '../../Buttons/PillButton'
import CloseIcon from "@mui/icons-material/Close";
import styles from './styles'
import actions from '../../../Containers/Device/store/actions';
import * as actionTypes from '../../../Containers/Device/store/actionTypes';
import {QRCodeSVG} from 'qrcode.react';
import { listenEvents } from '../../../Services/SSEService';
import api from '../../../api';
import { useSnackbar } from 'notistack';

function AddDeviceModal({open, setOpen}) {
    const {enqueueSnackbar} = useSnackbar()
    const dispatch = useDispatch()
    const [QRCode, setQRCode] = useState(null);
    const isLoading = useSelector(s=>s.alert.loading)
    const isDevicePaired = useSelector(s => s.devices.isDevicePaired)

    const handleClose = () => setOpen(false)

    const handleSubmit = () => {
      dispatch(actions.addNewDevice()).then(res => {
        if (res) {
          setQRCode(JSON.stringify(res))
        }
      })
    }

    useEffect(() => {
      handleSubmit()
    },[])

   useEffect(()=>{
    const onSuccess = () => {
      enqueueSnackbar("Device paired successfully!", {
        variant: "success"
      })
      dispatch(actions.fetchDevices({seed: 0, count: 40}))
      setTimeout(()=>{
        dispatch({
          type: actionTypes.SET_DEVICE_PAIRED_STATUS,
          data: false
        })
      },1000)
      handleClose()
    }
    if(isDevicePaired) onSuccess()
   },[isDevicePaired]) 

  return (
    <Dialog
    open={open}
    onClose={handleClose}
  >
    <Box sx={styles.topRow}>
    <Typography variant="h4" color="text.heading">{"Add a new Device"}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
        </IconButton>
    </Box>
    <DialogContent sx={{...styles.dialogContent}}>
       {isLoading ?  
         <div style={styles.loaderBox}>
            <CircularProgress />
         </div> : 
         <div style={styles.midBox}>
         {
             QRCode && 
            <QRCodeSVG
                value={QRCode}
                size={230}
                level={"L"}
                includeMargin={true}
                fgColor="#013366"
              /> 
         }
        <PillButton
              text="Refresh"
              onClick={handleSubmit}
              invertedColor
        />
       </div>
       }
    </DialogContent>
  </Dialog>
  )
}

export default AddDeviceModal