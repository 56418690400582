import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PillButton from "../Buttons/PillButton";
import StandardTextfield from "../Input/TextField";

function WarningModal({open, setOpen, handler, title, subtitle, caption, type}) {
  const theme = useTheme();
  const largerThanXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLoading = useSelector(s => s.alert.loading)

  const [input, setInput] = React.useState("")

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const callHandler = (e) => {
    e.stopPropagation();
    if(type == 'edit') handler(input);
    else handler();
  };

  return (
    <Dialog
      maxWidth={largerThanXLScreen ? "sm" : "xs"}
      open={open}
      onClose={handleClose}
      onClick={(e)=>e.stopPropagation()}
    >
      {/* <Box sx={styles.topRow}>
      <Typography variant="h4" color="text.headingLight">Credential Preview</Typography>
      <IconButton onClick={handleClose} >
        <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
      </IconButton>
    </Box> */}
      <DialogContent sx={{padding: "2.5rem 3rem", display: "flex", flexDirection: "column", rowGap: "2rem", alignItems: "center"}}>
         {
          isLoading ? 
          <div style={{height: "11rem", width: "24rem", display:"grid", placeItems: "center"}}>
            <CircularProgress />
          </div> : 
          type == 'edit' ?
          <>
                <Typography variant="h3">{subtitle}</Typography>
                <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "400" }}>{caption}</Typography>
                <StandardTextfield
                  placeholder="Enter a valid reason"
                  type="text"
                  multiline
                  rows={4}
                  required
                  variant='outlined'
                  label='Enter here'
                  value={input}
                  sx={{width:"100%"}}
                  onChange={(e) => setInput(e.target.value)}
                />
                <Box sx={{ display: "flex", columnGap: "15%", marginTop: ".5rem" }}>
                  <PillButton text="Send" onClick={callHandler} />
                  <PillButton text="Canel" invertedColor onClick={handleClose} />
                </Box>
          </>
          :
          <>
          <Typography variant="h3">{subtitle}</Typography>
          <Typography variant="h4" sx={{textAlign: "center", fontWeight: "400"}}>{caption}</Typography>
          <Box sx={{display: "flex", columnGap: "15%", marginTop: ".5rem"}}>
            <PillButton text="Yes" onClick={callHandler} />
            <PillButton text="No" invertedColor onClick={handleClose} />
          </Box>
         </>
         }
      </DialogContent>
    </Dialog>
  );
}

export default WarningModal;
