
import Close from '@mui/icons-material/Close';
import { Avatar, Grid, IconButton, ListItemText, Menu, Typography, CircularProgress, Box, Chip, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { camelCaseToString } from '../../Helpers/helpers';
import Nodata from '../../Components/NoData';

const ignoreKeys = ["rollNumber", "name", "photo", "tag"];


export default function VisitorCard({ open, anchorEl, handleClose, entryId }) {

    const {visitorData, entriesData} = useSelector(x => x.entries)
    const entryIndex = entriesData?.list?.findIndex(entry => entry._id === entryId)
    const selectedEntry = entriesData?.list?.[entryIndex] ?? {}
    const entriesFields = {
        entryCheckpoint: selectedEntry?.in?.checkpoint?.name,
        exitCheckpoint: selectedEntry?.out?.checkpoint?.name,
        hostName: selectedEntry?.host?.name,
        hostAddress: selectedEntry?.host?.address
    }

    return (<Menu
        anchorEl={anchorEl}
        id="visitor-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
            elevation: 0,
            sx: {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                width: '30vw', height: 'fit-content',
                overflowY: 'auto',
                borderRadius: '10px',
                // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                mt: 1.5,
                padding: '0 1em',
                border: "1px solid #DADADA",
                '& .MuiAvatar-root': {
                    width: 105,
                    height: 105,
                    borderRadius: '24px',
                    margin: 'auto',
                    marginBottom: '1em'
                },
            },
        }}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
    >

        {typeof visitorData === 'object' ?
            <div style={{
                display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                flexDirection: 'column',
                // height: '100%',
                // width: '100%'
            }}>
                <IconButton onClick={handleClose}
                    sx={{
                        float: 'right',
                        position: 'relative',
                        right: '-0.4rem',
                        marginLeft: 'auto',
                        padding: "0"
                    }}><Close /></IconButton>
                <Box sx={{display: "flex", columnGap: "2rem", alignItems: "center", mb: "1.1rem", mt: "-1rem"}}>
                <img
                        alt="visitorPic"
                    src={visitorData?.photo ? `data:image/png;base64,${visitorData?.photo}` :
                        "https://akm-img-a-in.tosshub.com/indiatoday/images/story/201406/eyecatchers_suraj_061414124800.jpg"
                    }
                    style={{display: "flex", alignItems: "center", borderRadius: "1.2rem", height: "9.5rem", width: "9.5rem", objectFit: "cover"}}
                 />
                
                <div style={{display: "flex", flexFlow: "column", flex: 1, rowGap: "0.8rem"}}>
                   <div style={{display: "flex", columnGap: "1rem", alignItems: "center"}}>
                  <Typography variant="h3">{visitorData?.name || "-"}</Typography>
                  <Chip label={visitorData.tag} 
                  sx={{
                    fontSize: 'var(--body-font-size-large)',
                    backgroundColor: "#C7E5FF",
                    fontWeight: 700,
                    borderRadius: "0.4rem",
                    typography: "caption",
                    height: "3vh"
                    }}/>
                   </div>
                    <Typography variant="subtitle2" sx={{fontWeight: "400"}}>{visitorData?.rollNumber || "-"}</Typography>
                </div>
                </Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {visitorData ? Object.keys(visitorData).map((key, i) => !ignoreKeys.includes(key) ? (<Grid
                        key={"visitor-info-" + i}
                        item xs={4}>
                        <ListItemText
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: 'var(--body-font-size-xsmall)',
                                    fontWeight: 700
                                }
                            }}
                            secondaryTypographyProps={{
                                sx: {
                                    fontSize: 'var(--body-font-size-small)',
                                    fontWeight: 400,
                                    wordBreak: "break-word"
                                }
                            }}
                            primary={camelCaseToString(key)}
                            secondary={visitorData?.[key] || "-"}
                        />
                    </Grid>) : null) : null}
                </Grid>
                <Divider sx={{mt: "0.5rem", mb: "1.5rem"}}/>
                <Typography variant="h4" sx={{mb: 1}} color="#000">Entry / Exit details</Typography>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {Object.keys(entriesFields).map(field => 
                        <Grid
                        key={field}
                        item xs={4}>
                        <ListItemText
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: 'var(--body-font-size-xsmall)',
                                    fontWeight: 700
                                }
                            }}
                            secondaryTypographyProps={{
                                sx: {
                                    fontSize: 'var(--body-font-size-small)',
                                    fontWeight: 400,
                                    wordBreak: "break-word"
                                }
                            }}
                            primary={camelCaseToString(field)}
                            secondary={entriesFields?.[field] || "-"}
                        />
                    </Grid>
                    )}
                </Grid>
            </div> :
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '69vh',
                // width: '100%'
            }}>
                {visitorData === 'loading' ? <CircularProgress /> : <Nodata size="25vh" />}

            </div>}

    </Menu>
    );
}