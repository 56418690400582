import * as actionTypes from "./actionTypes";

const initialState = {
  users: {
    list: [],
    totalCount: 0
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.FETCH_USERS:
    return fetchUsers(state, action);
  default:
    return state;
  }
};

const fetchUsers = (state, action) => {
  return {
    ...state,
    users: {
        list: [...action.data],
        totalCount: action.total,
    }
  };
};

