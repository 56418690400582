import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, CircularProgress, Typography, Link, TextField } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styles from "./styles";
import PillButton from "../../../Components/Buttons/PillButton";
import StandardTextfield from "../../../Components/Input/TextField/index";
import { ReactComponent as TrentialLogo } from "../../../Assets/svg/trential-logo-black.svg";
import { ReactComponent as TrentialLogoBlue } from "../../../Assets/svg/access-portal-logo.svg";
import authActions from "../store/actions";
import { deleteFromErrors } from "../../../Helpers/helpers";
import { isValidEmail } from "../../../Utility/validations";
import ForgotPassword from "../ForgotPassword/index-new";
import ContactUs from "../ContactUs";
import ResetPassword from "../ResetPassword/index-new";

function Login({ resetPassword }) {
  // page 0 = forget password
  // page 1 = main login page
  // page 2 = contact us
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const isLoading = false
  const [visibility, setVisibility] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
  const { email, password } = loginInfo;
  const [page, setPage] = useState(1);

  const handleChange = (name, value) => {
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };

  const handleSubmit = () => {
    const tempErrors = { ...errors };
    Object.keys(loginInfo).forEach((field) => {
      if (loginInfo[field] === "") tempErrors[field] = "required";
    });
    if (email.length > 0 && !isValidEmail(email)) tempErrors.email = "Email is invalid";
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    dispatch(authActions.login(email, password));
  };

  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) handleSubmit();
  };

  useEffect(() => {
    // for the case when cookies were cleared, but in the redux state the token is there
    if (isAuthenticated)
    {
      dispatch(authActions.logout())
    }
  }, [])

  return (
    <Box sx={styles.outerBox}>
      <Box sx={styles.middleOuterBox}>
        <Box sx={styles.logoBox}>
          <Typography sx={styles.welcomeText} mb={1}>Welcome to</Typography>
          <Typography sx={styles.welcomeText2} mb={1.5}>Trential Access Control System.</Typography>
          <Typography sx={styles.welcomeText3}>track and control the movement of people in your location</Typography>
        </Box>
        <Box sx={styles.formBox}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TrentialLogoBlue />
            <Typography sx={[styles.trentialText, styles.text, { margin: '5px' }]}>trential</Typography>
            <Typography sx={[styles.trentialText, styles.text, { margin: '5px 5px 5px 0px', color: "rgb(2, 89, 179)" }]}>access</Typography>
          </Box>
          {/* <Typography sx={(page==1)?styles.headerLogin:styles.headerDesc} mb={1}>Start issuing tamper proof digital credentials</Typography> */}
          {resetPassword ?
            <ResetPassword setPage={setPage} /> :
            (page == 1) ? <>
              <Typography variant="h3" sx={styles.headerText}>Login to your Account</Typography>
              <StandardTextfield
                label="Email"
                value={email}
                onChange={(e) => handleChange("email", e.target.value)}
                errorBool={errors?.email?.length > 0}
                helperText={errors?.email}
                onKeyDown={handleEnterKeyPress}
                shrinkLabel
              />
              <Box>
                <StandardTextfield
                  label="Password"
                  value={password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  sx={{ width: "100%", marginBottom: ".5rem" }}
                  endIcon={visibility ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
                  endIconOnClick={() => setVisibility((prev) => !prev)}
                  errorBool={errors?.password?.length > 0}
                  type={visibility ? "text" : "password"}
                  onKeyDown={handleEnterKeyPress}
                  shrinkLabel
                />
                <Typography variant="h4" sx={{ cursor: "pointer", marginLeft: "auto", width: "fit-content" }} onClick={() => setPage(0)}>
                  Forgot Password
                </Typography>
              </Box>

              <Box sx={{ display: "grid", placeItems: "center" }}>
                <PillButton
                  onClick={handleSubmit}
                  text={isLoading ?
                    <CircularProgress size="2rem" sx={{ color: "white" }} /> :
                    "Continue"
                  } padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }} />
              </Box>
            </> :
              page == 0 ? (
                <ForgotPassword setPage={setPage} />
              ) :
                (page == 2) ?
                  <ContactUs setPage={setPage} /> :
                  null
          }
          {/* {(page!==2)&& !resetPassword &&
          <Typography sx={[styles.contactText, styles.text]}>Need help? <Link href="#" underline="none" onClick={()=>{
            setPage(2);
          }}>Contact us</Link></Typography>
          } */}
        </Box>
      </Box>
      <Box sx={styles.bottomText}>
        <Typography variant='h4' sx={{ color: "#3E3E3E" }}>Powered by </Typography>
        <TrentialLogo />
        <Typography variant='h4' sx={{ color: "#3E3E3E", fontWeight: "800" }}>trential</Typography>
      </Box>

    </Box>
  );
}

export default Login;

// <ResetPassword setPage={setPage} /> :

//(page == 0) ?
//<ForgotPassword setPage={setPage} /> :
//<ContactUs setPage={setPage} />