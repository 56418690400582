import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EyeIcon from '@mui/icons-material/Visibility';
import EyeOffIcon from '@mui/icons-material/VisibilityOff';
import {styles} from './styles';
import PatchedTooltip from '../PatchedTooltip';
const Pin=({info,generate, disableUpdatePIN = false})=>{
    const [show,setShow] = useState(false);
    return(
      <PatchedTooltip
         title={disableUpdatePIN ? "You don't have permission to use this. Contact Administrator" : ""}
      >
        <div style={styles.pinWrapper}>
               {info?.pin?.length > 0 ? 
               <React.Fragment>
               <div style={styles.pinLeft}>
                  {
                    info?.pin?.split('').map((digit, index) => 
                    <React.Fragment key={index}>
                    {
                      show ? 
                     <Typography variant="h3">{digit}</Typography> :
                     <Box
                     sx={{
                     ...styles.statusDot,
                     backgroundColor: info.status === "Archived" ? "border.main" : "primary.main"
                     }}/>
                    }
                    </React.Fragment>
                    )
                  }
                </div> 
                {
                  info.status !== "Archived" &&
                  <div>
                  <IconButton sx={styles.iconButton}
                    onClick={() => setShow(prev => !prev)}
                  >
                    {
                      show ? 
                      <EyeOffIcon style={styles.icon} /> :
                      <EyeIcon style={styles.icon} />
                    }
                  </IconButton>
                  <IconButton 
                  sx={styles.iconButton} 
                  onClick={()=>{
                    if (disableUpdatePIN) return;
                    generate(info)
                  }}
                  disabled={disableUpdatePIN}
                  >
                      <RefreshIcon style={styles.icon}/>
                  </IconButton>
               </div>
                }
               </React.Fragment>:
               <Typography>-</Typography>
               } 
            </div>

      </PatchedTooltip>
    )
}

export default Pin;