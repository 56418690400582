const styles =  {
  paginationOuterBox: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "1.4vmin",
  },
  pagination: {
    wrapper: {
      display: "flex",
      justifyContent: "flex-end",
      columnGap: "1.8rem",
      // mt: "1.25rem",
      alignItems: "center",
      // height: "4rem"
    },
    pageBox: {
      typography: "caption",
      display: "grid",
      placeItems: "center",
      borderRadius: "1.1rem",
      padding: "0.1rem 1.7rem",
      border: "1px solid",
      borderColor: "border.main"
    },
    select: {
      height: "2.2rem",
      padding: "0 0.4rem",
      paddingTop: "0.3rem",
      typography: "caption",
      borderRadius: "1.1rem",
    },
    icon: {
      height: "2rem",
      width: "2rem"
    },
    iconButton: {
      height: "2.2rem",
      width: "2.2rem"
    },
  },
};

export default styles;