import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Typography, Box, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.js";
import PillButton from "../../Components/Buttons/PillButton";
import PaginatedCardGrid from "../../Components/PaginatedCardGrid";
import actions from './Store/actions'
import deviceActions from "../../Containers/Device/store/actions"
import AutocompleteField from '../../Components/Input/Autocomplete';
import * as actionTypes from '../Zones/Store/actionTypes.js'
import { useSnackbar } from "notistack";
import WarningModal from "../../Components/WarningModal/index.js";
import BlacklistModal from "../../Components/Zone/BlacklistModal/index.js";
import PatchedTooltip from "../../Components/PatchedTooltip/index.js";

const states = ["Active", "Inactive"]

function Zones() {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const {componentAccessMap} = useSelector(s => s.auth);
    const data = useSelector((state) => state.zones);
    const [zoneState, setZoneState] = useState("Active")
    const {zonesData} = data;
    const selectedZone = useRef({});
    const selectedDeviceId = useRef("")
    const selectedCheckpoint = useRef({})
    const [showZoneDeactivationWarning, setShowZoneDeactivationWarning] = useState(false);
    const [showCheckpointDeactivationWarning, setShowCheckpointDeactivationWarning] = useState(false);
    const [showDeviceUnassignWarning, setShowDeviceUnassignWarning] = useState(false)
    const [showZoneDeletionWarning, setShowZoneDeletionWarning] = useState(false);
    const [showBlacklistModal, setShowBlacklistModal] = useState(false);

    const fetchDataHandler = ({active = zoneState === "Active"} = {}) => {

        // console.info(active,"Active")
        dispatch(actions.fetchZones( (active!==null) ? {active}:{}));
        // dispatch(actions.fetchZones({stats:true}));
    };


    const handleBeginAction = (ref, data, setter) => {
        ref.current = data;
        setter(true)
    }

    // onclick handlers
    const clickHandlers = {
        beginUnassignDevice: (id) => handleBeginAction(selectedDeviceId, id, setShowDeviceUnassignWarning),
        beginToggleZoneActivation: (zone) => {
            handleBeginAction(selectedZone, zone, setShowZoneDeactivationWarning)
        },
        beginToggleCheckpointActivation: (checkpoint) => {
            handleBeginAction(selectedCheckpoint, checkpoint, setShowCheckpointDeactivationWarning)
        },
        beginDeleteZone: (zone) => {
            handleBeginAction(selectedZone, zone, setShowZoneDeletionWarning);
        },
        beginBlacklist: (zone) => {
            handleBeginAction(selectedZone, zone, setShowBlacklistModal);
        }
      }

      // handlers
      const toggleZoneActivation = () => {
        const currentState = selectedZone.current.state === "active";
        dispatch(actions.handleActivation(!currentState, selectedZone.current._id)).then((res) => {
            setShowZoneDeactivationWarning(false);
            if (res)
            {
              enqueueSnackbar(!currentState ? "Zone activated" : "Zone deactivated", {
                variant: "success"
              })
              dispatch(actions.fetchZones({ active: zoneState === "Active"}))
              dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }));
            }
          })
      }

      const toggleCheckpointActivation = () => {
        const currentState = selectedCheckpoint.current.state === "active";
        dispatch(actions.handleCheckpointActivation(!currentState, selectedCheckpoint.current._id)).then((res) => {
            setShowCheckpointDeactivationWarning(false)
            if(res?.data?.code === 0) {
                dispatch(actions.fetchZones({ active: zoneState === "Active" }))
                dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }));
                enqueueSnackbar(!currentState ? "Checkpoint activated" : "Checkpoint deactivated", {
                    variant: "success"
                })
            }
        })
      }

      const handleUnassignDevice = () => {
        const reqData = {
            device: selectedDeviceId.current
        }
        dispatch(deviceActions.updateAssignedStatus(false, reqData)).then((res) => {
            setShowDeviceUnassignWarning(false);
            if (res === "success")
            {
                dispatch(actions.fetchZones({ active: zoneState === "Active" }));
                dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }))
                enqueueSnackbar("Device unassigned!", {
                    variant: "success"
                })
            }
        })
    }

    const handleDeleteZone = () => {
        dispatch(actions.deleteZone(selectedZone.current._id, zoneState === "Active")).then(() => {
          setShowZoneDeletionWarning(false);
          enqueueSnackbar("Zone Deleted", {
            variant: "success"
          })
          dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }));
        })
      }

    const warningConfigs = [
        {
            state: showDeviceUnassignWarning,
            setter: setShowDeviceUnassignWarning,
            subtitle: "Unassign Device",
            caption: "Are you sure you want to unassign this device from this zone?",
            handler: handleUnassignDevice,
        },
        {
            state: showZoneDeactivationWarning,
            setter: setShowZoneDeactivationWarning,
            subtitle: `${selectedZone.current?.state === "active" ? "Deactivate" : "Activate"} Zone`,
            caption: `Are you sure you want to ${selectedZone.current?.state === "active" ? "deactivate" : "activate"} this zone?`,
            handler: toggleZoneActivation,
        },
        {
            state: showCheckpointDeactivationWarning,
            setter: setShowCheckpointDeactivationWarning,
            subtitle: `${selectedCheckpoint.current?.state === "active" ? "Deactivate" : "Activate"} Checkpoint`,
            caption: `Are you sure you want to ${selectedCheckpoint.current?.state === "active" ? "deactivate" : "activate"} this checkpoint?`,
            handler: toggleCheckpointActivation,
        },
        {
            state: showZoneDeletionWarning,
            setter: setShowZoneDeletionWarning,
            subtitle: "Delete Zone",
            caption: `Are you sure you want to delete this zone?`,
            handler: handleDeleteZone,
        }
    ]
    
    return (
        <Box sx={styles.outerBox}>
        {
            warningConfigs.map(config => 
                config.state && 
                <WarningModal
                open={config.state}
                setOpen={config.setter}
                subtitle={config.subtitle}
                caption={config.caption}
                handler={config.handler}
                />
            )
        }
        {
            showBlacklistModal && 
            <BlacklistModal 
               open={showBlacklistModal}
               setOpen={setShowBlacklistModal} 
               zoneId={selectedZone.current._id}
            />
        }
            <Box sx={styles.topRow}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="h1" color="text.heading" sx={{ margin: "2.6rem 3rem auto 0rem" }}>Zones</Typography>
                    <AutocompleteField
                        label={'State'}
                        options={states}
                        placeholder={"Select State"}
                        variant="outlined"
                        sx={{ marginTop: "2rem", width: '200px' }}
                        shrinkLabel
                        size={"large"}
                        selectedValue={zoneState}
                        onValueChange={(e, newValue) => {
                            setZoneState(newValue)
                            dispatch({
                                type: actionTypes.UPDATE_ZONE_FILTERS,
                                data: newValue !== null ? {
                                    active: newValue === "Active"
                                } : { active: null }
                            })
                            fetchDataHandler( newValue!==null ? {active: newValue === "Active"}:{active:null} )
                        }}
                    />
                </Box>
                <Box mt={1.5}>
                   <PatchedTooltip
                    title={!componentAccessMap?.zones?.addNew ? "You don't have permission to use this. Contact Administrator" : ""}
                   >
                    <PillButton
                        text={"Create a New Zone"}
                        onClick={() => { 
                            if (!componentAccessMap?.zones?.addNew) return;
                            history.push({ pathname: "/zones/add-zone", state: { page: 'add', data: {} } }) 
                        }}
                        disabled={!componentAccessMap?.zones?.addNew}    
                    />
                   </PatchedTooltip>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                <PaginatedCardGrid
                    handler={fetchDataHandler}
                    data={zonesData}
                    // totalCount={totalProofTemplates}
                    cardType={"zones"}
                    zoneState={zoneState === "Active"}
                    clickHandlers={clickHandlers}
                //   unrelatedModalsOpen={[openAddTemplate]}
                />
            </Box>
        </Box>
    );
}

export default Zones;
