import React,{ useState, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { Typography, Box, IconButton, Dialog, DialogContent, useTheme, useMediaQuery, Divider, Tabs, Tab, CircularProgress } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import styles from './styles'
import { fetchNotifications, markNotificationsAsRead } from '../../Containers/Dashboard/notifications/store/actions';
import useInfiniteScroll from '../../Helpers/hooks/useInfiniteScroll';
import Notification from '../../Containers/Dashboard/notifications/notification';
import { changeToSentenceCase, delay } from '../../Helpers/helpers';
import { BorderBottom } from '@mui/icons-material';

function NotificationsModal({open, setOpen, unreadCount}) {
  const theme = useTheme();

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState({
    unread: false,
    all: false
  });
  const smallerThanMD = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    handleMarkNotificationsAsRead()
    setOpen(false)
  }
  const initialTabValue = unreadCount == 0 ? 1 : 0
  const [tabValue, setTabValue] = useState(initialTabValue);

  const tabValuesMap = useMemo(() => ({
    0: {
      name: "Unread",
      action: fetchNotifications,
      actionParams: {read:-1, markAsRead: false},
      totalKey: "unreadCount"
    },
    1: {
      name: "All",
      action: fetchNotifications,
      actionParams: {markAsRead: true},
      totalKey: "total"
    }
  }), []);


  const { scrollableDivRef, handleChangeSearchText, searchText, items: allNotifications, handleFetchItems, loadOnScroll, handleScrollPastBottom, reset } = useInfiniteScroll({
    action: tabValuesMap[tabValue]['action'],
    otherActionParams: tabValuesMap[tabValue]['actionParams'],
    totalKey: tabValuesMap[tabValue]['totalKey'],
    parentComponentLoading:loading.all,
  });

  const handleChangeTab = async (e, newValue) => {
    reset()
    if (newValue == 1) handleMarkNotificationsAsRead()
    else {
      setLoading(prev => ({...prev, unread: true}))
      await delay(150)
      setLoading(prev => ({ ...prev, unread: false }))

    }
    setTabValue(newValue); 
  };

  const handleMarkNotificationsAsRead = () => {
    const unreadEventIds = allNotifications.filter(record => record.read === false).map(record => record._id)
    if (unreadEventIds.length > 0)
    { 
      setLoading(prev => ({ ...prev, all: true }))
      dispatch(markNotificationsAsRead(unreadEventIds)).then(()=>{
        setLoading(prev => ({ ...prev, all: false }))

      }).catch(() => {
        setLoading(false)
      })
    }
  }


  return (
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth={smallerThanMD ? "sm" : "xl"}
    fullWidth={false}
  >
    <Box sx={styles.topRow}>
      <Typography variant="h3">Notifications</Typography>
      <IconButton onClick={handleClose} sx={{height: "2.5rem", width: "2.5rem"}} >
        <CloseIcon sx={{ height: "1.8rem", width: "1.8rem" }} />
      </IconButton>
    </Box>
      <Box sx={{borderBottom:'1px solid #DADADA'}}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
        >
          {
            Object.keys(tabValuesMap).map((key) =>
              <Tab key={key} sx={{ backgroundColor: "#ffffff" }} label={<Typography variant="h4" color="text.heading">{changeToSentenceCase(tabValuesMap[key].name)}
              </Typography>} />
            )
          }
        </Tabs>
      </Box>
    <DialogContent 
    sx={styles.dialogContent}
    ref={scrollableDivRef}
    onScroll={loading.all || loading.unread ? null : handleScrollPastBottom}
    >
        {loading.all || loading.unread ?
          <div style={styles.loaderBox}>
            <CircularProgress />
          </div>
          :
          <Box>
            {allNotifications?.length === 0 ?
              <Typography variant="h4" sx={{ margin: "1.5rem 2rem" }}>No new notifications</Typography> :
              allNotifications.map((notification, index) => (
                <Notification
                  key={notification._id}
                  notification={notification}
                  index={index}
                />
              ))}
          </Box>
        }
    </DialogContent>
  </Dialog>
  )
}

export default NotificationsModal