import dayjs from "dayjs";

const getFormattedTime = (time) => {
    const formattedTime = dayjs(Date.parse(time)).fromNow()
    if(formattedTime.includes("seconds")) return "Just now"
    if(formattedTime.includes("minutes")) return formattedTime.replace("minutes", "mins")
    return formattedTime
}

const eventTitles = {
    "DEVICE_PAIRED": "Device paired",
    "DEVICE_SIGN_IN": "Device signed in",
    "DEVICE_SIGN_OUT": "Device signed out",
    "DEVICE_ALERT" : "Emergency Alert",
    "ManualSignOff": "Manual Sign off registered",
}

const getTaskFromTopic = (topic) => {
    let task;
    switch (topic) {
        case "DEVICE_PAIRED": 
            task = "was paired"
            break;
        case "DEVICE_SIGN_IN":
            task = "is signed in"
            break;
        case "DEVICE_SIGN_OUT":
            task = "is signed out"
            break; 
        case "ManualSignOff":
            task = "registered a manual sign off"
            break;          
        case "DEVICE_ALERT":
            task = "raised an emergency alert"
            break;          
        default:
            task = ""
            break;
    }
    return task
}

export {getFormattedTime, eventTitles, getTaskFromTopic}