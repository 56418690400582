import { Box, Divider, Typography } from '@mui/material'
import { eventTitles, getFormattedTime, getTaskFromTopic } from '../helpers'
import {ReactComponent as DeviceShape} from '../../../../Assets/svg/deviceShape.svg'
import {ReactComponent as WarningIcon} from '../../../../Assets/svg/errorIcon.svg'
import styles from './styles'

const colors = ["#FFF3F3", "#FFFEF3", "#EBFBFF", "#EAFFE7"]

function Notification({notification, index}) {
  const deviceColor = index % 4 > 3 ? colors[3] : colors[index % 4]
  const notificationState = notification?.payload?.stateChange ?? notification?.payload?.state
  const task = getTaskFromTopic(notificationState)
  const message = eventTitles[notificationState]
  const time = getFormattedTime(notification.timestamp)
  const checkpointName = notification?.payload?.checkpointName ?? null
  const zoneName = notification?.payload?.zoneName ?? null
  const location = checkpointName && zoneName ? `${zoneName}, ${checkpointName}` : ""
  const deviceId = notification?.payload?.id
  const formattedDeviceId = deviceId?.length === 1 ? `0${deviceId}` : deviceId
  const bottomMessage = message.includes("Alert") ? notification?.payload?.message : notification?.payload?.reason

  return (
    <Box key={index}>
    <Box sx={{...styles.outer, backgroundColor: notification.read ? "background.first" : "#EEF2FB"}}>
      <div style={styles.leftOuterDiv}>
        <Box sx={{...styles.deviceOuter, backgroundColor: deviceColor}}>
          <DeviceShape />
          <Typography variant="subtitle1" noWrap sx={styles.deviceIdText}>
          {formattedDeviceId}
          </Typography>
        </Box>  
        <div style={{maxWidth: "80%"}}>
          <div style={styles.eventTitleParent}>
            {message.includes("Alert") && 
            <WarningIcon style={styles.warningIcon}/>
            }
            <Typography variant="h4" color={"text.heading"}>{message}</Typography>
          </div>
          <Typography variant="caption" color={"#888888"}>
              Device no. <strong>{formattedDeviceId}</strong> {location ? <>at location - <strong>{location}</strong></> : ""} {task}. 
          </Typography>
          {bottomMessage && 
          <Typography variant="caption" color={"#888888"} sx={{display: "block", mt: "0.4rem"}}>
            {`${message.includes("Alert") ? "Message" : "Reason"}`} 
            - 
            “<Box component="span" sx={{color: message.includes("Alert") ? "error.regular" : "#888888"}}>{bottomMessage}
            </Box>”
          </Typography>
          }
        </div>
      </div>
      <Box>
        <Typography noWrap variant="caption" sx={{color: "#888888"}}>{`${time}`}</Typography>
      </Box>
    </Box>
    <Divider />
</Box>
  )
}

export default Notification