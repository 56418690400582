import jwt from "jsonwebtoken";
import { getAccessToken } from "../Utility/tokenUtils";
import { appName } from '../Config/appConfig';
import { EventSourcePolyfill } from 'event-source-polyfill'
import { fetchNotifications } from "../Containers/Dashboard/notifications/store/actions";
import notificationSound from "../Assets/sounds/chime.mp3"
import api from "../api"
import InfoSnackbar from "../Components/Snackbars/Info";
import { store } from '../../src/index'
import { getEntriesList } from "../Containers/Entries/Store/actions";
import * as actionTypes from '../Containers/Device/store/actionTypes';

const MAX_TRY_COUNT = 2
const listenEvents = async (url, { enqueueSnackbar } = {}, onSuccess = () => { }, trialCount = 0) => {
  const playNotificationAudio = process.env.REACT_APP_PLAY_NOTIFICATION_AUDIO
  const token = `Bearer ${getAccessToken()}`;
  const dispatch = store.dispatch



  const es = new EventSourcePolyfill(`${process.env.REACT_APP_SSE_EVENTS_URL}${url}`, {
    headers: {
      Authorization: `${token}`,
    },
    heartbeatTimeout: 999990000,
  });

  es.onopen = (e) => {
    console.info("SSE IS OPEN:", e);
  };

  es.onmessage = (e) => {
    const event = JSON.parse(e.data)
    if (!event) return
    console.info(event, "parsed event");
    const state = store.getState();
    const rowsPerPage = state['entries']['rowsPerPage'] ?? 20
    // console.info(state, rowsPerPage, 'data')

    // ENTRIES
    if (event?.payload?.entryId && event?.topic === "entries") {
      const entriesState = store.getState().entries
      const seed = entriesState.seed
      const filters = entriesState.filters
      let hasFilters = false;
      for (let key of Object.keys(filters)) {
        const value = filters[key]
        if (["sort", "order"].includes(key)) {
          if ((key === "sort" && value !== "updatedAt") || (key === "order" && value !== -1)) {
            hasFilters = true
          }
        }
        else {
          if (Array.isArray) {
            if (value?.length > 0) hasFilters = true
          }
          else {
            if (value) hasFilters = true
          }
        }
      }
      if (window.location.pathname.includes("entries")) {
        if (seed === 0 && !hasFilters) dispatch(getEntriesList(0, rowsPerPage))
        else {
          if (enqueueSnackbar) {
            const message = "This page just got refreshed."
            enqueueSnackbar(message, {
              preventDuplicate: true,
              autoHideDuration: 10000,
              content: (key, msg) =>
                <div>
                  <InfoSnackbar
                    snackbarKey={key}
                    message={msg}
                    link={"/entries"}
                  />
                </div>,
              variant: "info",
            })
          }
        }
      }
      return
    }

    // UPDATE NOTIFICATIONS
    if (!event.eventId) return
    dispatch(fetchNotifications())
    if (playNotificationAudio === "true") {
      let audio = new Audio(notificationSound)
      audio.muted = false;
      audio.volume = 1;
      audio.play()
    }

    // ON DEVICE PAIR
    if (event?.payload?.stateChange === "DEVICE_PAIRED" && event?.topic === "device") {
      dispatch({
        type: actionTypes.SET_DEVICE_PAIRED_STATUS,
        data: true
      })
    }
  };

  es.onerror = function (e) {
    console.info(e, "sse error");
    es.close();
    if (e?.status !== 401 && es?.readyState === 2 && url === api.notifications.subscribe && trialCount < MAX_TRY_COUNT) {
      listenEvents(url, { enqueueSnackbar }, onSuccess, ++trialCount)
    }
  };
};

export { listenEvents };

