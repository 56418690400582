const styles = {
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    paddingLeft: "1.5rem",
    borderBottom: "1px solid",
    borderColor: "border.main",
  },
  dialogContent: {
    padding: "0",
    display: "flex",
    flexDirection: "column",
    height: "54vh",
    width: {
      xs: "80vw",
      md: "60vw",
      lg: "40vw"
    }
  },
  loaderBox: {
    display: "grid",
    placeItems: "center",
    minHeight: "30.7vh"
  }
}

export default styles