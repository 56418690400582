import axios from "axios";
import authActions from "./Containers/Auth/store/actions";
import { getAccessToken } from "./Utility/tokenUtils";
import { loginExpired } from "./Helpers/utils";
import API from './api';

const TIMEOUT_MILLISECONDS = 10 * 60 * 1000;

const axiosPing = axios.create({
  timeout: TIMEOUT_MILLISECONDS,
});

const axiosUserInstance = axios.create({
  baseURL: `${process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL}`,
  timeout: TIMEOUT_MILLISECONDS
});

const axiosSSEInstance = axios.create({
  baseURL:`${process.env.REACT_APP_SSE_EVENTS_URL}`,
  timeout: TIMEOUT_MILLISECONDS
})

const axiosUserManagementInstance = axios.create({
  baseURL: `${process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL}`,
});

const baseURLs = {
  verifier: `${process.env.REACT_APP_ACCESS_CONTROL_BASE_URL}`,
  events: `${process.env.REACT_APP_SSE_EVENTS_URL}`,
  userManagement: `${process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL}`
}

const axiosRequestInterceptor = (request, setLoading) => {

  const loginUrl = process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL + API.login.login;
  const getTokenUrl = process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL + API.login.token;

  if (request?.url !== loginUrl && request?.url !== getTokenUrl) setLoading(true);
  const accessToken = getAccessToken()
  if (request?.url !== loginUrl && request?.url !== getTokenUrl) request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

const axiosResponseInterceptor = (response, setLoading, history, showErrorSnackbar, dispatch) => {
  if (response && response?.data?.code !== 0) {
    if (response?.data?.code === -2) {
      dispatch(authActions.logout());
      // localStorage.removeItem("persist:root");
      window.clearInterval(window.intervalId);
      history.push("/user/login");
      showErrorSnackbar("Logged Out")
    } else {
      if (response?.data?.message) showErrorSnackbar(response.data.message);
    }
  }
  setLoading(false);
  return response;
};

const axiosCatchError = (error, setLoading, history, showErrorSnackbar, dispatch) => {
  console.log("axios err", error);
  if (error?.response?.data?.code === -2)
  {
    dispatch(authActions.logout());
    window.clearInterval(window.intervalId);
    history.push("/user/login");
  }
  setLoading(false);
  if (error?.response?.data?.error_description === "Invalid user credentials") {
    showErrorSnackbar("Invalid credentials")
  }
  else if (error?.response?.data?.message == loginExpired.message)
  {
    showErrorSnackbar("Logged Out")
  } else
  {
    console.info('here')
    showErrorSnackbar(error?.response?.data?.message);
  }
  return error
};


export {
  axiosPing,
  axiosUserInstance,
  axiosSSEInstance,
  axiosRequestInterceptor,
  axiosResponseInterceptor,
  axiosUserManagementInstance,
  axiosCatchError,
  baseURLs
};

