import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button, IconButton } from "@mui/material";
import styles from "../styles";
import { AntSwitch } from "../../../Buttons/AntSwitch";
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../Containers/Zones/Store/actions";
import deviceActions from "../../../../Containers/Device/store/actions"
import { useSnackbar } from "notistack"
import AutocompleteField from '../../../Input/Autocomplete';
import { deleteFromErrors } from '../../../../Helpers/helpers';
import AddIcon from '@mui/icons-material/Add';
import PatchedTooltip from "../../../PatchedTooltip";

function MoreInfo({ info, index, zoneState, total, clickHandlers }) {

    const dispatch = useDispatch()
    // console.info(info,'DATA')
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    const {componentAccessMap} = useSelector(s => s.auth);
    const [showAddDevice, setShowAddDevice] = useState(false)

    const { devices, totalCount } = useSelector(s => s.devices)
    const [deviceDropdown, setDevicesDropdown] = useState([])
    const [selectedDeviceLabel, setselectedDeviceLabel] = useState(null)
    const [deviceId, setdeviceId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [checkbox, setcheckbox] = useState(info?.state === 'active' ? true : false);

    console.info(selectedDeviceLabel, "device label")

    const handleUnassignDevice = (id) => {
        clickHandlers.beginUnassignDevice(id);
    }

    useEffect(() => {
        // console.info(zoneCheckpoints, 'ZONES')
        // console.info(devices, totalCount, 'Devices')
        setDevicesDropdown([])
        const tempDevices = []
        for (let i in devices)
        {
            let status = devices[i].status
            // console.info(status, 'Status')
            let label = devices[i].deviceId
            let id = devices[i]._id
            // if (devices[i].status == 'unassigned') setDevicesDropdown((prev) => ([...prev, { label: label, id: id }]))
             if (devices[i].status === 'unassigned') tempDevices.push({ label: label, id: id })
        }
        if(tempDevices.length > 0) setDevicesDropdown(tempDevices)
        // console.info(deviceCounts,deviceDropdown)
    }, [devices])

    useEffect (() => {
            console.info(info,'here')
        setcheckbox(info?.state === 'active' ? true : false)
    },[info])

    const [errors, setErrors] = useState({})

    const assignDevice = (checkpointId) => {

        console.info(selectedDeviceLabel, 'Device')
        let tempErrors = { ...errors };
        if (!deviceId) tempErrors['deviceId'] = "required"

        if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);

        const reqData = {
            checkpoint: checkpointId,
            device: deviceId
        }
        setselectedDeviceLabel('')
        setdeviceId('')
        setIsLoading(true)
        dispatch(deviceActions.updateAssignedStatus(true, reqData)).then((response) => {
            setIsLoading(false)
            if (response === "success")
            {
                dispatch(deviceActions.generateDevicePin(selectedDeviceLabel)).then((res) => {
                    
                    if (res.status === "success")
                    {
                        enqueueSnackbar("Device assigned!", {
                            variant: "success"
                        })
                        dispatch(actions.fetchZones({ active: zoneState }));
                        dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }));
                        setShowAddDevice(false)
                    }
                })
            }
        })
        setIsLoading(false)
        
    }

    const handleCheckpointChange = () => {
        if (!zoneState || !componentAccessMap?.zones?.editActions) return;
        clickHandlers.beginToggleCheckpointActivation(info);
    }

    console.info(total, index, "data")


    return (
        <Box sx={styles.zones.extraPaddingBottomSection}>
            <Box style={{...styles.zones.bottom, padding: ".8rem", paddingTop: "0", flexWrap:'wrap'}}>
                <Box sx={{ display: 'flex', alignItems: "center", columnGap: "10px" }}>
                  <PatchedTooltip
                   title={!componentAccessMap?.zones?.editActions ? "You don't have permission to use this. Contact Administrator" : ""}
                  >
                    <AntSwitch
                        checked={checkbox}
                        onChange={handleCheckpointChange}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        sx={{ml:"10px"}}
                        disabled={!zoneState || !componentAccessMap?.zones?.editActions}
                    />
                  </PatchedTooltip>
                    <Typography variant="subtitle3" sx={styles.fontStyle}>Checkpoint {index + 1}</Typography>
                    <Typography variant="subtitle3" sx={styles.fontStyle} ml={1}>{info?.name}</Typography>
                </Box>
               <PatchedTooltip
               title={!componentAccessMap?.zones?.addOrRemoveDevice ? "You don't have permission to use this. Contact Administrator" : ""}
               >
                <Button
                    sx={styles.textButton} onClick={() => setShowAddDevice(true)}
                    disabled={!checkbox || !componentAccessMap?.zones?.addOrRemoveDevice}
                >
                <AddIcon sx={{height:'1.8rem',width:'1.8rem'}}/> Add Device
                </Button>
               </PatchedTooltip> 
            </Box>

            <Box sx={{display:'flex', flexWrap:'wrap'}}>
            {info.devices.map((info, index) =>
                    <Box m={1} sx={{...styles.timeBox, alignItems: "center"}} key={index}>
                    <Typography variant="subtitle3" ml={1} mr={1} sx={styles.fontStyle}>
                        Device {info?.deviceId < 10 ? `0${info?.deviceId}` : info?.deviceId }
                        </Typography>
                        <PatchedTooltip
                        title={!componentAccessMap?.zones?.addOrRemoveDevice ? "You don't have permission to use this. Contact Administrator." : ""}
                        >
                            <IconButton 
                            onClick={() => {
                                if (!componentAccessMap?.zones?.addOrRemoveDevice) return;
                                handleUnassignDevice(info?._id)
                            }}
                            size="small"
                            disabled={!componentAccessMap?.zones?.addOrRemoveDevice}
                            color="black"
                            >
                                <CloseIcon sx={{ height: "2rem", width: "2rem" }} />
                            </IconButton>
                        </PatchedTooltip>
                    </Box>
            )}
            </Box>

            {
                showAddDevice &&
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: "1rem 0" }}>
                        <AutocompleteField
                            options={deviceDropdown ?? []}
                            name={"Devices"}
                            label={"Devices"}
                            placeholder={"Select Device"}
                            variant="outlined"
                            sx={{ width: "50%" }}
                            getOptionLabel={(option) => `Device ${`${option.label}`.length === 1 ? `0${option.label}` : option.label}`}
                            shrinkLabel
                            size={"large"}
                            onValueChange={(e, newValue,reason) => {
                                console.info(newValue)
                                setdeviceId(reason!=="clear"? newValue.id:null)
                                setselectedDeviceLabel(reason!=="clear"? newValue.label:null)
                                if (errors['deviceId']) deleteFromErrors(errors, setErrors, 'deviceId')
                            }}
                            errorBool={errors?.deviceId}
                            isLoading={isLoading}
                        />
                            <Button sx={styles.textButton} onClick={() => { assignDevice(info?._id) }} disabled={!checkbox}>Assign Device
                            </Button>
                    </Box>
                </>
            }
            { total !== index+1 &&
            <Divider sx={{margin:'1rem 0rem 1rem 0rem'}}/>
            }
        </Box>
    );
}

export default MoreInfo;
