import {useRef, useEffect, memo} from 'react'
import { 
    Chart, registerables
} from "chart.js";
import { getTrimmedText } from '../../../Helpers/helpers';
import { Box } from '@mui/material';

Chart.register(...registerables);
const colors = ["#1963BA", "#4F7DC2", "#4DA7CE", "#5085AB", "#5581A9", "#4DB5F0", "#328ABC", "#2EA4BE", "#408CA5", "#59C3E4"]
const lighterShades = ["#4DB5F0", "#328ABC", "#2EA4BE", "#408CA5", "#59C3E4"]

function LineChart({labels, datasets, axisLabels, chartTitle}) {
    const canvas = useRef(null);
    
    useEffect(() => {
      const chart = new Chart(canvas.current, {
        type: "line",
        options:{
            hover: {
              mode: "dataset",
            },
            elements: {
              point: {
                hoverRadius: 5.5,
              },
            },
            responsive: true,
            plugins: {
              datalabels: {
                display: false
              },
              legend: {
                position: "top",
              },
              title: {
                display: chartTitle ? true : false,
                text: chartTitle,
                position: "top",
                align: "start"
              },
            },
            scales: {
                y: {
                  title: {
                    display: axisLabels.y ? true : false,
                    text: axisLabels.y
                  },
                  grid: {
                    display: datasets?.length === 0 ? false : true
                  },
                  ticks: {
                    precision: 0
                  }
                },
                x: {
                    title: {
                      display: axisLabels.x ? true : false,
                      text: axisLabels.x
                    },
                    grid: {
                        display: true
                      }
                  }
            },
        },
        data: {
            labels,
            datasets
        }
      });
      return () => chart.destroy();
    }, [canvas, datasets, labels, chartTitle, axisLabels]);
    return (
      <Box sx={{border: "1px solid", borderColor: "border.main", borderRadius: ".8rem", padding: "0 1rem 0.5rem 1rem"}}>
        <canvas ref={canvas} />
      </Box>
    );
  }

  export default memo(LineChart)