import React from "react";
import { SnackbarProvider } from "notistack";
import { styled } from "@mui/material";

const notistackRef = React.createRef();

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantInfo {
    background-color: white;
    color: black
  }
`;

function CustomSnackbar({children}) {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      preventDuplicate
      ref={notistackRef}
      onClose={(event, reason, key) => {
        if (reason === "clickaway") {
          notistackRef.current.closeSnackbar(key);
        }
      }}
    >
      {children}
    </StyledSnackbarProvider>
  );
}

export default CustomSnackbar;
