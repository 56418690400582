import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

const usePagination = ({ handler, isLoading, initialRowCount, tablesRedux = "AUTH", orders }) => {

  // console.info(orders,"ORDERS")
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const rowsPerPage = useSelector(x => x[tablesRedux.toLowerCase()].rowsPerPage) ?? 10;
  const [searchText, setSearchText] = useState("");
  const rowCounts = [5, 10, 15, 20, 25, 50, 75, 100];

  const fetchData = (pageValue, shouldSearch = true) => {
    if (searchText.length >= 2 && shouldSearch) handler((pageValue - 1) * rowsPerPage, rowsPerPage, searchText);
    else handler((pageValue - 1) * rowsPerPage, rowsPerPage, orders);
  };

  useEffect(() => {
    fetchData(page);
  }, [rowsPerPage]);

  const setRowsPerPage = (val) => {
    dispatch({
      type: tablesRedux.toUpperCase() + '_UPDATE_STATE',
      data: { rowsPerPage: val },
    });
  }
  // useEffect(() => {
  //   setRowsPerPage(initialRowCount ?? 5)
  // }, [])

  useEffect(() => {
    if (searchText.length >= 2) debouncedSearch();
  }, [searchText]);

  const handlePageChange = (value) => {
    if (isLoading) return;
    setPage(value);
    fetchData(value);
  };

  const handleChangeRows = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangeSearchText = (e) => {
    if (e.target.value.length === 1 && searchText.length === 0 && page > 1) setPage(1);
    if (e.target.value.length === 0 && searchText.length > 0) fetchData(page, false);
    setSearchText(e.target.value);
  };

  const handleCloseSearch = () => {
    if ((searchText.length > 0)) {
      if (page !== 1) setPage(1);
      fetchData(1, false);
      setSearchText("");
    }
  };

  const debouncedSearch = useDebouncedCallback(
    (params) => {
      if (searchText.length >= 2) {
        fetchData(page);
      };
    },
    700
  );

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchText,
    setSearchText,
    handleChangeSearchText,
    handleCloseSearch,
    handlePageChange,
    handleChangeRows,
    rowCounts
  };
};

export default usePagination;
