import { useState } from 'react'
import { TableRow } from '@mui/material'
import DeviceTableCell from './tableCell'
import { changeToSentenceCase } from '../../Helpers/helpers';
import { displayTime } from '../../Helpers/helpers';

function DeviceTableRow({row, clickHandlers}) {
  const [showPin, setShowPin] = useState(false)  
  const [archiveAnchorEl, setArchiveAnchorEl] = useState(null);

  const info = {
    assignedStatus: ["unassigned", "archived"].includes(row.status) ? false : true,
    deviceId: row.deviceId,
    battery: row?.deviceInfo?.battery?.batteryLevel,
    zone: row?.zone?.name,
    checkpoint: row?.checkpoint?.name,
    pin: row.pin,
    status: changeToSentenceCase(row.status),
    signedIn: row?.signedIn,
    updatedAt: displayTime(row?.updatedAt),
    actions: "",
    _id: row._id,
}
  return (
    <TableRow sx={{}}>
    {Object.keys(info).map((key) => (
      <DeviceTableCell 
         key={key}
         info={info}
         infoKey={key}
         clickHandlers={clickHandlers}
         states={{showPin, archiveAnchorEl}}
         setters={{setShowPin, setArchiveAnchorEl}}
         appVersion={row?.deviceInfo?.appVersion}
      />
    ))}
  </TableRow>
  )
}

export default DeviceTableRow