import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import App from "./App";
import theme from "./Config/theme";
import entriesReducer from "./Containers/Entries/Store/reducer";
import zonesReducer from "./Containers/Zones/Store/reducer";
import "./index.css";
import SnackbarUtils from "./Utility/snackbarUtils";
import authReducer from "./Containers/Auth/store/reducer";
import devicesReducer from './Containers/Device/store/reducer'
import alertReducer from "./Containers/Alert/store/reducer";
import notificationsReducer from "./Containers/Dashboard/notifications/store/reducer";
import userManagementReducer from "./Containers/UserManagement/store/reducer";

const composeEnhancers =
  (process.env.NODE_ENV === "development" ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  entries:entriesReducer,
  devices: devicesReducer,
  alert: alertReducer,
  zones: zonesReducer,
  notifications: notificationsReducer,
  userManagement: userManagementReducer
});

// Storing the redux in local storage
const persistConfig = {
  key: "root",
  storage,
};

const appReducer = (state, action) => {
  // Run this code when user logs out:- Remove the local storage and reset the store to initial state
  if (action.type === "AUTH_LOGOUT") {
    localStorage.removeItem("persist:root");
    window.clearInterval(window.intervalId);
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

// Redux-Persist
const persistedReducer = persistReducer(persistConfig, appReducer);

// Creating Store
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <SnackbarUtils>
              <App />
            </SnackbarUtils>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();