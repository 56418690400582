import {ReactComponent as DashboardIcon} from "../../Assets/svg/sidebar-dashboard.svg";
import {ReactComponent as IssuanceIcon} from "../../Assets/svg/sidebar-issuance.svg";
import {ReactComponent as VerificationIcon} from "../../Assets/svg/sidebar-verification.svg";
import {ReactComponent as DesignsIcon} from "../../Assets/svg/sidebar-designs.svg";
import {ReactComponent as SelfServiceIcon} from "../../Assets/svg/sidebar-selfService.svg";
import {ReactComponent as MessagesIcon} from "../../Assets/svg/sidebar-messages.svg";
import {ReactComponent as SupportIcon} from "../../Assets/svg/sidebar-support.svg";


export const sidebarData = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardIcon/>
  },
  {
    label: "Entries",
    link: "/entries",
    icon: <DesignsIcon />
  },
  {
    label: "Device Management",
    link: "/devices",
    icon: <IssuanceIcon />,
  },
  {
    label: "Zones",
    link: "/zones",
    icon: <VerificationIcon />,
  },
  {
    label: "User Management",
    link: "/user-management",
    icon: <VerificationIcon />,
  },
  // {
  //   label: "General Settings",
  //   link: "/",
  //   icon: <MessagesIcon />
  // },
  // {
  //   label: "Support",
  //   link: "/",
  //   icon: <SupportIcon />
  // }
];
