import { getEntriesGraphStats } from "../../Entries/Store/actions"
import dayjs from "dayjs"
// const durationOptions = ["Hourly", "Daily", "Weekly"]
const durationOptions = { "past 12 hours": "Hourly", "Past Week": "Daily", "Past Month": "Weekly" }
const reverseMappingDurationOptions = { "Hourly": "past 12 hours", "Daily": "Past Week", "Weekly": "Past Month" }
const statsLabels = {
    totalEntries: "Total visitors",
    signedIn: "Signed In",
    signedOff: "Signed Off",
    manualSignOff: "Manual Signed Off"
}

const durationValues = {
    Hourly: {
        prevTimeDuration: (1000 * 60 * 60 * 12),
        timeDifference: 1000 * 60 * 60,
        label: "Hour",
    },
    Daily: {
        prevTimeDuration: (1000 * 60 * 60 * 24 * 7),
        timeDifference: 1000 * 60 * 60 * 24,
        label: "Day"
    },
    Weekly: {
        prevTimeDuration: (1000 * 60 * 60 * 24 * 7 * 3),
        timeDifference: 1000 * 60 * 60 * 24 * 7,
        label: "Week"
    }
}

const getLabel = (labelType, x) => {
    if (labelType == 'Day')
    {
        console.info(labelType, x)
        var d = new Date();
        d.setDate(d.getDate() - (7 - x));
        return dayjs(d).format('DD MMM YY')
    }
    if (labelType == 'Week')
    {
        if (x == 4)
        {
            return 'Current Week'
        } else return `${labelType} ${x}`
    }
    if (labelType == 'Hour')
    {
        var d = new Date();
        var time = dayjs(d).format('HH') - (12 - x)
        return `${time}:00`
    }

    return `${labelType} ${x}`

}

const getLineGraphData = async ({ graphType, dataKey, interval, duration, zoneId }) => {
    const params = {
        type: graphType,
        zone: zoneId,
        period: duration.toLowerCase(),
        from: new Date(interval.current.from),
        to: new Date(interval.current.to)
    }
    const data = await getEntriesGraphStats(params)()
    if (!data || data.length === 0) return
    const timeDiff = durationValues[duration].timeDifference
    const timeArray = []
    const initTime = new Date(interval.current.from).getTime()
    const finalTime = new Date(interval.current.to).getTime()
    //array of time differences
    //will have some objects (four in case of weeks) each having from and to values for the given time diff
    for (let i = initTime; i < finalTime; i += timeDiff)
    {
        const from = i === 0 ? i : (i + 1)
        timeArray.push({
            from,
            to: from + timeDiff,
            entries: {},
            // label: `${durationValues[duration].label} ${timeArray.length + 1}`
            label: getLabel(durationValues[duration].label, timeArray.length + 1)
        })
    }
    const formattedData = [];

    //organising and grouping data based on the different intervals
    data.forEach(record => {
        const entries = record[dataKey].entries
        const _id = record[dataKey]._id
        const labelValue = record[dataKey].name || _id
        if (entries?.length > 0) formattedData.push({ _id, label: labelValue })
        if (entries?.length > 0)
        {
            timeArray.forEach(interval => {
                entries.forEach(entry => {
                    const timeInMs = new Date(entry.date).getTime()
                    if (timeInMs >= interval.from && timeInMs <= interval.to)
                    {
                        const prevValue = interval.entries[_id] ?? 0
                        interval.entries[_id] = prevValue + entry.count
                    }
                })
            })
        }
    })
    //formatting/separating data for different checkpoints/visitor types
    for (let i = 0; i < formattedData.length; i++)
    {
        const _id = formattedData[i]._id
        formattedData[i].data = []
        formattedData[i].labels = []
        for (let j = 0; j < timeArray.length; j++)
        {
            formattedData[i].labels[j] = timeArray[j].label
            if (timeArray[j].entries[_id] !== undefined)
            {
                formattedData[i].data[j] = timeArray[j].entries[_id]
            }
            else formattedData[i].data[j] = 0
        }
    }
    return formattedData
}

export { getLineGraphData, durationValues, durationOptions, statsLabels, reverseMappingDurationOptions }