/* eslint-disable import/no-anonymous-default-export */
export default {
  outer: {
    border: ".75px solid",
    borderColor: "border.main",
    borderRadius: "8px",
    height: "fit-content",
    position: "relative",
    // cursor: "pointer",
  },
  zones: {
    outer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      height: "100%"
    },
    icon: {
      height: "2.35rem",
      width: "2.35rem"
    },
    bottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    deviceRow: {
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: "center", 
      padding: ".55rem 0"
    },
    extraPadding: {
      padding: {
        xs: "1.5rem 2rem 1.5rem 2rem",
        xl: "2rem 2rem 1.5rem 2rem"
      },
    },
    extraPaddingBottomSection: {
      padding: {
        xs: "1.1rem 2rem 1.5rem 2rem",
        xl: "1.2rem 2rem 1.5rem 2rem"
      },
      paddingTop: "0rem",
      mt: "-0.75rem"
    },
  },
  textButton: {
    typography: "h4",
    color: "primary.main",
    fontWeight: 600
  },
  timeBox: { 
    display:'flex',
    flexWrap:'none',
    border: '1px solid #DADADA',
    borderRadius: '8px',
    background: '#F6F7FF',
  },
  checkpointCount:{
    letterSpacing: '0.32em',
    color: 'white',
    paddingLeft:'3px'
  },
  fontStyle:{
    fontSize: '1.3rem',
    fontWeight: '700'
  }
};
