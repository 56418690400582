import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import 'animate.css';
import * as React from 'react';
import PillButton from '../../Components/Buttons/PillButton';
import Search from '../../Components/Input/Autocomplete/search';
import DropdownTextfield from '../../Components/Input/Dropdown/index-new';
import Textarea from '../../Components/Input/Textarea';
import StandardTextfield from '../../Components/Input/TextField/index';
import { getCheckpointList, getVisitsConfigs, getZoneList, signOffManual } from './Store/actions';
import { useSnackbar } from "notistack"
import { useSelector } from "react-redux";

const initialInput = {
  reason: "",
  checkpoint: "",
}


export default function ManualSignOff({ entry, close, fetchData, page }) {


  // const rowsPerPage = useSelector(x => x['AUTH'.toLowerCase()].rowsPerPage)
  const rowsPerPage = useSelector(x => x.entries.rowsPerPage)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { _id: id, zone } = entry;
  const [input, setInput] = React.useState(initialInput)
  const [checkpoints, setCheckpoints] = React.useState([])
  const [selectedReason, setSelectedReason] = React.useState(null)
  const [reasons, setReasons] = React.useState([])

  const changeValue = (field, value) => {
    setInput({ ...input, [field]: value })
  }

  React.useEffect(() => {
    const fn = async () => {
      const res = await getCheckpointList(
        zone?._id
      );
      setCheckpoints(res.list)
    }
    fn();
  }, [])

  React.useEffect(()=>{
    dispatch(getVisitsConfigs("reasonOfManualSignOff")).then(res => {
      if(res?.list) setReasons(res?.list?.map(row => row.texts[0].text))
    })
  },[dispatch])


  const onSubmit = () => {

      dispatch(signOffManual({
        ...input,
        id
      })).then((res) => {
        if(res == 0){
        enqueueSnackbar("Signed Out Successfully", {
          variant: "success"
        })
        fetchData(page,rowsPerPage)
        }
        close()
      })
  }

  React.useEffect(() => {

    if(selectedReason!=='Other'){
      changeValue('reason', selectedReason)
    }else{
      changeValue('reason', "")
    }

  },[selectedReason])

  console.info(input,"INPUT")

  return (

    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >

      {/* <Search
        sx={{ width: "100%", margin: '0.5em 0' }}
        placeholder="Enter Zone"
        value={input.zone}
        label="Zone"
        apiTrigger={async (searchThis, newSeedValue) => {
          const res = await getZoneList(
            newSeedValue,
            10,
            searchThis
          );
          console.log('res', res);
          return res;
        }}
        getName={(n) => {
          return {
            primary: n,
            secondary: "",
          };
        }}
        updateSelectedKey={(v) => {
          changeValue('zone', v)
        }}
      /> */}
      <DropdownTextfield
        label="Checkpoint"
        size="medium"
        optionsArray={['- Select -', ...checkpoints.map(x => ({ label: x.name, id: x._id }))]}
        value={input.checkpoint}
        onChange={(e) => {
          changeValue('checkpoint', e.target.value)
        }}
        sx={{ width: "100%", margin: '1em 0' }}
      />

      <DropdownTextfield
        label="Select Reason"
        size="medium"
        optionsArray={reasons}
        value={selectedReason}
        onChange={(e) => {
          // changeValue('checkpoint', e.target.value)
          setSelectedReason(e.target.value)
        }}
        sx={{ width: "100%", margin: '1em 0' }}
      />

      { selectedReason == 'Other' &&
      <StandardTextfield
        placeholder="Enter a valid reason"
        type="text"
        multiline
        rows={4}
        required
        variant='outlined'
        label='Enter here'
        value={input.reason}
        onChange={(e) => changeValue('reason', e.target.value)}
      />
      }

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <PillButton
          text={"Cancel"}
          border="none"
          invertedColor
          backgroundColor={"#ffffff"}
          typography={"body2"}
          onClick={() => {
            setInput(initialInput);
            close();
          }}
        />
        <PillButton
          disabled={!(input.reason?.length > 0 && input.checkpoint?.length > 0)}
          text={"Sign Out"}
          typography={"body2"}
          sx={{ margin: '1em' }}
          onClick={onSubmit}
        />

      </Grid>
    </Grid>

  );
}