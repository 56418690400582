import React,{ useEffect, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Divider,
    Button,
    Badge,
    badgeClasses
} from "@mui/material";
import styles from "../styles";
import { fetchNotifications } from './store/actions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsModal from '../../../Components/NotificationsModal';
import NotificationIcon from '@mui/icons-material/NotificationsOutlined';
import Notification from './notification';

function Notifications() {
    const dispatch = useDispatch()
    const notificationsState = useSelector(s => s.notifications)
    const {notifications, unreadCount} = notificationsState
    const [openNotificationsModal, setOpenNotificationsModal] = useState(false)

    useEffect(() => {
        dispatch(fetchNotifications())
    },[dispatch])

    return (
        <div style={{overflowY: "hidden", height: "100%", display: "flex", flexFlow: "column"}}>
        {
            openNotificationsModal && 
            <NotificationsModal
             open={openNotificationsModal}
             setOpen={setOpenNotificationsModal}
             unreadCount={unreadCount}
             />
        }
            <Box sx={{ padding: "1.2rem 1.4rem", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                  <Badge variant="standard" color="secondary" badgeContent={unreadCount}
                   sx={{
                        width: "1.7rem",
                        [`& .${badgeClasses.standard}`]: {
                        width: 10,
                        padding:1.1,
                        borderRadius: "100%",
                        typography: "caption",
                        backgroundColor: "error.regular"
                        }
                  }}
                  >
                   <NotificationIcon color="primary" sx={{height: "1.8rem", width: "1.8rem"}}/>
                  </Badge>
                    <Typography variant="h3">
                        Notifications
                    </Typography>
                </div>
                {
                    notifications?.length > 0 && 
                    <Button 
                    onClick={() => setOpenNotificationsModal(true)}
                    endIcon={<ChevronRightIcon/>}
                    sx={{typography: "h4"}}>
                        See all
                    </Button>
                }
            </Box>
            <Divider />
            <Box sx={styles.notificationsOuterBox}>
                {notifications?.length === 0 ? 
                 <Typography variant="h4" sx={{margin: "1.5rem 2rem"}}>No new notifications</Typography> :
                 notifications?.map((notification, index) => (
                  <Notification 
                    key={notification._id} 
                    notification={notification} 
                    index={index} 
                  />
                ))}
            </Box>
        </div>
    )
}

export default memo(Notifications)