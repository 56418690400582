import React from 'react'
import { Button, Box, Divider, Typography, IconButton, Collapse, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress } from "@mui/material";
import { useSelector } from 'react-redux';
import { TransitionGroup } from "react-transition-group";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './styles';
import StandardTextfield from '../../Components/Input/TextField';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/CancelRounded';
import WarningModal from '../../Components/WarningModal';
import useEntryManagement from './hooks/useEntryManagement';
import PatchedTooltip from '../../Components/PatchedTooltip';
import CustomSwitch from '../../Components/Switch';

const languages = ["English", "Hindi"]

function EntryManagement() {

  const {componentAccessMap} = useSelector((state) => state.auth);
  const { states, functions, misc } = useEntryManagement()

  const {
      showWarningModal, setShowWarningModal,
      expanded, 
      isLoading, 
      errors, 
  } = states

  const {
      handleDelete, handleToggleAccordion,
      handleChange, handleSubmit,
      makeEditable, makeUnEditable,
      beginDelete, handleAddNew,
      handleChangeHostReqd
  } = functions

  const {
      visitsConfigMap,
      toBeDeletedConfig,
      isAnyRowEditable
  } = misc;

  return (
    <Box sx={{ marginBottom: "2%", padding: "0 1.4rem" }}>
      {
        showWarningModal &&
        <WarningModal
          open={showWarningModal}
          setOpen={setShowWarningModal}
          subtitle={`Remove ${toBeDeletedConfig.current.configObj.title}`}
          caption={`Are you sure you want to remove this ${toBeDeletedConfig.current.configObj.title?.toLowerCase()}?`}
          handler={handleDelete}
        />
    }
          <Typography variant="h2" color="text.heading">Entry Management</Typography>
          <Divider sx={{ my: 1, mb: "2rem" }} />
          <Box sx={{display: "flex", flexFlow: "column", rowGap: "2rem"}}>
          {
            visitsConfigMap?.map(row => 
            <Box sx={styles.outerBox} key={row.key}>
              <PatchedTooltip
                title={!componentAccessMap?.profile?.viewVisitsConfig ? "You don't have permission to use this. Contact Administrator" : ""}
               >
                <Button 
                sx={{width: "100%", padding: "1.2rem 1.4rem"}}
                onClick={() => {
                  if (!componentAccessMap?.profile?.viewVisitsConfig) return;
                  handleToggleAccordion(row.key)
                }}
                disabled={!componentAccessMap?.profile?.viewVisitsConfig}
                >
                    <Box sx={styles.accordionTopRow}>
                        <Box sx={{display: "flex", flexFlow: "column", alignItems: "flex-start", rowGap: "1rem"}}>
                          <Typography variant="h4" color="text.heading">{row.title}</Typography>
                          <Typography variant="subtitle1" color="text.heading" sx={{fontWeight: "400"}}>{row.subtitle}</Typography>
                        </Box>
                        {expanded[row.key] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Box>
                </Button>
              </PatchedTooltip>
              <TransitionGroup>
               {
                  expanded?.[row.key] && 
                      <Collapse>
                          <Box sx={styles.innerBox}>
                            {
                                <Box sx={{overflowY: "auto", flex: 1,}}>
                                    <Table
                                        size="small"
                                        >
                                        <colgroup>
                                            <col width="25%" />
                                            <col width="25%" />
                                            <col width="25%" />
                                            <col width="25%" />
                                        </colgroup>
                                        <TableHead>
                                            {
                                                languages?.map(language => 
                                                  <TableCell sx={styles.tableHeaderCell}>
                                                    <Typography variant="h4">
                                                    {language ? `${row.tableColumnTitle} in ${language}` : row.tableColumnTitle}
                                                    </Typography>
                                                  </TableCell>
                                                )
                                            }
                                            {
                                              row.configType === "visitPurpose" &&
                                              <TableCell sx={styles.tableHeaderCell}>
                                                  <Typography variant="h4">
                                                    {"Host Required"}
                                                  </Typography>
                                              </TableCell>
                                            }
                                            <TableCell sx={styles.tableHeaderCell}>
                                                    <Typography variant="h4">
                                                    {"Actions"}
                                                    </Typography>
                                            </TableCell>
                                            <TableCell sx={styles.tableHeaderCell}/>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            isLoading?.[row.key] ? 
                                            <TableRow>
                                             <TableCell colSpan={4} sx={{ borderTop: "1px solid #DADADA"}}>
                                              <Box sx={styles.loaderWrapper}>
                                                  <CircularProgress />
                                              </Box> 
                                             </TableCell>
                                            </TableRow> :
                                            row?.state?.temporary?.map((record, index) => {
                                            const englishTextIndex = record.texts.findIndex(item => item.langCode === "en");
                                            const hindiTextIndex = englishTextIndex === 0 ? 1 : 0;
                                            const englishLangCode = record?.texts?.[englishTextIndex]?.langCode
                                            const hindiLangCode = record?.texts?.[hindiTextIndex]?.langCode
                                            return (
                                              <TableRow
                                                   sx={{
                                                backgroundColor: index % 2 === 0 ? "#FEFEFE" : "#f5f5f5",
                                                borderTop: "1px solid #DADADA",
                                             }}
                                              >
                                                <TableCell sx={{border: "none"}}>
                                                  {
                                                    record.isEdit ?
                                                    <StandardTextfield 
                                                      value={record?.texts?.[englishTextIndex]?.text}
                                                      size={"small"}
                                                      sx={{width: "100%"}}
                                                      onChange={(e) => handleChange(row, record, englishTextIndex, englishLangCode, e.target.value)}
                                                      errorBool={errors?.["en"]?.length > 0}
                                                      helperText={errors?.["en"] ?? (errors?.["hi"] && ".")}
                                                      showHelperTextAlways={(errors?.["en"] || errors?.["hi"])}
                                                    /> : 
                                                    <Typography variant="subtitle2" sx={{fontWeight :"400", whiteSpace:"wrap", wordBreak: "break-all"}}>
                                                    {record?.texts?.[englishTextIndex]?.text || "-"}
                                                    </Typography>
                                                  }
                                                </TableCell>
                                                <TableCell sx={{border: "none"}}>
                                                {
                                                    record.isEdit ?
                                                    <StandardTextfield 
                                                      value={record?.texts?.[hindiTextIndex]?.text}
                                                      size={"small"}
                                                      sx={{width: "100%"}}
                                                      onChange={(e) => handleChange(row, record, hindiTextIndex, hindiLangCode, e.target.value)}
                                                      errorBool={errors?.["hi"]?.length > 0}
                                                      helperText={errors?.["hi"] ?? (errors?.["en"] && ".")}
                                                      showHelperTextAlways={(errors?.["en"] || errors?.["hi"])}
                                                    /> : 
                                                    <Typography variant="subtitle2" sx={{fontWeight :"400"}}>
                                                    {record?.texts?.[hindiTextIndex]?.text || "-"}
                                                    </Typography>
                                                  }
                                                </TableCell>
                                                {
                                                  row.configType === "visitPurpose" && 
                                                  <TableCell>
                                                  {
                                                    record.isEdit ?
                                                    <CustomSwitch 
                                                      size="small"
                                                      checked={record.hostRequired}
                                                      onChange={(e) => handleChangeHostReqd(row, record, e.target.checked)}
                                                    /> : 
                                                      <CustomSwitch 
                                                      size="small"
                                                      checked={record.hostRequired}
                                                      disabled={true}
                                                    />
                                                  }
                                                  </TableCell>
                                                }
                                                <TableCell sx={{border: "none"}}>
                                                  {
                                                    record.isEdit ? 
                                                    <Box sx={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                                                      <IconButton size="small" sx={{color: "success.main"}}
                                                      onClick={() => handleSubmit(row, record)}
                                                      >
                                                        <CheckIcon style={{height: "2.1rem", width: "2.1rem"}} />
                                                      </IconButton>
                                                      <IconButton size="small" sx={{color: "#ff6347"}}
                                                      onClick={() => makeUnEditable(row, record)}
                                                      >
                                                        <CancelIcon style={{height: "2.1rem", width: "2.1rem"}}/>
                                                      </IconButton>
                                                    </Box> :
                                                    <Box sx={{display: "flex", alignItems: "center", columnGap: "1.1rem"}}
                                                    >
                                                       <PatchedTooltip 
                                                       title={!componentAccessMap?.profile?.editVisitsConfig ? "You don't have permission to use this. Contact Administrator" : ""}
                                                       >
                                                        <IconButton size="small" color="primary"
                                                        onClick={() => makeEditable(row, record)}
                                                        disabled={isAnyRowEditable || !componentAccessMap?.profile?.editVisitsConfig}
                                                        >
                                                          <EditIcon style={{height: "2rem", width: "2rem"}} />
                                                        </IconButton>
                                                       </PatchedTooltip>
                                                        <PatchedTooltip
                                                          title={!componentAccessMap?.profile?.deleteVisitsConfig ? "You don't have permission to use this. Contact Administrator" : ""}
                                                        >
                                                          <IconButton size="small" sx={{color: "error.regular"}}
                                                          disabled={isAnyRowEditable || !componentAccessMap?.profile?.deleteVisitsConfig}
                                                          onClick={() => beginDelete(row, record)}
                                                          >
                                                            <DeleteIcon style={{height: "2rem", width: "2rem"}} />
                                                          </IconButton>
                                                        </PatchedTooltip> 
                                                    </Box>
                                                  }
                                                </TableCell>
                                                <TableCell sx={{border: "none"}}/>
                                              </TableRow> 
                                            )
                                        })}
                                        </TableBody>
                                </Table>
                                <Box sx={{display: "flex", justifyContent: "space-between", padding: "1rem 1.75rem", borderTop: "1px solid #DADADA"}}>
                                  <PatchedTooltip
                                    title={!componentAccessMap?.profile?.addVisitsConfig ? "You don't have permission to use this. Contact Administrator" : ""}
                                  >
                                    <Button
                                     sx={{typography: "h4", color: "primary.main"}}
                                     startIcon={<AddIcon />}
                                     onClick={() => handleAddNew(row)}
                                     disabled={!componentAccessMap?.profile?.addVisitsConfig}
                                    >
                                        Add New
                                    </Button>
                                  </PatchedTooltip>
                                </Box>
                                </Box>
                            }
                          </Box>
                      </Collapse>
               }
                  </TransitionGroup>
          </Box>
            )
          }
          </Box>
        </Box>
  )
}

export default EntryManagement