/* eslint-disable camelcase */
import axios from "axios";
import API from "../../../api";
import { baseURLs } from "../../../Axios";
import { getAccessToken } from "../../../Utility/tokenUtils";
import * as actionTypes from "./actionTypes";
import { adjustZone } from "./helper";

const fetchZones = (paramsObj = {}) => {
  const sort='createdAt'; const order=-1
  const {stats, from, to, doNotDispatch, active} = paramsObj;
  const token = `Bearer ${getAccessToken()}`;

  let queryString = ""
  queryString += `&sort=${sort}&order=${order}` 
  if (active !== undefined) queryString += `&active=${active}`
  if(stats){ 
    queryString += `&statistics=true`
    if(from) queryString += `&from=${from}`
    if(to) queryString += `&to=${to}`
  }
 
  return async (dispatch) => {
    if(!doNotDispatch){
      dispatch({
        type: actionTypes.FETCH_ZONES,
        data: [],
      });
    }
    const response = await axios.get(
      `${baseURLs.verifier}${API.zone.zone}?${queryString}`,
      { headers: { Authorization: `${token}` } },
    );

    if (response?.data?.code === 0) {
      if(!doNotDispatch){
        return dispatch({
          type: actionTypes.FETCH_ZONES,
          data: response.data.data,
          // itemCount: response.data.data.total,
        });
      }
      return response.data.data
    }
  };
};

const addZone = (zoneInfo) => {
  let zone = adjustZone(zoneInfo);
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.verifier}${API.zone.zone}`,
      zone,
      { headers: { Authorization: `${token}` } },
    );
    return response
  };
}


const updateZone = (zoneInfo, id) => {
  let zone = adjustZone(zoneInfo)
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.put(
      `${baseURLs.verifier}${API.zone.zone}/${id}`,
      zone,
      { headers: { Authorization: `${token}` } },
    );
    return response
  };
}

const handleActivation = (checked,id) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    let response
    if(checked){
      response = await axios.put(
        `${baseURLs.verifier}${API.zone.zone}/${id}/activate`,
        {},
        { headers: { Authorization: `${token}` } },
      );
    }else{
    response = await axios.put(
      `${baseURLs.verifier}${API.zone.zone}/${id}/deactivate`,
      {},
      { headers: { Authorization: `${token}` } },
    );
    }
    if (response?.data?.code == 0)
    {
      return 1
    }
  };
}

const handleCheckpointActivation = (checked, id) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    let response
    if (checked)
    {
      response = await axios.put(
        `${baseURLs.verifier}${API.zone.checkpoint}/${id}/activate`,
        {},
        { headers: { Authorization: `${token}` } },
      );
    } else
    {
      response = await axios.put(
        `${baseURLs.verifier}${API.zone.checkpoint}/${id}/deactivate`,
        {},
        { headers: { Authorization: `${token}` } },
      );
    }
    return response;
  };
}

const fetchZoneById = (id) => {
  return async (dispatch) => {
    const response = await axios.get(
      `${baseURLs.verifier}${API.zone.zone}/${id}`,
    );
    if (response?.data?.code === 0) {
      dispatch({
        type: actionTypes.FETCH_ZONE,
        data: response.data.data,
      });
    }
  };
}

const deleteZone = (id, isActive = true) => {
  return async (dispatch, getState) => {
    const filters = getState().zones.filters;
    const response = await axios.delete(
      `${baseURLs.verifier}${API.zone.zone}/${id}`,
    );
    if (response?.data?.code === 0)
    {
      const params = {}
      if(filters.active !== undefined && filters.active!==null) {
        params.active = isActive;
      }
      dispatch(fetchZones(params))
    }
  };
}

const blacklistCandidate = (zoneId, reqBody) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch, getState) => {
    const response = await axios.put(
      `${baseURLs.verifier}${API.zone.zone}/${zoneId}/blacklist`,
      reqBody,
      { headers: { Authorization: `${token}` } },
    );
    return response?.data?.code === 0;
  };
}

const fetchBlacklistedCandidates = ({id, seed, count} = {}) => {
  return async (dispatch) => {
    let queryString = "";
    if (seed !== undefined && count !== undefined) queryString += `&seed=${seed}&count=${count}`;
    const response = await axios.get(
      `${baseURLs.verifier}${API.zone.zone}/${id}/blacklist?${queryString}`,
    );
    if (response?.data?.code === 0) {
      dispatch({
        type: actionTypes.FETCH_BLACKLISTED_CANDIDATES,
        data: response.data.data.list,
        total: response.data.data.total
      });
    }
  };
}

const unBlacklistCandidate = (zoneId, reqBody) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch, getState) => {
    const response = await axios.delete(
      `${baseURLs.verifier}${API.zone.zone}/${zoneId}/blacklist`,
      { 
        data: reqBody, 
        headers: { Authorization: `${token}` }
      }
    );
    return response?.data?.code === 0;
  };
}



export default {
  fetchZones,
  addZone,
  deleteZone,
  updateZone,
  handleActivation,
  fetchZoneById,
  handleCheckpointActivation,
  blacklistCandidate,
  fetchBlacklistedCandidates,
  unBlacklistCandidate
};

