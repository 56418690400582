import React, {useState, useEffect, useCallback} from 'react'
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, } from 'react-redux';
import { useSnackbar } from 'notistack';
import StandardTextfield from '../../Input/TextField';
import PillButton from '../../Buttons/PillButton';
import styles from './styles'
import { deleteFromErrors } from '../../../Helpers/helpers';
import { isValidEmail, isValidMobile } from '../../../Utility/validations';
import { addUser, fetchRoles } from '../../../Containers/UserManagement/store/actions';
import AutocompleteField from '../../Input/Autocomplete';

const fields = [
    {
        label: "Name",
        name: "name"
    },
    {
        label: "Email",
        name: "email"
    },
    // {
    //     label: "Designation",
    //     name: "designation"
    // },
    // {
    //     label: "Contact Number",
    //     name: "mobile"
    // }
  ]

function AddUserModal({open, setOpen, fetchUsers}) {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch();   
  
  const [userDetails, setUserDetails] = useState({})
  const [allRoles, setAllRoles] = useState([]);
  const [role, setRole] = useState(null);
  const [roleSearchTerm, setRoleSearchTerm] = useState("")
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

const getRoles = useCallback(() => {
      dispatch(fetchRoles()).then(res => {
          if(res) setAllRoles(res?.list); 
      })
}, [dispatch])  

useEffect(()=>{
  getRoles();
},[getRoles])  

  const handleClose = () => setOpen(false)

  const handleChange = (value, name) => {
    const isNumber = /^-?\d*\.?\d*$/;
    if (name === "mobile" && (!isNumber.test(value) || value.length > 10) ) return;
    if (name === "role") setRole(value);
    else setUserDetails(prev => ({...prev, [name]: value}));
    if(errors?.[name]) deleteFromErrors(errors, setErrors, name)
  }

  const handleSubmit = () => {
    const {name, email, designation, mobile} = userDetails;
    const tempErrors = {};
    if (!name || name?.length < 3) {
        tempErrors.name = "At least 3 letters are needed"
    }
    // if (!designation) tempErrors.designation = "required";
    if (!email) tempErrors.email = "required";
    else if (email && !isValidEmail(email)) {
        tempErrors.email = "Invalid Email"
    }
    if (mobile && !isValidMobile(mobile)) {
        tempErrors.mobile = "Invalid Contact Number"
    }
    if (!role) tempErrors.role = "required"
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors)

    const reqData = {
        username:email,
        name: name,
        roles: [role?._id ],
        type: 'User'
    }
    setIsLoading(true)
    dispatch(addUser(reqData)).then(res => {
      setIsLoading(false)
        if (res === "success" ) {
          enqueueSnackbar("User created", {variant: "success"});
          fetchUsers(0, 5)
          handleClose();
        }
    })
  }


  return (
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth
  >
    <Box sx={styles.topRow}>
    <Typography variant="h3">{"Add a New User"}</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
        </IconButton>
    </Box>
    <DialogContent sx={styles.dialogContent}>
     {
      isLoading ? 
      <Box sx={{minHeight: "20rem", display: "grid", placeItems: "center"}}>
        <CircularProgress />
      </Box> : 
      <React.Fragment>
        {
          fields.map(field => 
          <StandardTextfield 
            key={field.label}
            label={field.label}
            value={userDetails?.[field.name] ?? ""}
            onChange={(e) => handleChange(e.target.value, field.name)}
            errorBool={errors?.[field.name]?.length > 0}
            helperText={errors?.[field.name]}
          />
        )
        }
        <AutocompleteField
          options={ allRoles ?? [] }
          name={"Role"}
          label={"Role"}
          placeholder={"Select Role"}
          variant="outlined"
          selectedValue={role}
          fieldValue={roleSearchTerm}
          sx={{width: "100%"}}
          heightDropdown={"17rem"}
          size={"large"}
          getOptionLabel={(option) => option.name}
          onValueChange={(e, newValue) => {
            handleChange(newValue, "role")
          }}
          onFieldInputChange={(e, newValue)=> {
            setRoleSearchTerm(newValue);
          }}
          errorBool={errors?.role}
        />
      </React.Fragment>
     }
    </DialogContent>
      <Box sx={{backgroundColor: "#FAFAFA", display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "1.1rem 2.5rem", columnGap: "2rem"}}>
        <Button sx={{typography: "h4"}}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <PillButton 
          text="Send Invite"
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </Box>
  </Dialog>
  )
}

export default  AddUserModal