export const styles = {
    batteryTop: {
        width: ".5rem",
        height: ".22rem",
        borderRadius: ".5rem",
      },
    batteryOuter: {
        height: "1.4rem",
        width: ".9rem",
        border: "1.5px solid",
        display: "flex",
        alignItems: "flex-end"
      },
    batteryInner: {
       width: "100%",
      },
    batteryWrapper: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center"
      },
    batteryBox: { 
        display: "flex", 
        alignItems: "center",
        columnGap: ".9rem"
      },
};