const styles  = {
    dialogContent: {
        padding: "1.5rem 2.75rem",
        minHeight: "8.5rem",
        display: "flex",
        flexDirection: "column",
        rowGap: "3.25rem",
        paddingBottom: "2.75rem",
        width: "40rem"
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "background.third",
        alignItems: "center",
        padding: ".6rem",
        paddingLeft: "1.5rem",
        marginBottom: "1rem"
      },
      midBox: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        rowGap: "2rem",
      },
      qr: {
        width: "10rem",
        height: "10rem"
      },
      loaderBox: {
        display: "grid",
        placeItems: "center",
        minHeight: "30.7vh"
      }
}

export default styles;