
import MoreVert from '@mui/icons-material/MoreVert';
import { Grid, IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PillButton from '../../Components/Buttons/PillButton';
import CustomDialog from '../../Components/CustomDialog';
import PaginatedTable from '../../Components/PaginatedTable';
import { camelCaseToString, displayTime, getTimeAgo } from '../../Helpers/helpers';
import { states } from '../../Helpers/utils';
import ManualSignOff from './ManualSignOff';
import { getVisitorData } from './Store/actions';
import VisitorCard from './VisitorCard';
import * as actionTypes from "./Store/actionTypes";
import PatchedTooltip from '../../Components/PatchedTooltip';


const getStatus = (state) => {

  switch (state) {
    case states.SIGNED_IN: return 'dot-green';
    case states.SIGN_OFF: return 'dot-grey';
    default: return 'dot-yellow';
  }
}

export default function TabularDetails({ fetchData, page }) {
  const dispatch = useDispatch()
  const {componentAccessMap} = useSelector(s => s.auth);
  const [openManualSignOffDialog, setOpenManualSignOffDialog] = React.useState(false)
  const entries = useSelector(x => x.entries.entriesData)
  // const visitorData = useSelector(x => x.entries.visitorData)

  //more details
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEntryId, setSelectedEntryId] = React.useState('') 
  const open = Boolean(anchorEl);


  const handleMoreClick = React.useCallback(
    (event, id) => {
      setAnchorEl(event.currentTarget);
      dispatch(getVisitorData(id))
    },
    [dispatch],
  );

  
  const handleMoreClose = () => {
    dispatch({
      type: actionTypes.ENTRIES_UPDATE_STATE,
      data: { visitorData: null },
    });
    setAnchorEl(null);
  };



  // React.useEffect(() => {
  //   fetchData(0, 5)

  // }, [])
  // console.log('entries', entries, visitorData, open)

  return (
    <Box sx={{ height: "100%", width: '100%' }}>
      <PaginatedTable
        tablesRedux="ENTRIES"
        title="Entry Details"
        cellPadding={".6rem"}
        handler={fetchData}
        searchHandler={fetchData}
        data={React.useMemo(
          () =>
            entries?.list?.map((entry, index) => {

              // let name;
              // let email;
              // let mobile;
              // if (cred.candidate) {
              //   name = cred.candidate.name;
              //   email = cred.candidate.email;
              //   mobile = cred.candidate.mobile;
              // }
              return {
                name: entry.name ?? "-",
                identifier: entry.identifier ?? "-",
                type: entry.type ?? "-",
                // hostName: (entry.purpose == "Meet a Friend") ? entry.host.name : "-",
                // hostAddress: (entry.purpose == "Meet a Friend") ? entry.host.address : "-",
                zoneName: entry?.zone?.name ?? "-",
                purpose: entry.purpose ?? "-",
                arrival: entry?.in?.time ? (<Tooltip arrow
                  title={getTimeAgo(entry.in.time)}>
                  <span>{displayTime(entry.in.time)}
                  </span></Tooltip>) : "-",
                departure: entry?.out?.time ? (<Tooltip arrow
                  title={getTimeAgo(entry.out.time)}>
                  <span>{displayTime(entry.out.time)}
                  </span></Tooltip>) : "-",
                actions: (
                  <Grid container display="flex"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ flexWrap: 'nowrap' }}>
                    <Tooltip arrow title={camelCaseToString(entry.state)}>
                      <span className={getStatus(entry.state)}></span>
                    </Tooltip>
                    {entry.state === states.SIGNED_IN ?
                    <PatchedTooltip title={!componentAccessMap?.entries?.manualSignOff ? "You don't have permission to use this. Contact Administrator" : ""}>
                      <PillButton
                        text={"Sign Out"}
                        invertedColor
                        border="none"
                        backgroundColor={"#ffffff"}
                        padding={{ vertical: "2px", horizontal: "2px" }}
                        typography={"subtitle1"}
                        onClick={() => {
                          if (!componentAccessMap?.entries?.manualSignOff) return;
                          setOpenManualSignOffDialog(entry)
                        }}
                        sx={{ margin: '5px' }}
                        disabled={!componentAccessMap?.entries?.manualSignOff}
                      /> 
                    </PatchedTooltip> :
                     null}
                    <PatchedTooltip
                     title={!componentAccessMap?.entries?.candidateInfo ? "You don't have permission to use this. Contact Administrator" : ""}
                    >
                      <IconButton sx={{ padding: "0" }}
                        disabled={!componentAccessMap?.entries?.candidateInfo}
                        onClick={(e) =>{
                          if (!componentAccessMap?.entries?.candidateInfo) return;
                          setSelectedEntryId(entry?._id)
                          handleMoreClick(e, entry?.identifier)
                          }}
                      >
                        <MoreVert />
                      </IconButton>
                    </PatchedTooltip> 
                  </Grid>
                ),

              };
            }),
          [entries?.list, handleMoreClick]
        )}
        totalCount={entries?.total ?? 0}
        columns={[
          { name: "Name", sort: 'name' },
          { name: "Identifier", sort: 'identifier' },
          { name: "Type" },
          // { name: "Host Name" },
          // { name: "Host Address" },
          { name: "Zone Name" },
          { name: "Purpose" },
          { name: "Arrival", sort: 'inTime', defaultOrder:-1 },
          { name: "Departure", sort: 'outTime' },
          { name: "Actions" }
        ]}
        handleRowClick={(row) => {
          // setSelectedCandidateInfo(row);
          // setOpenCandidateDetailsDrawer(true);
        }}
      // dontLoadWhen={[openCandidateDetailsDrawer]}
      // hidePagination
      // showMoreHandler={
      //   credentials?.total < 5 ?
      //     null :
      //     () => {
      //       let route = `/batches/${selectedBatchId}`;
      //       if (selectedBatchId === "singleIssuance") {
      //         route = "/issuanceHistory?singleIssuancesOnly=true";
      //       }
      //       history.push(route);
      //     }
      // }
      />

      {anchorEl && <VisitorCard
        entryId={selectedEntryId}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleMoreClose} /> }

      <CustomDialog
        fullWidth
        maxWidth="xs"
        title="Manual Sign off"
        open={Boolean(openManualSignOffDialog?._id)}
        setOpen={setOpenManualSignOffDialog}
      >
        <ManualSignOff entry={openManualSignOffDialog}
          close={() => { setOpenManualSignOffDialog(false) }} fetchData={fetchData} page={page}/>
      </CustomDialog>
    </Box>
  );
}
