import API from "../api";
import { baseURLs } from "../Axios";
import axios from "axios";
import { getAccessToken } from "../Utility/tokenUtils";
import { SET_ACCOUNT_DATA } from "../Containers/Auth/store/actionTypes";

export const fetchOrgData = () => {
  return async (dispatch) => {
    try
    {
      const response = await axios
        .get(
          `${baseURLs.userManagement}${API.userManagement.profile}`,
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        );

      if (response?.data?.code === 0)
      {
        const data = response?.data?.data
        dispatch({
          type: SET_ACCOUNT_DATA,
          name: data?.name ?? "-",
          orgName: data?.organization?.name ?? "-",
          id: data?.organization?._id ?? "-",
          logo: data?.organization?.imageUrl ?? "-"
        })
      }
    }
    catch (err)
    {
      window.snackbar("error", err.message);
    }
  }
};