import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { fetchNotifications } from "../../Containers/Dashboard/notifications/store/actions";

const useInfiniteScroll = ({ action, searchParamName, initialSearchText, category, otherActionParams = {}, countPerFetch = 10,
  isAction = true, totalKey="total", parentComponentLoading }) => {
  
  const scrollableDivRef = useRef(null);
  const didMount = useRef(false)
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.alert.loading); // for loading when searching for something or when there are no results

  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [paginationSeed, setPaginationSeed] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [loadOnScroll, setLoadOnScroll] = useState(false); // only for loading when scrolled to the bottom of currently fetched results

  const reset = () => {
    setItems([]);
    setPaginationSeed(0);
    setTotalItemCount(0);
    if (loadOnScroll) setLoadOnScroll(false);
    scrollableDivRef?.current?.scrollTo(0, 0);
    // didMount.current=false
  }
  const handleFetchItems = async (shouldSearch = true, currentScrollHeight) => {
    let pageNum = 0;
    if (currentScrollHeight) {
      // case of scrolling past bottom
      setLoadOnScroll(true);
      if (paginationSeed + countPerFetch >= totalItemCount && totalItemCount !== 0) pageNum = paginationSeed;
      else pageNum = paginationSeed + countPerFetch;
    }
    console.info(pageNum, "pnum", totalItemCount, "total", paginationSeed, "pseed", )
    const params = { seed: pageNum, count: countPerFetch, doNotDispatch: true, ...otherActionParams };
    if (searchText.length >= 2 && shouldSearch) params[searchParamName] = searchText;
    if (category) params["category"] = category;

    const fetched = () => isAction ? dispatch(action(params)) : action(params);

    fetched().then((res) => {
      if (loadOnScroll) setLoadOnScroll(false);
      // console.log("res", res);
      if (res?.data?.list) {
        if (!currentScrollHeight) return setItems(res?.data?.list);
        if (totalItemCount === 0) setTotalItemCount(res.data[totalKey]);
        scrollableDivRef?.current?.scrollTo(0, currentScrollHeight);
        if (res?.data?.list?.length > 0) {
          setItems([...items, ...res?.data?.list]);
          setPaginationSeed(pageNum);
        }
      }
    });
  };

  const handleScrollPastBottom = () => {
    const newHeight = scrollableDivRef?.current?.scrollTop + scrollableDivRef?.current?.clientHeight;
    const oldHeight = scrollableDivRef?.current?.scrollHeight;
    if (newHeight && oldHeight && ((newHeight >= oldHeight || (oldHeight - newHeight <= 0.5)))) {
      const currentScrollHeight = newHeight;
      if (totalItemCount !== 0 && ((paginationSeed + countPerFetch) >= totalItemCount)) { } else {
        handleFetchItems(true, currentScrollHeight);
      }
    }
  };

  useEffect(() => {

    if((parentComponentLoading === false || parentComponentLoading === undefined) && paginationSeed === 0) {
          if (initialSearchText) setSearchText(initialSearchText);
          else if (items?.length < countPerFetch || !items) {
            // dispatch(action({page: 0, itemOnPage: countPerFetch, doNotDispatch: true, ...otherActionParams})).then((res) => {
            //   setItems(res?.data?.list);
            // });
            handleFetchItems();
          }
    }
  }, [parentComponentLoading, totalKey]);


  useEffect(() => {
    if (searchText.length >= 1) debouncedSearch();
  }, [searchText]);

  useEffect(() => {
    if (category !== undefined && category !== "") handleFetchItems();
  }, [category]);

  // const resetInfiniteScroll = () => {
  //   setTotalItemCount(0);
  //   setPaginationSeed(0);
  //   if (loadOnScroll) setLoadOnScroll(false);
  // };

  const handleChangeSearchText = (e) => {
    if (e.target.value.length === 1 && searchText.length === 0 && paginationSeed > 0) reset();
    if (e.target.value.length === 0 && searchText.length > 0) {
      reset();
      handleFetchItems(false);
    }
    setSearchText(e.target.value);
  };

  const debouncedSearch = useDebouncedCallback(
    (params) => {
      // if (searchText.length >= 2) {
      handleFetchItems();
      // };
    },
    700
  );

  return { scrollableDivRef, handleChangeSearchText, searchText, items, handleFetchItems, loadOnScroll, handleScrollPastBottom, reset };
};

export default useInfiniteScroll;
