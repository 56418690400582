import { componentToRolesMap } from "../../../Config/roleBasedAccess/componentLevel"

const tableColumns = ["Feature", "Section", "Action"]

const allPermissionsInfo = {
    manageZone: {
        title: "Manage Zone",
        description: "Enable, Disable, Edit Zone, its Checkpoints and the Devices attached",
        tags: ["Zones", "Device Management"],
        permissions: [
            ...componentToRolesMap.zones.editActions,
            ...componentToRolesMap.zones.addOrRemoveDevice
        ],
        dependees: ["addDeleteZone", "blacklistStudent"],
        info: "'Device Operations' will also be enabled on enabling this.",
        willEnable: ["deviceOperations"]
    },
    addDeleteZone: {
        title: "Zone Creation and Deletion",
        description: "Create and Delete Zone",
        tags: ["Zones"],
        permissions: [
            ...componentToRolesMap.zones.addNew,
            ...componentToRolesMap.zones.delete
        ],
        paddingLeft: "3.75rem",
        dependsOn: "manageZone"
    },
    blacklistStudent: {
        title: "Blacklist Student",
        description: "Blacklist a student from a zone",
        tags: ["Zones"],
        permissions: [
            ...componentToRolesMap.zones.blacklist
        ],
        paddingLeft: "3.75rem", 
        dependsOn: ["manageZone", "viewStudentData"],
        info: "'Manage Zone' and 'View Student Data' need to be enabled."
    },
    viewEntries: {
        title: "View Entries",
        description: "View Entries Page",
        tags: ["Entries"] ,
        permissions: [
            "access-view_entry"
        ],
        dependees: ["manageEntries", "viewStudentData", "exportStudentData"]
    },
    manageEntries: {
        title: "Manage Entries",
        description: "Manually Sign off Entries",
        tags: ["Entries"],
        paddingLeft: "3.75rem" ,
        permissions: [
            ...componentToRolesMap.entries.manualSignOff
        ],
        dependsOn: "viewEntries"
    },
    viewStudentData: {
        title: "View Student Data",
        description: "View Student Data from Database",
        tags: ["Entries"],
        paddingLeft: "3.75rem",
        permissions: [
            ...componentToRolesMap.entries.candidateInfo
        ],
        dependsOn: "viewEntries",
        dependees: ["blacklistStudent", "exportStudentData"]
    },
    exportStudentData: {
        title: "Export Student Data",
        description: "Export all student data including Data from Database",
        tags: ["Entries"],
        paddingLeft: "5.5rem",
        permissions: [
            ...componentToRolesMap.entries.export
        ],
        dependsOn: ["viewEntries", "viewStudentData"] 
    },
    deviceOperations: {
        title: "Device Operations",
        description: "Assign, Unassign, Archive, Change Password, and Send Message",
        tags: ["Dashboard", "Device Management", "Zones"],
        permissions: [
            ...componentToRolesMap.devices.assign,
            ...componentToRolesMap.devices.editActions
        ],
        dependees: ["addDevice", "manageZone"]
    },
    addDevice: {
        title: "Add a Device",
        description: "Connect New Device to Portal",
        tags: ["Device Management"],
        paddingLeft: "3.75rem",
        permissions: [
            ...componentToRolesMap.devices.addNew
        ],
        dependsOn: "deviceOperations"
    },
    configureVisitLists: {
        title: "Configure visit related lists",
        description: "View, create, edit, and delete - visitor types, purposes of visit, and manual sign off reasons",
        tags: ["General Settings"],
        permissions: [
            ...componentToRolesMap.profile.addVisitsConfig,
            ...componentToRolesMap.profile.viewVisitsConfig,
            ...componentToRolesMap.profile.editVisitsConfig,
            ...componentToRolesMap.profile.deleteVisitsConfig,
        ]
    },
    viewStats: {
        title: "View Statistics",
        description: "View Zones, Entries and Device statistics",
        tags: ["Dashboard"],
        permissions: [
            ...componentToRolesMap.dashboard.zoneStats,
            ...componentToRolesMap.dashboard.entriesStats,
            ...componentToRolesMap.dashboard.devicesStats,
        ]
    }
}

export {tableColumns, allPermissionsInfo}