import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography,TextField } from "@mui/material";
import PillButton from "../../../Components/Buttons/PillButton";
import StandardTextfield from "../../../Components/Input/TextField/index";
import DropdownTextfield from "../../../Components/Input/Dropdown/index-new";
// import authActions from "../store/actions";
import { deleteFromErrors } from "../../../Helpers/helpers";
import { isValidEmail } from "../../../Utility/validations";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ContactUs({setPage}) {
  console.info('here')
  const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.alert.loading);
  const isLoading = false;
  const [errors, setErrors] = useState({});
  const [contactUsInfo, setLoginInfo] = useState({ name: "", workEmail: "", password: "", organisationName: "", });
  const { name, workEmail, password, organisationName} = contactUsInfo;
  const [visibility, setVisibility] = useState(false);

  const handleChange = (name, value) => {
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };

  const handleSubmit = () => {
    // const tempErrors = { ...errors };
    // Object.keys(contactUsInfo).forEach((field) => {
    //   if (contactUsInfo[field] === "") tempErrors[field] = "required";
    // });
    // if (workEmail.length > 0 && isValidEmail(workEmail) !== "") tempErrors.workEmail = "Email is invalid";
    // if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    // dispatch(authActions.sendResetPasswordLink(workEmail));
  };

  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) handleSubmit();
  };

  return (
    <>
      <Typography variant="h3" sx={{ textAlign: "center" }}>Get in touch with us</Typography>
      {/* <Typography variant="caption" sx={{ color: "rgb(112, 112, 112)", textAlign: "center" }}>
        Enter your details to Sign Up with us
      </Typography> */}
      <StandardTextfield
        label="Name"
        value={name}
        onChange={(e) => handleChange("name", e.target.value)}
        errorBool={errors?.name?.length > 0}
        helperText={errors?.name}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
      />
      <StandardTextfield
        label="Email"
        value={workEmail}
        onChange={(e) => handleChange("workEmail", e.target.value)}
        errorBool={errors?.workEmail?.length > 0}
        helperText={errors?.workEmail}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
      />
      {/* <StandardTextfield
        label="Set Password"
        value={password}
        onChange={(e) => handleChange("password", e.target.value)}
        sx={{ width: "100%", marginBottom: "4rem" }}
        endIcon={visibility ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
        endIconOnClick={() => setVisibility((prev) => !prev)}
        errorBool={errors?.password?.length > 0}
        type={visibility ? "text" : "password"}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
      /> */}
      <StandardTextfield
        label="Organisation Name"
        value={organisationName}
        onChange={(e) => handleChange("organisationName", e.target.value)}
        errorBool={errors?.organisationName?.length > 0}
        helperText={errors?.organisationName}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
      />
      {/* <TextField
        label="Query"
        multiline={true}
        rows={4}
      /> */}
      {/* <DropdownTextfield
        label="Organisation type"
        size="medium"
        value={""}
        // optionsArray={Object.keys(authTypes)}
        optionsArray={["org1", "org2"]}
        // onChange={(e) => handleChange(e.target.value, "authenticationType", setter, stateName)}
        // errorBool={errors?.[stateName]?.authenticationType?.length > 0}
      /> */}
      <StandardTextfield
        label="Query"
        // value={organisationName}
        // onChange={(e) => handleChange("organisationName", e.target.value)}
        errorBool={errors?.organisationName?.length > 0}
        helperText={errors?.organisationName}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
        multiline={true}
        rows={3}
      />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <PillButton
          onClick={handleSubmit}
          text={isLoading ?
            <CircularProgress size="2rem" sx={{ color: "white" }} /> :
            "Submit"
          } padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="caption" sx={{width: "fit-content", mt: 1 }} >Already have an Account ? 
          <span style={{ cursor: "pointer", color: '#013366' }} onClick={() => setPage(1)}> Login </span>
        </Typography>
      </Box>
    </>
  );
}

export default ContactUs;
