import * as actionTypes from "./actionTypes";

const initialState = {
  devices: null,
  totalCount: 0,
  isDevicePaired: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.FETCH_DEVICES:
    return fetchDevices(state, action);
  case actionTypes.SET_DEVICE_PAIRED_STATUS: 
    return setDevicePairedStatus(state, action)   
  default:
    return state;
  }
};

const fetchDevices = (state, action) => {
  return {
    ...state,
    devices: [...action.data],
    totalCount: action.total,
  };
};

const setDevicePairedStatus = (state, action) => {
  return {
    ...state,
    isDevicePaired: action.data
  }
}

