import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import PillButton from '../../Buttons/PillButton'
import CloseIcon from "@mui/icons-material/Close";
import styles from './styles'
import AutocompleteField from '../../Input/Autocomplete';
import zoneActions from '../../../Containers/Zones/Store/actions';
import deviceActions from '../../../Containers/Device/store/actions'
import { deleteFromErrors } from '../../../Helpers/helpers';
import { useSnackbar } from 'notistack';

function AssignDeviceModal({open, setOpen, device, selectedFilter}) {

    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const zones = useSelector(s => s.zones.zonesData)
    const zoneInDetail = useSelector(s => s.zones.zone)

    const [checkpoints, setCheckpoints] = useState([])
    const [errors, setErrors] = useState({})
    const [zone, setZone] = useState(null)
    const [zoneSearchTerm, setZoneSearchTerm] = useState('')
    const [checkpoint, setCheckpoint] = useState(null)
    const [checkpointSearchTerm, setCheckpointSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState({
      checkpoint: false,
      zone: false,
      submit: false
    })
    useEffect(()=>{
      dispatch(zoneActions.fetchZones({active: true}))
    },[dispatch])

    const handleClose = () => setOpen(false)

    const handleChange = (name, val, setter) => {
      if(name === "zone") {
        if(!val) setCheckpoints([])
        setCheckpoint(null)
      }
      setter(val)
      if(errors[name]) deleteFromErrors(errors, setErrors, name)
    }

    const handleSubmit = () => {    
      if(!zone) return setErrors({zone: "required"})
      if(!checkpoint) return setErrors({checkpoint: "required"})
      const reqData={
        checkpoint: checkpoint._id,
        // zone: zone._id,
        device: device._id
      }
    setIsLoading(prev => ({...prev, submit: true}))
    dispatch(deviceActions.updateAssignedStatus(true, reqData)).then((res)=>{
      if(res === "success"){
        setOpen(false)
        dispatch(deviceActions.generateDevicePin(device.deviceId)).then((response)=>{
          if(response.status === "success"){
            enqueueSnackbar("Device assigned!", {
              variant: "success"
            })
            dispatch(deviceActions.fetchDevices({seed:0, count: 15, status: selectedFilter}))
            
          }
        })
      }
      setIsLoading(prev => ({...prev, submit: false}))
    })
    }

    const fetchZone = (zone) => {
      if(!zone) return
      setIsLoading(prev => ({...prev, checkpoint: true}))
      dispatch(zoneActions.fetchZoneById(zone._id)).then(()=>{
        setIsLoading(prev => ({...prev, checkpoint: false}))
      })
    }

    useEffect(()=>{
      if(zoneInDetail){
        setCheckpoints(zoneInDetail.checkpoints)
      }
    },[zoneInDetail])

  return (
    <Dialog
    open={open}
    onClose={handleClose}
  >
    <Box sx={styles.topRow}>
    <Typography variant="h4" color="text.heading">{"Assign Device"}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
        </IconButton>
    </Box>
    <DialogContent sx={{...styles.dialogContent}}>
       {isLoading.submit  ?  
         <div style={styles.loaderBox}>
            <CircularProgress />
         </div> : 
         <div style={styles.midBox}>
         <div style={styles.deviceIdBox}>
         <Typography 
          variant="subtitle1"
          sx={{color: "primary.main", fontWeight: 400}}
         >
            Device Id
         </Typography>
         <Typography
          variant="h4"
          >
          {device.deviceId}
          </Typography>
         </div>
         <AutocompleteField
                options={ zones ?? [] }
                name={"Zone"}
                label={"Zone"}
                placeholder={"Select zone"}
                variant="outlined"
                selectedValue={zone}
                fieldValue={zoneSearchTerm}
                sx={{marginTop: "1rem", width: "100%"}}
                heightDropdown={"17rem"}
                shrinkLabel
                // filterOptions={(x) => x}
                // isLoading={isLoading.search}
                // filterSelectedOptions
                size={"large"}
                getOptionLabel={(option) => `${option?.name}: ${option?.description}`}
                onValueChange={(e, newValue) => {
                  fetchZone(newValue)
                  handleChange("zone", newValue, setZone)
                }}
                onFieldInputChange={(e, newValue)=> {
                  // if (searchTerm?.length > 0 && !newValue) {
                  //   getVerificationTemplates({searchTerm: searchTerm, setIsLoading, dispatch, skipSearchTerm: true});
                  // }
                  setZoneSearchTerm(newValue);
                }}
                errorBool={errors?.zone}
                // helperText={errors?.verificationTemplate}
                // hasDebouncedSearch={true}
                // fieldValueCompareText={`${verificationTemplate?.name} (${verificationTemplate?.version})`}
                // getData={getVerificationTemplates}
                // getDataParams={{searchTerm: searchTerm, setIsLoading, dispatch}}
              />
                  <AutocompleteField
                options={ checkpoints ?? [] }
                name={"checkpoint"}
                label={"Checkpoint"}
                placeholder={"Select checkpoint"}
                variant="outlined"
                selectedValue={checkpoint}
                isLoading={isLoading.checkpoint}
                fieldValue={checkpointSearchTerm}
                sx={{marginTop: "1rem", width: "100%"}}
                heightDropdown={"10rem"}
                shrinkLabel
                size={"large"}
                getOptionLabel={(option) => `${option?.name}${option?.description ? `: ${option.description}` : ""}`}
                onValueChange={(e, newValue) => {
                  handleChange("checkpoint", newValue, setCheckpoint)
                }}
                onFieldInputChange={(e, newValue)=> {
                  setCheckpointSearchTerm(newValue);
                }}
                errorBool={errors?.checkpoint}
                disabled={!zone}
              />     
         <div style={styles.buttons}>
            <PillButton 
                text="Cancel"
                border="none"
                invertedColor
                onClick={handleClose}
            />
            <PillButton 
                text="Assign Device"
                onClick={handleSubmit}
            />
         </div>
       </div>
       }
    </DialogContent>
  </Dialog>
  )
}

export default AssignDeviceModal