import Dashboard from "../../Containers/Dashboard"
import DeviceManagement from "../../Containers/Device"
import EditProfile from "../../Containers/EditProfile"
import Entries from "../../Containers/Entries"
import UserManagement from "../../Containers/UserManagement"
import Zones from "../../Containers/Zones"
import AddZone from "../../Containers/Zones/zoneInfo"

const userLevelToRoutesMap = {
    "user": [
        {
            path: "/dashboard",
            component: Dashboard,
        },
        {
            path: "/edit-profile",
            component: EditProfile
        },
        {
            path: "/entries",
            component: Entries
        },
        {
            path: "/zones",
            component: Zones,
            exact: true,
        },
        {
            path: "/zones/add-zone",
            component: AddZone
        },
        {
            path: "/zones/edit-zone",
            component: AddZone
        },
        {
            path: "/devices",
            component: DeviceManagement
        },
        {
            path: "/user-management",
            component: UserManagement
        }
    ],
    "superAdmin": []
}

const routeToRolesMap = {
    "/zones": [
        "access-create_zone",
        "access-edit_zone",
        "access-view_zone",
        "access-delete_zone"
    ],
    "/zones/add-zone": [
        "access-create_zone",
    ],
    "/zones/edit-zone": [
        "access-create_zone",
        "access-edit_zone",
        "access-delete_zone"
    ],
    "/devices": [
        "access-create_device",
        "access-edit_device",
        "access-view_device"
    ],
    "/entries": [
        "access-create-entry",
        "access-edit_entry",
        "access-view_entry"
    ],
    "/user-management": [
        "roles-view",
        "identities-view",
    ]
}

export {userLevelToRoutesMap, routeToRolesMap};