import React from 'react'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'
import { Button, Card, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

function InfoSnackbar({snackbarKey, message, buttonText, link}) {
    const history = useHistory() 
    const {closeSnackbar} = useSnackbar()
  return (
    <Card sx={{display: "flex", padding: "1.5rem", alignItems: "center", columnGap:"1rem"}}>
        <InfoOutlined/>
        <div style={{display: "flex", columnGap: "0.1rem", alignItems: "center"}}>
            <Typography 
                variant="h4"
                sx={{
                    fontWeight:"400"
                }}
                >
                {message ?? ""}
            </Typography>
            <Button
                sx={{
                typography:"h4",
                fontWeight:"400",
                color: "primary"
                }}
                //  href={"/entries"}
                onClick={()=>{
                closeSnackbar(snackbarKey)
                if (link) history.push(link)
                }}
                >
                {buttonText ?? "Click here to view."}
            </Button>
        </div>
    </Card>
  )
}

export default InfoSnackbar