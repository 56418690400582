import {useState, useEffect, useCallback, useMemo, useRef, Fragment, memo} from 'react'
import { Box, Divider, Typography } from '@mui/material'
import DropdownTextfield from '../../../Components/Input/Dropdown/index-new'
import zoneActions from '../../Zones/Store/actions'
import styles from '../styles'
import Treemap from '../../../Components/Charts/Treemap'
import LineChart from '../../../Components/Charts/Line'
import { getLineGraphData, durationValues, durationOptions, statsLabels, reverseMappingDurationOptions } from './handlers'
import { useDispatch, useSelector } from 'react-redux'
import {UPDATE_DURATION} from '../../Zones/Store/actionTypes'
import moment from 'moment'
import PatchedTooltip from '../../../Components/PatchedTooltip'

function ZonesStats() {
    const dispatch = useDispatch()
    const {componentAccessMap} = useSelector(s => s.auth);
    const prevDuration = useSelector(state => state.zones.duration)
    const [duration, setDuration] = useState("Weekly")
    const [zones, setZones] = useState([])
    const [selectedZone, setSelectedZone] = useState({})
    const interval = useRef({
        from: new Date().getTime() - (1000*60*60), 
        to: new Date().getTime()
    })
    const [entriesData, setEntriesData] = useState({})

    const updateLineCharts = useCallback(async (zoneId) => {
       const checkpointsData = await getLineGraphData({graphType: "checkpoint", dataKey: "checkpoint", interval, duration, zoneId})
       const visitorsData = await getLineGraphData({graphType: "visitors", dataKey: "visitorType", interval, duration, zoneId})
       setEntriesData(prev => ({...prev, checkpoints: checkpointsData, visitors: visitorsData}))

    }, [duration])

    const fetchZones = useCallback(async () => {
        const currentTime = new Date().getTime();
        const prevTime = currentTime - durationValues[duration].prevTimeDuration
        const params = {
            stats:true,
            from: prevTime, 
            to: currentTime, 
            doNotDispatch: true 
        }
        interval.current.from = duration === "Hourly" ? currentTime - (1000*60*60*12) : prevTime
         //in case of weekly, backend is rounding off the 'from date' on the basis of the last iso week
        if (duration === "Weekly"){
            interval.current.from = moment(interval.current.from).subtract(moment(interval.current.from).isoWeekday() - 1, "days").startOf("day")
        }
        interval.current.to = currentTime
        const res = await zoneActions.fetchZones(params)()
        if(res?.length > 0) {
            const tempZones = res.map(record => {
                const {name, description, totalEntries, signedIn, signedOff, manualSignOff, _id} = record 
                return {
                    name, description, 
                    totalEntries : totalEntries, signedIn, signedOff, manualSignOff, _id
                }
            })
            //zones with atleast 1 entry
            const filteredZones = tempZones.filter(zone => zone.totalEntries > 0) 
            if(filteredZones.length === 0) {
                setZones(filteredZones)
                return setEntriesData({})
            }
            setZones(filteredZones)
            let zoneWithMostEntries = filteredZones[0];
            for (let i = 0; i < filteredZones.length; ++i) {
              if (filteredZones[i].totalEntries > zoneWithMostEntries.totalEntries) {
                zoneWithMostEntries = filteredZones[i];
              }
            }
            setSelectedZone(zoneWithMostEntries)
            if(componentAccessMap?.dashboard?.entriesStats) {
                updateLineCharts(zoneWithMostEntries._id)
            }
    }
    },[duration, updateLineCharts, componentAccessMap])

    useEffect(()=>{
        if(componentAccessMap?.dashboard?.zoneStats) {
            fetchZones()
        }
    },[fetchZones, componentAccessMap])

    const formattedZones = useMemo(()=>{
        return zones.map(zone => ({name: zone.name, "Total visitors": zone.totalEntries, _id: zone._id}))
    },[zones])

    const onZoneClick = useCallback((e, array)=>{
        if(array[0]){
            const id = (array[0]?.element?.["$context"]?.raw?.["_data"]?.children[0]?._id)
            const foundZone = zones.find(zn => zn._id === id) 
            setSelectedZone(foundZone)
            updateLineCharts(foundZone._id)
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[zones])

    const handleUpdateDuration = (e) => {
        const durationOption = durationOptions[e.target.value]
        console.info(durationOption,"durationOption")
        setDuration(durationOption)
        dispatch({
            type: UPDATE_DURATION,
            data: durationOption
        })
    }    

    // console.info(entriesData?.visitors?.[0]?.labels, entriesData?.visitors )

  return (
   <Box>
    <Box sx={{...styles.zoneStatsBox, borderRadius:".8rem .8rem 0 0"}}>
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
         <Typography variant="h3">Total Visits</Typography>
         <PatchedTooltip
           title={!componentAccessMap?.dashboard?.zoneStats ? "You don't have permission to use this. Contact Administrator." : ""}
           style={{width: "30%"}}
         >
            <DropdownTextfield 
                label={'Frequency'}
                value={reverseMappingDurationOptions[duration]}
                optionsArray={Object.keys(durationOptions)}
                onChange={handleUpdateDuration}
                disabled={!componentAccessMap?.dashboard?.zoneStats}
                sx={{width: "100%"}}
            />
         </PatchedTooltip>
    </div>
    {formattedZones.length > 0 ?
     <Fragment>
     <Treemap 
     tree={formattedZones ?? []} 
     keys={{labelKey: "name", valueKey: "Total visitors"}}
     handlers={{ onClick: onZoneClick }}
    />
    <div style={styles.zoneInfo}>
        <div style={styles.dataColumn}>
            <Typography variant="h3">{selectedZone?.name ?? "-"}</Typography>
            <Typography variant="subtitle2" sx={{fontWeight: "400"}}>{selectedZone?.description ?? "-"}</Typography>
        </div>
        <div style={styles.zoneStats}>
            {Object.keys(statsLabels).map(key => 
             <div style={styles.dataColumn} key={key}>
                <Typography variant="caption">{statsLabels[key]}</Typography>
                <Typography variant="h3" color="primary">{selectedZone?.[key] ?? "-"}</Typography>
             </div>
            )}
        </div>
    </div>
     </Fragment> : 
     <Typography variant="h4">No data</Typography>
    }
  </Box>
  {   
    <Box sx={{...styles.zoneStatsBox, borderRadius: "0 0 .8rem .8rem", borderTop: 0, display: "flex", flexFlow: "column", rowGap: "1.5rem"}}>
        <LineChart
        labels = {entriesData?.checkpoints?.[0]?.labels ?? []}
        datasets = {entriesData?.checkpoints ?? []}
        axisLabels={{x: `Past few ${durationValues[duration].label}s`, y: "Entries"}}
        chartTitle={"Entries at Checkpoints"}
        />
        <LineChart
        labels = {entriesData?.visitors?.[0]?.labels ?? []}
        datasets = {entriesData?.visitors ?? []}
        axisLabels={{x: `Past few ${durationValues[duration].label}s`, y: "Entries"}}
        chartTitle={"Entries by Visitor types"}
        /> 
    </Box>
  }
   </Box>
  )
}

export default memo(ZonesStats)