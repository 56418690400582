import React from "react";
import { Box, IconButton, MenuItem, Pagination, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRightOutlined";

// mode: 'standard', 'tabular'
function PaginationBottomRow({page, totalCount, rowsPerPage, handleChangeRows, rowCounts, handlePageChange, justifyContent, mode = "standard", setRowsPerPage, setPage, pagesList, noMarginTop = false, size = "normal"}) {
  const theme = useTheme();
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down("md"));
  return mode === "tabular" ?
    <Box
    sx={{...styles.pagination.wrapper, mt: noMarginTop ? 0 : "1.25rem", height: size === "small" ? "fit-content" : "4rem"}}>
      <Box sx={{display: "flex", alignItems: "center", columnGap: "0.5rem"}}>
        <Typography variant="caption">Rows per Page</Typography>
        <Select
          value={rowsPerPage}
          onChange={(e) => {
            setRowsPerPage(e.target.value);
            setPage(1);
          }}
          size="small"
          sx={styles.pagination.select}
        >
          {
            rowCounts.map((rowCount) =>
              <MenuItem key={rowCount} value={rowCount} sx={{typography: "caption"}}>{rowCount}</MenuItem>
            )
          }
        </Select>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", columnGap: "0.5rem"}}>
        <Typography variant="caption">Page</Typography>
        <Select
          value={page}
          onChange={(e) => setPage(e.target.value)}
          size="small"
          sx={styles.pagination.select}
        >
          {
            pagesList.map((pageNum) =>
              <MenuItem key={pageNum} value={pageNum} sx={{typography: "caption"}}>{pageNum}</MenuItem>
            )
          }
        </Select>
        <Typography variant="caption">of</Typography>
        <Typography variant="caption">{totalCount}</Typography>
      </Box>
      <Box sx={{display: "flex", columnGap: "1.25rem"}}>
        <IconButton color="primary"
          sx={styles.pagination.iconButton}
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          <ChevronLeftIcon sx={styles.pagination.icon}/>
        </IconButton>
        <IconButton color="primary"
          sx={styles.pagination.iconButton}
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalCount}
        >
          <ChevronRightIcon sx={styles.pagination.icon}/>
        </IconButton>
      </Box>
    </Box> :
    (
      <div>
        {totalCount !== 0 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: justifyContent?justifyContent:"space-between",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: ".5rem" }}
            >
              <Typography variant="subtitle1">Rows per Page</Typography>
              <TextField
                select
                value={rowsPerPage}
                onChange={handleChangeRows}
                size="small"
                variant="outlined"
                InputProps={{
                  style: {
                    fontSize: "1.2rem",
                    borderRadius: "2rem",
                  },
                }}
              >
                {rowCounts.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Typography variant="subtitle1">{option}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box mt={.5}>
              <Pagination
                color="primary"
                boundaryCount={smallerThanMDScreen ? 1 : 3}
                count={Math.ceil(totalCount / rowsPerPage)}
                page={page}
                size="small"
                onChange={(event, value) => handlePageChange(value)}
              />
            </Box>
          </Box>
        )}
      </div>
    );
}

export default PaginationBottomRow;
