export default {
    outerBox: {
        backgroundColor: "background.first",
        height: "100%",
        border: ".1rem solid #DADADA",
        borderRadius: ".8rem",
        overflowY: "auto",
        display: "flex",
        flexFlow: "column",
    },
    topRow: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        padding: {
            xs: "1.3rem 3.5rem",
            xl: "2rem 3.5rem"
        },
    }
};