export default {
  outerBox: {
    padding: "1.1rem 3.5rem",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  topRowBox: {
    display: "flex",
    marginBottom: "2rem",
    marginTop: ".8rem",
    justifyContent: "space-between"
  },
};
