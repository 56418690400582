export default (theme) => ({
  "main": {
    // "marginLeft": "16.5rem",
    // "@media (max-width:1500px)": {
    //   marginLeft: "14.4rem",
    // },
    // "height": "90%",
    // "caretColor": theme.palette.primary.main,
  },
  "@global": {
    ".fullHeight": {
      height: "100%",
    },
    ".mb": {
      marginBottom: theme.spacing(1),
    },
    ".mt": {
      marginTop: theme.spacing(1),
    },
    ".ml": {
      marginLeft: theme.spacing(1),
    },
    ".mr": {
      marginRight: theme.spacing(1),
    },
    "*::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
    "*::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      borderRadius: "4px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.28)",
      borderRadius: "8px"
    },
    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
      border: "0",
    },
  },
});
