const styles = {
    dialogContent: {
        // padding: "1.5rem 2.5rem",
        padding: "1.5rem 0",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2.75rem",
        width: "73rem",
        rowGap: "1.2rem",
        minHeight: "69vh",
        overflowY: "hidden"
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem",
        paddingTop: "1rem",
        paddingLeft: "2.5rem",
        paddingRight: "1.5rem",
        marginBottom: "0.25rem"
      },
      outerBox: {
        border: "1px solid",
       borderColor: "border.main",
        borderRadius: "0.8rem",
       backgroundColor: "#FAFAFA",
       display: "flex",
       flexFlow: "column"
    },
    tagChip: {
     fontSize: "1.035rem",
     m: "0.25rem",
     borderRadius: "0.5rem",
     backgroundColor: "background.second",
     border: "1px solid",
     borderColor: "border.main",
     fontFamily: "Lato",
     padding: "1.05rem 0.25rem"
    }
}

export default styles;