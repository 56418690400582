import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createVisitsConfig, deleteVisitsConfig, getVisitsConfigs, updateVisitsConfig } from '../../Entries/Store/actions';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFromErrors } from '../../../Helpers/helpers';

function useEntryManagement() {
  const {componentAccessMap} = useSelector(s => s.auth);
    const {enqueueSnackbar} = useSnackbar()
    const dispatch = useDispatch();
    const [visitPurposes, setVisitPurposes] = useState({
        persisted: [],
        temporary: []
      });
      const [typeOfVisitors, setTypeOfVisitors] = useState({
        persisted: [],
        temporary: []
      });
      const [manualSignOffReasons, setManualSignOffReasons] = useState({
        persisted: [],
        temporary: []
      });
      const [expanded, setExpanded] = useState({
        visitPurposes: false,
        typeOfVisitors: false,
        manualSignOffReasons: false
      })
      const [isLoading, setIsLoading] = useState({
        visitPurposes: false,
        typeOfVisitors: false,
        manualSignOffReasons: false
      })
      const [showWarningModal, setShowWarningModal] = useState(false)
      const [errors, setErrors] = useState({});
      const toBeDeletedConfig = useRef(null)
  
      const visitsConfigMap = [
          {
            title: "Visitor Type",
            tableColumnTitle: "Visitor Type",
            subtitle: "You can define the type of visitors for your organisation",
            configType: "typeOfVisitor",
            state: typeOfVisitors,
            setter: setTypeOfVisitors,
            key: "typeOfVisitors",
          },
          {
            title: "Purpose of Visit",
            tableColumnTitle: "Purpose of Visit",
            subtitle: "You can define the Purpose of Visit that will be visible to the Verifier",
            configType: "visitPurpose",
            state: visitPurposes,
            setter: setVisitPurposes,
            key: "visitPurposes"
          },
          {
            title: "Reason for Manual Sign Off",
            tableColumnTitle: "Reason",
            subtitle: "You can define the reason for Manual Sign off",
            configType: "reasonOfManualSignOff",
            state: manualSignOffReasons,
            setter: setManualSignOffReasons,
            key: "manualSignOffReasons"
          }
        ]
  
      const fetchVisitsConfigsByType = (configObj) => {
        const {configType, key, setter} = configObj;
        if(configType) {
          setIsLoading(prev => ({...prev, [key]: true}))
          dispatch(getVisitsConfigs(configType)).then(res => {
              setIsLoading(prev => ({...prev, [key]: false}))
              if(res?.list) {
                  setter({
                      persisted: structuredClone(res?.list),
                      temporary: structuredClone(res?.list?.map(row => ({...row, isEdit: false})))
                  })
                  if(Object.keys(errors).length > 0) setErrors({})
              }
          })
        }
      }  
  
      useEffect(()=>{
          const expandedRowKey = Object.keys(expanded).find(key => expanded[key] === true);
          if(expandedRowKey) {
              const record = visitsConfigMap.find(row => row.key === expandedRowKey)
              if (record) {
                fetchVisitsConfigsByType(record)
              }
          }
      },[expanded, dispatch])
      
        const handleToggleAccordion = (toExpandKey) => {
          const allKeys = [
            "visitPurposes",
            "typeOfVisitors",
            "manualSignOffReasons"
          ]
          const filteredKeys = allKeys.filter(row => row !== toExpandKey);
          if(!expanded?.[toExpandKey]){
            setExpanded(prev => ({...prev, [toExpandKey]: !prev[toExpandKey], [filteredKeys[0]]: false, [filteredKeys[1]]: false}))
         
          }
          else setExpanded(prev => ({...prev, [toExpandKey]: !prev[toExpandKey]}))
        }  
  
      const handleAddNew = (configObj) => {
        if (!componentAccessMap?.profile?.addVisitsConfig) return;
        const {configType, state, setter} = configObj;
        const newObj = {
          isEdit: true,
          isNew: true,
          _id: new Date().toISOString(),
          configType,
          texts: [
            {
              langCode: "en",
              text: ""
            },
            {
              langCode: "hi",
              text: ""
            }
          ],
        }
        if (configObj.configType === "visitPurpose") newObj.hostRequired = false
        setter(prev => ({...prev, temporary: [...state.temporary, newObj]}))
      }  
  
    const makeEditable = (configObj, record) => {
      if (!componentAccessMap?.profile?.editVisitsConfig) return;
      const {state, setter} = configObj;
      const temp = [...state.temporary];
      const foundRecordIndex = temp.findIndex(row => row._id === record._id)
      if (foundRecordIndex !== -1) temp[foundRecordIndex].isEdit = true;
      setter(prev => ({...prev, temporary: temp}))
    }
  
    const makeUnEditable = (configObj, record) => {
      const {state, setter} = configObj;
      const temp = [...state.temporary];
      const persistedTemp = [...state.persisted];
      const foundRecordIndex = temp.findIndex(row => row._id === record._id)
      if (foundRecordIndex !== -1) {
        if(temp[foundRecordIndex].isNew){
          temp.splice(foundRecordIndex, 1)
        }
        else temp[foundRecordIndex] = structuredClone(persistedTemp[foundRecordIndex]);
      }
      setter(prev => ({...prev, temporary: temp}))
      if(Object.keys(errors).length > 0) setErrors({})
    }

    const handleChangeHostReqd = (configObj, record, value) => {
      const {state, setter} = configObj;
      const temp = [...state.temporary];
      const foundRecordIndex = temp.findIndex(row => row._id === record._id)
      if (foundRecordIndex !== -1) {
        temp[foundRecordIndex].hostRequired = value;
      }
      setter(prev => ({...prev, temporary: temp}))
    }
  
    const handleChange = (configObj, record, langIndex, langCode, value) => {
      const {state, setter} = configObj;
      const temp = [...state.temporary];
      const foundRecordIndex = temp.findIndex(row => row._id === record._id)
      if (foundRecordIndex !== -1) {
        if(!temp[foundRecordIndex]?.texts?.[langIndex]) {
          const obj = {
            langCode: langCode || "hi",
            text: value,
          }
         temp[foundRecordIndex].texts[langIndex] = obj
        }
        else temp[foundRecordIndex].texts[langIndex].text = value
      }
      if (errors?.[langCode]) deleteFromErrors(errors, setErrors, langCode)
      setter(prev => ({...prev, temporary: temp}))
    }
  
    const handleSubmit = (configObj, record) => {
      const tempErrors = {}
      const texts = record.texts;
      texts.forEach(item => {
        if (item?.text?.length < 3) tempErrors[item.langCode] = "At least 3 letters are needed"
      })
      if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors)
      let reqData = {
        configType: configObj.configType,
        texts: record.texts
      }
      if (configObj.configType === "visitPurpose") {
        reqData.hostRequired = record.hostRequired
      }
      if(record.isNew) return handleCreate(configObj, reqData, configObj.title) //POST
      //PUT
      reqData._id = record._id
      if(record.hostRequired !== undefined) reqData.hostRequired = record.hostRequired
      handleEdit(configObj, record._id, reqData, configObj.title)
    }
  
    const handleCreate = (configObj, reqData) => {
      dispatch(createVisitsConfig(reqData)).then(res => {
        if(res === "success") {
          fetchVisitsConfigsByType(configObj);
          enqueueSnackbar(`${configObj.title} created.`, {
            variant: "success"
          })
        }
      })
    }
  
    const handleEdit = (configObj, id, reqData) => {
      dispatch(updateVisitsConfig(id, reqData)).then(res => {
        if(res === "success") {
          fetchVisitsConfigsByType(configObj);
          enqueueSnackbar(`${configObj.title} updated.`, {
            variant: "success"
          })
        }
      })
    }
  
    const beginDelete = (configObj, record) => {
      if(isAnyRowEditable || !componentAccessMap?.profile?.deleteVisitsConfig) return
      toBeDeletedConfig.current = {
        id: record._id,
        configObj
      }
      setShowWarningModal(true);
    }
  
    const handleDelete = () => {
      dispatch(deleteVisitsConfig(toBeDeletedConfig.current.id)).then(res => {
        setShowWarningModal(false)
        if(res === "success") {
          fetchVisitsConfigsByType(toBeDeletedConfig.current.configObj);
          enqueueSnackbar(`${toBeDeletedConfig.current.configObj.title} deleted.`, {
            variant: "success"
          })
        }
      })
    }
  
      const isAnyRowEditable = useMemo(()=>{
        let result = false;
        Object.keys(expanded).forEach(key => {
          if(expanded[key]) {
            const foundRecordIndex = visitsConfigMap.findIndex(row => row.key === key);
            const {state} = visitsConfigMap[foundRecordIndex];
            const editableRecordIndex = state.temporary.findIndex(row => row.isEdit === true);
            if(editableRecordIndex >= 0) result = true;
          }
        })
        return result;
      },[expanded, visitPurposes, typeOfVisitors, manualSignOffReasons])

    return {
        states: {
            showWarningModal, setShowWarningModal,
            expanded, setExpanded,
            isLoading, setIsLoading,
            errors, setErrors
        },
        functions: {
            handleDelete,
            handleToggleAccordion,
            handleChange,
            handleSubmit,
            makeEditable,
            makeUnEditable,
            beginDelete,
            handleAddNew,
            handleChangeHostReqd
        },
        misc: {
            visitsConfigMap,
            toBeDeletedConfig,
            isAnyRowEditable
        }
    }  
}

export default useEntryManagement