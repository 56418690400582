import React, {useState, useEffect} from 'react'
import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import styles from './styles'
import { useDispatch, } from 'react-redux';
import { useSnackbar } from 'notistack';
import StandardTextfield from '../../Input/TextField';
import PillButton from '../../Buttons/PillButton';
import PermissionsTable from './permissionsTable';
import { allPermissionsInfo } from './info';
import { deleteFromErrors } from '../../../Helpers/helpers';
import { addRole } from '../../../Containers/UserManagement/store/actions';

function AddRoleModal({open, setOpen, permissions, mode = "add", name = "", description = "", getRoles}) {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();    
    const [roleInfo, setRoleInfo] = useState({
        name,
        description
    })
    const [errors, setErrors] = useState({});
    const [allPermissions, setAllPermissions] = useState(structuredClone(permissions ?? allPermissionsInfo));
   
  const fieldsInfo = [
    {
        label: "Role Name",
        name: "name"
    },
    {
        label: "Role Description",
        name: "description"
    }
  ]  

  const handleClose = () => setOpen(false)

  const handleChange = (value, name) => {
    setRoleInfo(prev => ({...prev, [name]: value}));
    if(errors?.[name]) deleteFromErrors(errors, setErrors, name)
  }

  const handleSubmit = () => {
    const tempErrors = {};
    if(roleInfo.name?.length < 3) tempErrors.name = "At least 3 letters are needed."
    if (roleInfo.description?.length < 3) tempErrors.description = "At least 3 letters are needed."
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);

    const permissionsSet = new Set(["access-view_zone", "access-view_device"]);
    Object.keys(allPermissions).forEach(permKey => {
      const permObj = allPermissions[permKey]
      if(permObj.isEnabled) permObj.permissions.forEach(perm => permissionsSet.add(perm))
    })

    const permissions = Array.from(permissionsSet);
    const reqData = {
      name: roleInfo.name,
      description: roleInfo.description,
      permissions
    }
    dispatch(addRole(reqData)).then(res => {
      if (res === "success") {
        getRoles();
        enqueueSnackbar("Role created.", {variant: "success"})
        handleClose()
      }
    })
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth
  >
    <Box sx={styles.topRow}>
    <Typography variant="h3">{mode === "view" ? "View Role" : mode === "edit" ? "Edit Role" : "Create a New Role"}</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
        </IconButton>
    </Box>
    <DialogContent sx={{...styles.dialogContent, paddingTop: mode === "view" ? "0.25rem" : "1.5rem"}}>
    {
      mode !== "view" && 
       <Box sx={{padding: "0 2.5rem"}}>
        <Box sx={{width: "100%", columnGap: "1rem", display: "flex", alignItems: "center"}}>
               {
                fieldsInfo.map(field => 
                  <StandardTextfield 
                    key={field.label}
                    label={field.label}
                    value={roleInfo[field.name]}
                    sx={{width: "50%"}}
                    onChange={(e) => handleChange(e.target.value, field.name)}
                    errorBool={errors?.[field.name]?.length > 0}
                    helperText={errors?.[field.name] ?? "."}
                    showHelperTextAlways
                  />
                )
               }
        </Box>
      </Box>
    }
      <Divider />
      <Box sx={{padding: "0 2.5rem", display: "flex", flexFlow: "column", rowGap: "1rem", overflowY: "hidden"}}>
        <Typography variant="h4">{mode !== "view" ? "Select Permissions" : "Permissions"}</Typography>
        <PermissionsTable 
          states={{allPermissions, setAllPermissions}}
          mode={mode}
        />
      </Box>
    </DialogContent>
    {
      mode !== "view" &&
      <Box sx={{backgroundColor: "#FAFAFA", display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "1.1rem 2.5rem", columnGap: "2rem"}}>
        <Button sx={{typography: "h4"}}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <PillButton 
          text="Save"
          disabled={mode === "edit"}
          onClick={handleSubmit}
        />
      </Box>
    }
  </Dialog>
  )
}

export default  AddRoleModal