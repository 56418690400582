import * as actionTypes from "./actionTypes";

const initialState = {
  entriesData: null,
  visitorData: null,
  rowsPerPage: 20,
  resetPage: false,
  seed: 0,
  filters: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENTRIES_UPDATE_STATE:
      return updateState(state, action);
    case actionTypes.ENTRIES_RESET_STATE:
      return resetState(state, action);
    case actionTypes.RESET_PAGE:
      return resetPage(state, action);
    default:
      return state;
  }
};
export default reducer

const updateState = (state, action) => {
  return {
    ...state,
    ...action.data
  };
};
const resetState = (state, action = { data: {} }) => {
  return { ...initialState, ...action.data };
};

const resetPage = (state, action = { data: {} }) => {
  return { ...state, ...action.data };
};


