import axios from 'axios';
import API from '../api';

export const getAccessToken = () => {
  return localStorage.getItem("token");
};

export const getPermissions = async () => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL + "/api/2.0/identities/info",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  };

  try
  {
    console.info(config, "CONFIG");
    const response = await axios(config);
    console.info(response, "responsePermissions");
    return response?.data?.data?.permissions ?? [];
  } catch (e)
  {
    return e;
  }
};


export const generateLoginToken = async (email, password) => {
  const data = {
    username: email,
    password: password,
  };

  const config = {
    method: "post",
    url: process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL + API.login.login,
    data: data,
  };


  try
  {
    // console.info(config, "CONFIG");
    const response = await axios(config);
    if (response?.data)
    {
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
    }
    refreshAccessTokenAtRegularInterval();
    return response;
  } catch (e)
  {
    return e;
  }
};

export const generateLoginTokenFromRefreshToken = async (refreshToken) => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_USER_MANAGEMENT_SERVER_URL + API.login.token,
    headers: {
      Authorization: `Bearer ${refreshToken}`
    }
  };

  try
  {
    const response = await axios(config);
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("refreshToken", response.data.data.refreshToken);
    return response;
  } catch (e)
  {
    localStorage.removeItem("token");
    localStorage.removeItem("agentToken");
    localStorage.removeItem("role");
    localStorage.removeItem("userLevel");
    localStorage.removeItem("refreshToken");
    return {};
  }
};

window.intervalId = null;
export const refreshAccessTokenAtRegularInterval = async () => {
  try {
    window.intervalId = setInterval(async () => {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        await generateLoginTokenFromRefreshToken(refreshToken);
      }
    }, 60000);
  } catch (e) {
    console.error(e);
  }
}

export const startRefreshAccessTokenAtRegularInterval = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken)
  {
    const res = await generateLoginTokenFromRefreshToken(refreshToken);
    if (res.status === 200)
    {
      refreshAccessTokenAtRegularInterval();
      return true;
    } else if (res === "LOGOUT")
    {
      localStorage.removeItem("token");
      localStorage.removeItem("agentToken");
      localStorage.removeItem("role");
      localStorage.removeItem("userLevel");
      localStorage.removeItem("refreshToken");
      return false;
    }
    return true;
  }
};
