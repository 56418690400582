/* eslint-disable import/no-anonymous-default-export */
export default {
    outer: { 
        padding: "1rem  1.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    leftOuterDiv: {
        display: "flex", 
        alignItems: "center", 
        columnGap: "1.5rem", 
        flex: 1
    },
    deviceOuter: {
        borderRadius: "50%", 
        height: "4rem", 
        width: "4rem",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        paddingTop: "0.65rem",
        position: "relative"
    },
    deviceIdText: {
        position: "absolute", 
        left: "50%",
        top: "60%", 
        transform: "translate(-50%, -50%)", 
        color: "#767676", 
        maxWidth: "2.4rem", 
        overflow: "ellipsis"
   },
   eventTitleParent: {
        display: "flex", 
        columnGap: "0.55rem", 
        alignItems: "center",  
        marginBottom: ".5rem" 
    },
    warningIcon: {
        height: "1.72rem",
        width: "1.72rem",
        color: "error.regular"
    }
}