import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button, IconButton, Collapse, Menu, MenuItem } from "@mui/material";
import styles from "../styles";
import { AntSwitch } from "../../../Buttons/AntSwitch";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../Containers/Zones/Store/actions";
import deviceActions from "../../../../Containers/Device/store/actions"
import MoreInfo from "./moreInfo";
import { useSnackbar } from "notistack";
import { TransitionGroup } from "react-transition-group";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { changeToSentenceCase } from "../../../../Helpers/helpers";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WarningModal from "../../../../Components/WarningModal"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PatchedTooltip from "../../../PatchedTooltip";

function ZonesCard({ data, zoneState, clickHandlers }) {

  // console.info(zoneState,"Zone State")

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const {componentAccessMap} = useSelector(s => s.auth);
  const [moreInfo, setMoreInfo] = useState(false);
  // console.info(data,'DATA')
  const history = useHistory();
  const [checkbox, setcheckbox] = useState(data?.state === 'active' ? true : false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const showMoreActionsMenu = Boolean(menuAnchorEl);

  useEffect(() => {
    setcheckbox(data?.state === 'active')
  }, [data])

  const handleChange = (event) => {
    if (!componentAccessMap?.zones?.editActions) return;
    clickHandlers.beginToggleZoneActivation(data);
  };

  const menuActions = [
    {
      label: "Edit",
      onClick: () => history.push({ pathname: "/zones/edit-zone", state: { page: 'edit', data: data } }),
      accessDenied: !componentAccessMap?.zones?.editActions
    },
    {
      label: "Blacklist",
      onClick: clickHandlers.beginBlacklist,
      accessDenied: !componentAccessMap?.zones?.blacklist
    },
    {
      label: "Delete",
      onClick: clickHandlers.beginDeleteZone,
      disabled: data?.totalEntriesSoFar !== 0,
      accessDenied: !componentAccessMap?.zones?.delete
    }
  ]

  return (
    <Box sx={styles.zones.outer}>
    <Box sx={styles.zones.extraPadding}>
      <div style={{ display: "flex", flexFlow: "column", rowGap: "2rem" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{display: "flex", alignItems: "center", columnGap: "1.25rem"}}>
           <PatchedTooltip
            title={!componentAccessMap?.zones?.editActions ? "You don't have permission to use this. Contact Administrator" : ""}
           >
            <AntSwitch
              checked={checkbox}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              disabled={!componentAccessMap?.zones?.editActions}
            />
           </PatchedTooltip>
            <Typography variant="h6" sx={{ color: "primary.main", fontWeight: '700' }}>
              {data?.name}
            </Typography>
          </Box>
          <IconButton sx={{height: "3rem", width: "3rem"}}
           onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon sx={{height: "2.4rem", width: "2.4rem", color: "text.heading"}}/>
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          <Box mr={1}>
            <Typography variant="subtitle3" sx={styles.fontStyle} >{data?.description}</Typography>
          </Box>
          {data?.accessibility?.time?.from &&
            <>
              {data?.accessibility?.dates?.frequency &&
                <Box sx={{ borderLeft: '1px solid' }}>
                <Typography variant="subtitle3" sx={{ ...styles.fontStyle, margin: '8px 8px' }}>
                    {changeToSentenceCase(data?.accessibility?.dates?.frequency == 'custom' ? 'Custom Dates' : data?.accessibility?.dates?.frequency)}
                  </Typography>
                </Box>}
              <Box sx={{ borderLeft: '1px solid' }}>
              <Typography variant="subtitle3" sx={{ ...styles.fontStyle, margin: '8px 8px' }}>
                  {
                    data?.accessibility?.dates?.frequency === 'custom' ?
                      `${moment(data?.accessibility?.dates?.from).format('DD-MM-YYYY, HH:mm')} to
                        ${moment(data?.accessibility?.dates?.to).format('DD-MM-YYYY, HH:mm')}
                        `
                      :
                      `${(data?.accessibility?.time?.from)} -
                          ${(data?.accessibility?.time?.to)}
                          `
                  }
                </Typography>
              </Box>
            </>
          }
        </Box>
      </div>
      <Box mt={1.5}>
        <Divider />
      </Box>
      {data?.visitPurposes?.length > 0 &&
        <Box mt={1.2}>
        <Typography variant="subtitle3" sx={{ letterSpacing: '0.32em' }}>PURPOSE</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: "0.5rem" }}>
            {data?.visitPurposes?.map((info, index) =>
              <Box sx={{ ...styles.timeBox, padding: '.5rem' }} m={.5} key={index}>
                <Typography variant="subtitle3" sx={styles.fontStyle} >{data?.visitPurposes[index]?.name}</Typography>
              </Box>
            )}
          </Box>
          <Box mt={1.5}>
            <Divider />
          </Box>
        </Box>
      }
      </Box>

      <Button
        style={{
          ...styles.zones.bottom, flexWrap: 'wrap',
          padding: "1.1rem 2rem",
          marginTop: "-1.5rem",
        }}
        onClick={() => setMoreInfo(prev => !prev)}
        disabled={data?.checkpoints?.length === 0}
        >
        <Box sx={{ display: 'flex', alignItems: "center" }}>
          <Box>
            <Typography variant="subtitle3" sx={{ letterSpacing: '0.32em' }}> CHECKPOINTS</Typography>
          </Box>
          <Box sx={{ borderRadius: '14px', backgroundColor: '#013366', width: '2rem', height: '2rem', display:'flex', justifyContent: 'center', alignItems: 'center'}} ml={1}>
            <Box sx={{display: "grid", placeItems: "center"}}>
            <Typography variant="subtitle3" sx={styles.checkpointCount}> {data.checkpoints.length}</Typography>
            </Box>
          </Box>
        </Box>
        {
          moreInfo ?
            <Button sx={{...styles.textButton, color: "inherit", background: "none", "&:hover": {
              background: "none",
            }}}
            disableRipple
            >
              Less Info <ExpandLessIcon />
            </Button>
            :
            <Button sx={{...styles.textButton, color: "inherit", background: "none", "&:hover": {
              background: "none",
            }}}
              disableRipple
              >
              More Info <ExpandMoreIcon />
            </Button>
        }
      </Button>
       <Box>
        <TransitionGroup>
          {moreInfo &&
            data?.checkpoints?.map((info, index) =>
              <Collapse key={index}>
                <MoreInfo 
                info={info} index={index} zoneState={checkbox} total={data?.checkpoints?.length ?? 0} 
                clickHandlers={clickHandlers}
                />
              </Collapse>
            )
          }
        </TransitionGroup>
       </Box>
      <Menu
        aria-labelledby="more-actions-menu"
        anchorEl={menuAnchorEl}
        open={showMoreActionsMenu}
        onClose={()=>{ setMenuAnchorEl(null) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ sx: { py: 0 } }}
    >
      {
        menuActions.map(row => 
        <PatchedTooltip
        title={row.accessDenied ? "You don't have permission to use this. Contact Administrator." : ""}
        >
          <MenuItem
            key={row.label}
            sx={{typography: "h4", borderBottom: "1px solid #DADADA", width: "14rem", pt: "0.8rem", pb: "0.8rem", color: "primary.main"}}
            onClick={() => { 
              if (row?.disabled || row.accessDenied) return;
              row.onClick(data);
              setMenuAnchorEl(null) 
            }}
            disabled={row?.disabled || row.accessDenied}
          >
            {row.label}
          </MenuItem>
        </PatchedTooltip>
        )
      }
      </Menu>
    </Box>
  );
}

export default ZonesCard;
