import React from 'react'
import { Box } from '@mui/material'
export default function Nodata({ size }) {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" justifyContent="center" pb={2} alignItems="center">
      <img style={{ width: size, objectFit: "cover" }}
        src={require("../../Assets/img/nodata.png")}
        alt="no-data" />
    </Box>
  )
}
