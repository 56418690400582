import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013366",
      light: "#577BCC",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {},
    text: {
      regular: "#000000",
      heading: "#444444",
      headingLight: "#666666",
      button: "#fff",
      darkBlue:"#202B67"
    },
    background: {
      first: "#fff",
      second: "#F6F7FF",
      third: "#DEE4F4",
      fourth: "#eee",
      fifth: "#EEF2FB",
      sixth: "#A9D2EF",
      seventh: "#DFF1FF",
    },
    border: {
      main: "#DADADA",
      dark: "#79747e"
    },
    success: {
      main: "#5CC278",
      bright: "#00CD09"
    },
    pending: {
      main: "#F2CB67"
    },
    error: {
      main: "#FF0000",
      regular: "#D73F3F",
      light: "#FFE5E5"
    },
    // warning: {},
    // info: {},
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    htmlFontSize: 10,
  },
  spacing: (factor) => `${factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      bsm: 700,
      lsm: 800,
      md: 900,
      bmd: 1000,
      lmd: 1100,
      lg: 1200,
      blg: 1300,
      sxl: 1400,
      xl: 1536,
    },
  },
  overrides: {
    "MuiTooltip-popper": {
      tooltip: {
        fontSize: "42px",
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: "2.5rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.8rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3.2rem",
  },
};

theme.typography.h2 = {
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.8rem",
    lineHeight: "1.7rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "2rem",
    lineHeight: "2rem"
  },
};

theme.typography.h3 = {
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: "1.3rem",
  lineHeight: "1.25rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.4rem",
    lineHeight: "1.5rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.57rem",
    lineHeight: "2rem"
  },
};

theme.typography.h4 = {
  color: "#444444",
  fontFamily: "Lato",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.24rem",
    lineHeight: "1.5rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.4rem",
    lineHeight: "2rem"
  },
};


theme.typography.subtitle1 = {
  fontFamily: "Lato",
  fontSize: "1rem",
  lineHeight: "1.2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.12rem",
    lineHeight: "1.4rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem"
  },
};

theme.typography.subtitle2 = {
  fontFamily: "Lato",
  fontSize: "1.1rem",
  lineHeight: "1.2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.2rem",
    lineHeight: "1.4rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.3rem",
    lineHeight: "1.8rem"
  },
};

theme.typography.subtitle3 = {
  fontFamily: "Lato",
  fontSize: "1.2rem",
  lineHeight: "1.2rem",
  fontWeight: '400',
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.12rem",
    lineHeight: "1.4rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem"
  },
};

theme.typography.caption = {
  fontFamily: "Lato",
  fontSize: ".9rem",
  lineHeight: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    lineHeight: "1rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.025rem",
    lineHeight: "1rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.1rem",
    lineHeight: "1.1rem"
  },
};

theme.typography.button = {
  textTransform: "none"
};

export default theme;
