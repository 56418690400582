export default {
    outerBox: {
        backgroundColor: "background.first",
        height: "100%",
        border: ".1rem solid #DADADA",
        borderRadius: ".8rem",
        overflowY: "auto",
        display: "flex",
        flexFlow: "column",
        justifyContent:'space-between',
    },
    topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: {
            xs: "1.3rem 3.5rem",
            xl: "2rem 3.5rem"
        },
    },
    topRowAddZone: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: {
            xs: "1.3rem 3.5rem",
            xl: "2rem 3.5rem"
        },
    },
    checkpointBox: {
        padding: '2.5rem 1.5rem 1.5rem 1.5rem',
        background: '#FFFFFF',
        border: '1px solid #DADADA',
        borderRadius: '8px',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'relative',
    },
    frequencyBox: {
        background: '#FFFFFF',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
    },
    frequencyBoxSmallScreen: {
        background: '#FFFFFF',
        // width: '50%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    timeBox: {
        background: '#FFFFFF',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dateBox: {
        background: '#FFFFFF',
        width: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    textButton: {
        typography: "h4",
        color: "primary.main"
    },
    addZoneContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
};
