import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogTitle, DialogContent, Divider, IconButton, Tooltip, Typography, useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import StandardTextfield from "../../../Components/Input/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styles from "./styles";
import { useSnackbar } from "notistack";
import PillButton from "../../../Components/Buttons/PillButton"
import ResetPasswordImage from "../../../Assets/svg/resetPassword.svg";
import { deleteFromErrors } from "../../../Helpers/helpers";
import authActions from "../../Auth/store/actions";

function ChangePassword({ open, setOpen, data }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isLoading = useSelector((state) => state.alert.loading);
  const [errors, setErrors] = useState({});
  const [loginInfo, setLoginInfo] = useState({ currentPassword: "", newPassword: "", confirmNewPassword: "" });
  const { currentPassword, newPassword, confirmNewPassword } = loginInfo;
  const [visibility, setVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  });
  const smallerThanXLScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const getWidth = () => smallerThanXLScreen ? "xs" : "sm";
  const handleClose = () => setOpen(false);
  const [matchPassword, setMatchPassword] = useState(false);

  const handleChange = (name, value) => {
    // console.info(name, value, newPassword, confirmNewPassword, newPassword !== value, "");
    if (name == "confirmNewPassword" && newPassword !== value) {
      setMatchPassword(true);
    } else {
      setMatchPassword(false);
    }

    if (name == "currentPassword" && errors["newPassword"] == "New password can not be same as the old password") {
      deleteFromErrors(errors, setErrors, "newPassword");
    }
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };


  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) handleSubmit();
  };

  const handleSubmit = () => {
    const tempErrors = { ...errors };
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const lowerCase = /[a-z]/;
    const upperCase = /[A-Z]/;
    const numbers = /[0-9]/;
    if (currentPassword == newPassword) {
      tempErrors["newPassword"] = "New password can not be same as the old password";
    }

    Object.keys(loginInfo).forEach((field) => {
      if (loginInfo[field] === "") {
        tempErrors[field] = "required";
      }
      if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
      if (field == "newPassword") {
        if (loginInfo[field].length < 8) {
          tempErrors[field] = "Minimum 8 characters required";
        } else if (!specialChars.test(loginInfo[field])) {
          tempErrors[field] = "Atleast one special character required";
        } else if (!lowerCase.test(loginInfo[field])) {
          tempErrors[field] = "Atleast one lowercase character required";
        } else if (!upperCase.test(loginInfo[field])) {
          tempErrors[field] = "Atleast one uppercase character required";
        } else if (!numbers.test(loginInfo[field])) {
          tempErrors[field] = "Atleast one number required";
        }
      }
    });
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    if (newPassword !== confirmNewPassword) {
      setMatchPassword(true);
      return;
    } else {
      setMatchPassword(false);
    }

    dispatch(authActions.changePassword({
      password: loginInfo?.currentPassword,
      newPassword: loginInfo?.newPassword
    })).then((res) => {
      if (res?.data?.code == 0) {
        setOpen(false);
      }
    });
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{ padding: "2rem" }}
    >
      <DialogTitle variant='h2' sx={{ opacity: ".8" }} mt={2}>Update Your Password</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", display: "flex", padding: "1.5rem 0rem", flexDirection: smallerThanSMScreen ? "column-reverse" : "row" }}>
          <Box sx={{ width: smallerThanSMScreen ? "100%" : "50%" }}>
            <Box mt={1.5}>
              <StandardTextfield
                label="Enter Current Password"
                value={currentPassword}
                onChange={(e) => handleChange("currentPassword", e.target.value)}
                sx={{ width: "100%", marginBottom: ".5rem" }}
                endIcon={visibility.currentPassword ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
                endIconOnClick={() => setVisibility({ ...visibility, currentPassword: !visibility.currentPassword })}
                errorBool={errors?.currentPassword?.length > 0}
                type={visibility.currentPassword ? "text" : "password"}
                onKeyDown={handleEnterKeyPress}
                shrinkLabel
                helperText={errors["currentPassword"]}
              />
            </Box>
            <Box mt={1.5}>
              <StandardTextfield
                label="Enter New Password"
                value={newPassword}
                onChange={(e) => handleChange("newPassword", e.target.value)}
                sx={{ width: "100%", marginBottom: ".5rem" }}
                endIcon={visibility.newPassword ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
                endIconOnClick={() => setVisibility({ ...visibility, newPassword: !visibility.newPassword })}
                errorBool={errors?.newPassword?.length > 0}
                type={visibility.newPassword ? "text" : "password"}
                onKeyDown={handleEnterKeyPress}
                shrinkLabel
                helperText={errors["newPassword"]}
              />
            </Box>
            <Box mt={1.5}>
              <StandardTextfield
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => handleChange("confirmNewPassword", e.target.value)}
                sx={{ width: "100%", marginBottom: ".5rem" }}
                endIcon={visibility.confirmNewPassword ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
                endIconOnClick={() => setVisibility({ ...visibility, confirmNewPassword: !visibility.confirmNewPassword })}
                errorBool={errors?.confirmNewPassword?.length > 0}
                type={visibility.confirmNewPassword ? "text" : "password"}
                onKeyDown={handleEnterKeyPress}
                shrinkLabel
                helperText={errors["confirmNewPassword"]}
              />
            </Box>
            {
              matchPassword &&
                            <Box mt={-0.7}>
                              <Typography variant="caption" sx={{ color: "#FF0000", textAlign: "center", marginLeft: "1.5rem", opacity: ".7", fontSize: "10px !important", fontFamily: "Lato" }}>
                                Passwords do not match
                              </Typography>
                            </Box>
            }
          </Box>
          <Box sx={{ width: smallerThanSMScreen ? "100%" : "50%", display: "flex", justifyContent: smallerThanSMScreen ? "center" : "flex-end" }}>
            <img src={ResetPasswordImage} alt="reset_password_img" style={{ width: "90%", height: "90%", objectFit: "contain" }} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: smallerThanSMScreen ? "center" : "flex-end" }}>
          <PillButton
            onClick={() => setOpen(false)}
            text={"Cancel"}
            padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }}
            invertedColor
            sx={{ border: "none", marginRight: "1rem" }}
          />
          <PillButton
            onClick={handleSubmit}
            text={
              isLoading ?
                <CircularProgress size="2rem" sx={{ color: "white" }} /> :
                "Confirm"
            } padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default (ChangePassword);
