import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Box, Button, Divider, IconButton, Skeleton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import styles from './styles'
import PatchedTooltip from '../../Components/PatchedTooltip';

function Roles({states, functions}) {
  const {roles, setRoles, isLoading} = states;
  const {clickHandlers} = functions
  const { beginEditRole, beginDeleteRole, beginViewRole } = clickHandlers
  const {componentAccessMap} = useSelector(s => s.auth);

  return (
    isLoading ? 
    <Box sx={{display: "flex", columnGap: "2rem"}}>
      {
        ["1", "2", "3"].map(row =>
            <Skeleton key={row} sx={{width: "33%", borderRadius: "0.5rem"}} variant="rectangular" height={"19rem"}/>
        )  
      }
    </Box> :
    <Box sx={{height: "20rem"}}>
        <Box sx={{display: "flex", columnGap: "2rem", alignItems: "center", width: "100%", height: "fit-content", paddingBottom: "1rem", overflowX: "auto"}}>
        {
            roles?.map(role => 
                <Box sx={styles.roleBox} key={role.name}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography variant="h3">{role.name}</Typography>
                        <Box>
                            {/* <IconButton size="small" sx={{color: "primary.main"}}
                            onClick={() => beginEditRole(role)}
                            >
                                <EditIcon style={{height: "2rem", width: "2rem"}} />
                            </IconButton> */}
                            <PatchedTooltip
                                title={!componentAccessMap?.userManagement?.deleteRole ? "You don't have permission to use this. Contact Administrator." : ""}
                            >
                                <IconButton size="small" sx={{color: "primary.main"}}
                                    onClick={() => {
                                        if (!componentAccessMap?.userManagement?.deleteRole) return;
                                        beginDeleteRole(role)
                                    }}
                                    disabled={!componentAccessMap?.userManagement?.deleteRole}
                                >
                                    <DeleteIcon  style={{height: "1.9rem", width: "1.9rem"}}/>
                                </IconButton>
                            </PatchedTooltip>
                        </Box>
                    </Box>
                    {/* <Typography variant="subtitle1"  sx={{fontWeight: "400"}}>
                    {role.description}
                    </Typography> */}
                    <Divider />
                    <Typography variant="h4">Permissions</Typography>
                    <Box sx={{overflowY: "auto"}}>
                  
                            <Box>
                                <Box sx={{display: "flex", flexFlow: "column", rowGap: "1rem"}}>
                                {
                                    role.permissionNames?.slice(0,2).map((permTitle, index) =>  
                                    <Typography key={index} variant="subtitle1" sx={{fontWeight: "400"}}>
                                        {permTitle}
                                    </Typography>
                                    ) 
                                }
                                <Button sx={{width: "fit-content", typography: "h4", color: "primary", ml: "-0.7rem"}}
                                onClick={() => beginViewRole(role)}
                                >
                                View all permissions
                                </Button>
                                </Box>
                            </Box> 

                    </Box>
                </Box>
            )
        }
    </Box>

    </Box>
  )
}

export default Roles