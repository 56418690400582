import {TextareaAutosize, withStyles} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const styles = (theme) => ({
  input: {
    "backgroundColor": "#F2F5F7",
    "padding": "18.5px 14px",
    "font": "inherit",
    "width": "100%",
    "resize": "none",
    // 'height': '100%',
    "border": 0,
    "outline": "none",
    "& .MuiInputBase-input": {
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#666666",
    },
    "&::focus": {
      outline: "none !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "yellow !important",
  },
});

const TextArea = ({
  classes,
  placeholder,
  type,
  onChange,
  className,
  isReadOnly,
  ...props
}) => {
  return (
    <TextareaAutosize
      aria-label="textarea"
      minRows="3"
      maxRows="4"
      className={clsx(classes.input, className)}
      variant="outlined"
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      maxLength={100}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
        },
      }}
      {...props}
    />
  );
};

export default withStyles(styles)(TextArea);
