import React from 'react'
import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { tableColumns } from './info';
import CustomSwitch from '../../Switch';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import styles from './styles'

function PermissionsTable({states, mode}) {
  const {allPermissions, setAllPermissions} = states;

  const handleToggle = (isChecked, permKey) => {
    const temp = {...allPermissions};
    temp[permKey].isEnabled = isChecked;
    if(isChecked && temp[permKey].willEnable) {
        temp[permKey].willEnable.forEach(key => {
            temp[key].isEnabled = true;
        })
    }
    // disable dependees
    if (!isChecked && temp[permKey].dependees) {
        temp[permKey].dependees.forEach(dependeeKey => {
            temp[dependeeKey].isEnabled = false;
        })
    }
    setAllPermissions(temp);
  }

  return (
    <Box sx={{overflowY: "auto", flex: 1,}}>
    <Table
        size="small"
        sx={{border: "1px solid #DADADA", borderTop: "none"}}
        stickyHeader
        >
        <colgroup>
            <col width="45%" />
            <col width="45%" />
            <col width="10%" />
        </colgroup>
        <TableHead>
          <TableRow
          >
            {
                tableColumns.map(colName => 
                  <TableCell
                     sx={{
                      borderTop: "1px solid #DADADA",
                      backgroundColor: "#F4F4F4",
                      paddingTop: "0.9rem",
                      paddingBottom: "0.9rem",
                    }}
                    key={colName}
                  >
                    <Typography variant="h4">{colName}</Typography>
                  </TableCell>
                )
            }
          </TableRow>
        </TableHead>
        <TableBody>
            {
            Object.keys(allPermissions).map((permKey, index) => {
                const {title, description, tags, paddingLeft, permissions, isEnabled, dependsOn, info} = allPermissions[permKey];
                let isDisabled = false;
                if(dependsOn && Array.isArray(dependsOn)){
                    let sum = 0;
                    for (let permissionKey of dependsOn) {
                        if (!allPermissions?.[permissionKey]?.isEnabled) sum++;
                    }
                    if (sum >= 1) isDisabled = true;
                }
                else isDisabled = (dependsOn && !allPermissions?.[dependsOn]?.isEnabled)
            return (
                <TableRow
                key={permKey}
                sx={{
                    borderTop: "1px solid #DADADA",
                    opacity: isDisabled ? "0.5" : "1"
                }}
                >
                <TableCell
                 sx={{
                    paddingTop: "1.25rem",
                    paddingBottom: "1.25rem",
                    paddingLeft: paddingLeft && paddingLeft
                 }}
                >
                 <Box sx={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                  <Typography variant="subtitle1">{title}</Typography>
                  {
                    info &&
                     <Tooltip title={info}>
                         <InfoIcon style={{height: "1.5rem", width: "1.5rem"}} />
                     </Tooltip>
                  }
                 </Box>
                  <Box sx={{lineHeight: "1.25rem", mt: "0.5rem"}}>
                   <Typography variant="caption">{description}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {
                    tags?.map(tag => 
                      <Chip
                      key={tag} label={tag}
                      size="small"
                      sx={styles.tagChip}/>
                    )
                  }
                </TableCell>
                <TableCell>
                  <CustomSwitch 
                    checked={isEnabled ?? false}
                    onChange={(e) => {
                        if (isDisabled || mode === "view") return;
                        handleToggle(e.target.checked, permKey)
                    }}
                  />
                </TableCell>
              </TableRow>
            )
        })}
        </TableBody>
</Table>

</Box>
  )
}

export default PermissionsTable