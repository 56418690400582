export default {
    formControl: {
        margin: "24px"
    },
    informationControl: {
        color: "#000000"
    },
    radioControl: {
        color: "#000000",
        "&:checked": {
            color: "#000000"
        }
    }
}