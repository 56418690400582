const styles  = {
    outerBox: {
        height: "100%",
        backgroundColor: "background.first",
        borderRadius: ".8rem",
        border: "1px solid",
        borderColor: "border.main",
        overflowY: "auto",
        display: "flex",
        flexFlow: "column",
        rowGap: "2.4rem",
        padding: "2.25rem 2.5rem",
        width: "100%",
        overflowX: "hidden"
      },
    roleBox: {
      backgroundColor: "#FAFAFA",
      // boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      padding: "1rem 2rem",
      width: "33%",
      minWidth: "33%",
      height: "100%",
      display: "flex",
      flexFlow: "column",
      rowGap: "1rem",
      border: "1px solid #DADADA",
      borderRadius: "0.8rem",
  }  
}

export default styles;