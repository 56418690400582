import React, {useState, useRef} from 'react'
import { Box, CircularProgress, Collapse, Grid, IconButton, Pagination, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery, useTheme} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from '../styles'
import StandardTextfield from '../../../Input/TextField';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../Containers/Zones/Store/actions'
import usePagination from '../../../../Helpers/hooks/usePagination';
import {candidateFields} from '../index'
import { getVisitorData } from '../../../../Containers/Entries/Store/actions';
import { useSnackbar } from 'notistack';
import WarningModal from '../../../WarningModal';


function BlacklistedCandidatesTable({zoneId, isLoading, setIsLoading}) {
  const { enqueueSnackbar } = useSnackbar() 
  const theme = useTheme();
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const {visitorData} = useSelector(s => s.entries)
  const {list: blacklistedCandidates, totalCount} = useSelector(s => s.zones.blacklistedCandidates) ?? {}

  const [showInfoId, setShowInfoId] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false)
  const selectedCandidateId = useRef("");

  const handler = (seed, count, searchValue) => {
    setIsLoading((prev) => ({...prev, records: true}));
    if(zoneId) {
        dispatch(actions.fetchBlacklistedCandidates({id: zoneId, seed, count}))
    }
  };

    const {
    page,
    setPage,
    searchText,
    handleChangeSearchText,
    handleCloseSearch,
    handlePageChange,
    } = usePagination({handler})  

    const handleShowCandidateInfo = (row) => {
        if(showInfoId === row.identifier) return setShowInfoId("")
        setShowInfoId(row.identifier)
        setIsLoading(prev => ({...prev, showCandidate: true}))
        dispatch(getVisitorData(row.identifier)).then(res => {
            setIsLoading(prev => ({...prev, showCandidate: false}))
        })
    }

    const handleUnBlacklistCandidate = () => {
        const reqBody = {
            candidates: [
                selectedCandidateId.current
            ]
        }
        dispatch(actions.unBlacklistCandidate(zoneId, reqBody)).then(res => {
            setShowWarningModal(false);
            if(res) {
                handler(page - 1, 10)
                enqueueSnackbar("This candidate has been removed from the blacklist", {
                    variant: "success"
                })
            }
        })
    }

  return (
    <Box sx={{...styles.innerBox, padding: 0, paddingBottom: "1rem", justifyContent: "space-between"}}>
    {
        showWarningModal &&
        <WarningModal 
          open={showWarningModal}
          setOpen={setShowWarningModal}
          subtitle={"Remove from Blacklist"}
          caption={"Are you sure you want to remove this candidate from the blacklist?"}
          handler={handleUnBlacklistCandidate}
        />
    }
    <Box sx={{display: "flex", flexFlow: "column", rowGap: "0.6rem", flex: 1,  minHeight: 0}}>
      <Box sx={{padding: "0.4rem 1.5rem"}}>
          <StandardTextfield 
          placeholder={"Search"}
          value={searchText}
          onChange={handleChangeSearchText}
          startIcon={<SearchIcon color="primary"/>}
          sx={{width: "100%"}}
          />
      </Box>
      {
          blacklistedCandidates?.length > 0 && 
          <Box sx={{overflowY: "auto", flex: 1,}}>
              <Table
                  size="small"
                  >
                  <colgroup>
                      <col width="10%" />
                      <col width="10%" />
                      <col width="30%" />
                      <col width="30%" />
                      <col width="20%" />
                  </colgroup>
                  <TableBody>
                      {
                      totalCount !== 0 &&
                      blacklistedCandidates?.map((row, index) => {
                          const info = {
                              serialNo: index + 1,
                              rollNumber: row.identifier,
                              name: row.name,
                          }
                      return (
                      <React.Fragment> 
                          <TableRow
                          key={row.identifier}
                          sx={{
                              backgroundColor: index % 2 === 0 ? "#FEFEFE" : "#FAFAFA",
                              borderTop: "1px solid #DADADA"
                          }}
                          >
                          {
                              Object.keys(info).map(key => 
                              key !== "showInfo" &&
                              <TableCell key={key}>
                                  <Typography variant="subtitle1" sx={{fontWeight: "400"}}>{info[key]}</Typography>
                              </TableCell>
                              )
                          }
                          <TableCell />
                          <TableCell>
                          <Box sx={{display: "flex", columnGap: "1.4rem"}}>
                              <IconButton size="small" sx={{height: "2.1rem", width: "2.1rem"}}
                              onClick={() => handleShowCandidateInfo(row)}
                              >
                              {
                                  row.identifier === showInfoId ?
                                  <VisibilityOffIcon sx={styles.icon}/> :
                                  <VisibilityIcon sx={styles.icon}/>
                              }
                              </IconButton>
                              <IconButton size="small" sx={{height: "2.1rem", width: "2.1rem"}}
                              onClick={() => {
                                selectedCandidateId.current = row.identifier;
                                setShowWarningModal(true)
                              }}
                              >
                                  <DeleteIcon sx={styles.icon}/> 
                              </IconButton>
                          </Box>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={row.identifier === showInfoId} unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                              {
                                  isLoading.showCandidate ? 
                                  <Box sx={{height: "10rem", display: "grid", placeItems: "center"}}>
                                      <CircularProgress />
                                  </Box> :
                                  !visitorData ?
                                   <Box sx={{display: "grid", placeItems: "center"}}>
                                    <img 
                                        src={require("../../../../Assets/img/nodata.png")} 
                                        alt={"no-data"}
                                        style={{
                                            height: "15rem",
                                            width: "auto",
                                            objectFit: "cover"
                                        }}
                                    />
                                   </Box> :
                                  <Grid container rowSpacing={1.6} columnGap={1.25}>
                                      {Object.keys(candidateFields).map(key => 
                                          <Grid key={key} item xs={3.5}>
                                              <Box sx={{display: "flex", flexFlow: "column", rowGap: "0.5rem"}}>
                                                  <Typography variant="caption"
                                                  sx={{fontWeight: "700"}}
                                                  >
                                                  {candidateFields[key]}
                                                  </Typography>
                                                  <Typography variant="caption" sx={{wordBreak: "break-word"}}>{visitorData?.[key] || "-"}
                                                  </Typography>
                                              </Box>
                                          </Grid>
                                      )}
                                  </Grid>
                              }
                              </Box>
                          </Collapse>
                          </TableCell>
                      </TableRow>
                      </React.Fragment>  
                      )
                  })}
                  </TableBody>
          </Table>

          </Box>
      }

    </Box>
    {
        blacklistedCandidates?.length > 0 &&
        <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
        <Pagination
            color="primary"
            boundaryCount={smallerThanMDScreen ? 1 : 3}
            count={Math.ceil(totalCount / 10)}
            page={page}
            size="small"
            onChange={(event, value) => handlePageChange(value)}
        />
        </Box>
    }
  </Box>
  )
}

export default BlacklistedCandidatesTable