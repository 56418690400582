import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
    Button,
    Tooltip
} from "@mui/material";
import styles from "./styles";
import AutocompleteField from '../../Components/Input/Autocomplete';
import { useSelector, useDispatch } from "react-redux";
import actions from '../Zones/Store/actions'
import deviceActions from '../Device/store/actions'
import { useSnackbar } from 'notistack';
import { deleteFromErrors } from '../../Helpers/helpers';
import PatchedTooltip from '../../Components/PatchedTooltip';

function Devices() {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const {componentAccessMap} = useSelector(s => s.auth);
    const data = useSelector((state) => state.zones);
    const { devices, totalCount } = useSelector(s => s.devices)
    const { zonesData } = data
    const [deviceCounts, setDeviceCounts] = useState({
        'assigned': 0,
        'unassigned': 0,
        'offline': 0,
        'archived': 0
    })

    const [errors, setErrors] = useState({})
    const [zoneCheckpoints, setzoneCheckpoints] = useState([])
    const [deviceDropdown, setDevicesDropdown] = useState([])
    const [selectedDeviceLabel, setselectedDeviceLabel] = useState(null)
    const [selectedCheckpoint, setSelectedCheckpoint] = useState(null)
    const [deviceId, setdeviceId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(componentAccessMap?.dashboard?.assignDevices) {
            dispatch(actions.fetchZones({ active: true }));
            dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 }));
        }
    }, [])

    useEffect(() => {
        setzoneCheckpoints([])
        for (let i in zonesData)
        {
            let zoneName = zonesData[i].name
            let checkpoints = zonesData[i].checkpoints
            for (let j in checkpoints)
            {
                // console.info(checkpoints[j], 'ZONES DATA')
                let checkpointName = checkpoints[j].name
                let id = checkpoints[j]._id
                let label = zoneName + '-' + checkpointName
                setzoneCheckpoints((prev) => ([...prev, { label: label, id: id }]))
            }
        }
        // console.info(zoneCheckpoints, 'ZONES')
    }, [zonesData])

    useEffect(() => {
        // console.info(zoneCheckpoints, 'ZONES')
        // console.info(devices, totalCount, 'Devices')
        setDevicesDropdown([])
        setDeviceCounts({
            'assigned': 0,
            'unassigned': 0,
            'offline': 0,
            'archived': 0
        })
        if(componentAccessMap?.dashboard?.devicesStats) {
            for (let i in devices)
            {
                let status = devices[i].status
                // console.info(status, 'Status')
                setDeviceCounts((prev) => ({ ...prev, [status]: prev[status] + 1 }))
                let label = devices[i].deviceId
                let id = devices[i]._id
                if(devices[i].status == 'unassigned') setDevicesDropdown((prev) => ([...prev,{label: `${label}`, id: id}]))
            }
        }
        // console.info(deviceCounts,deviceDropdown)
    }, [devices, componentAccessMap])


    const handleSubmit = () => {
        if (!componentAccessMap?.dashboard?.assignDevices) return;
        let tempErrors = { ...errors };
        if (!selectedCheckpoint) tempErrors['checkpoint'] = "required";
        if (!deviceId) tempErrors['deviceId'] = "required"

        if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);

        const reqData = {
            checkpoint: selectedCheckpoint.id,
            device: deviceId
        }
        setIsLoading(true)
        dispatch(deviceActions.updateAssignedStatus(true, reqData)).then((response) => {
            if (response === "success")
            {
                dispatch(deviceActions.generateDevicePin(selectedDeviceLabel)).then((res) => {
                    if (res.status === "success")
                    {
                        setselectedDeviceLabel(null)
                        setSelectedCheckpoint(null)
                        enqueueSnackbar("Device assigned!", {
                            variant: "success"
                        })
                        dispatch(deviceActions.fetchDevices({ seed: 0, count: 1000 })).then((res) => {
                            setIsLoading(false)
                        });
                    }
                })
            }
        })
    }

    return (
        <div style={{display: "flex", flexFlow: "column", justifyContent: "space-between", height: "100%"}}>
           <Box>
            <Typography variant="h3">
                Devices
            </Typography>
            <Box sx={styles.deviceInfo}>
                <Box>
                    <Typography variant="h1" sx={styles.deviceValue}>
                        {totalCount}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} gutterBottom>
                        Total Devices
                    </Typography>
                </Box>
                <Box sx={styles.deviceIndividualInfo}>
                    <Typography variant="h2" sx={styles.deviceValue}>
                        {deviceCounts.assigned}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} gutterBottom>
                        Assigned
                    </Typography>
                </Box>
                <Box sx={styles.deviceIndividualInfo}>
                    <Typography variant="h2" sx={styles.deviceValue}>
                        {deviceCounts.unassigned}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} gutterBottom>
                        Available
                    </Typography>
                </Box>
                <Box sx={styles.deviceIndividualInfo}>
                    <Typography variant="h2" sx={styles.deviceValue}>
                        {deviceCounts.offline}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} gutterBottom>
                        Offline
                    </Typography>
                </Box>
            </Box>
            <Typography variant="h3" mb={3}>
                Assign Devices
            </Typography>
            <Box>
                <PatchedTooltip
                 title={!componentAccessMap?.dashboard?.assignDevices ? "You don't have permission to use this. Contact Administrator." : ""}
                >
                    <AutocompleteField
                        options={zoneCheckpoints ?? []}
                        name={"Zone"}
                        label={"Zone & Checkpoint"}
                        placeholder={"Select zone and checkpoint"}
                        variant="outlined"
                        sx={{ marginTop: "1rem", width: "100%" }}
                        shrinkLabel
                        size={"large"}
                        selectedValue={selectedCheckpoint}
                        onValueChange={(e, newValue) => {
                            // console.info(newValue)
                            setSelectedCheckpoint(newValue)
                            if (errors['checkpoint']) deleteFromErrors(errors, setErrors, 'checkpoint')
                        }}
                        errorBool={errors?.checkpoint}
                        disabled={!componentAccessMap?.dashboard?.assignDevices}
                    />
                </PatchedTooltip>
                <PatchedTooltip title={!componentAccessMap?.dashboard?.assignDevices ? "You don't have permission to use this. Contact Administrator." : ""}>
                    <AutocompleteField
                        options={deviceDropdown ?? []}
                        name={"Devices"}
                        label={"Devices"}
                        placeholder={"Select Device"}
                        variant="outlined"
                        sx={{ marginTop: "2rem", width: "100%" }}
                        shrinkLabel
                        size={"large"}
                        selectedValue={selectedDeviceLabel}
                        isOptionEqualToValue={(option, value) => {
                            return option.label === value
                        }}
                    getOptionLabel={(option) => {
                        const formattedOption = option?.label || `${option}`
                        return formattedOption ? `Device ${formattedOption.length === 1 ? `0${formattedOption}` : formattedOption}` : "-"
                        }}
                    onValueChange={(e, newValue) => {
                            console.info(newValue, "newVal")
                            // console.info(newValue)
                            setdeviceId(newValue.id)
                            setselectedDeviceLabel(newValue.label)
                            if (errors['deviceId']) deleteFromErrors(errors, setErrors, 'deviceId')
                        }}
                        errorBool={errors?.deviceId}
                        disabled={!componentAccessMap?.dashboard?.assignDevices}
                    />
                </PatchedTooltip>
            </Box>
        </Box>
            <Box sx={styles.buttonsDevices}>
              <PatchedTooltip 
                title={!componentAccessMap?.dashboard?.assignDevices ? "You don't have permission to use this. Contact Administrator." : ""}
              >
                <Button sx={{typography: "h4", color: "primary", mt: "2.1rem"}} onClick={handleSubmit}
                disabled={!componentAccessMap?.dashboard?.assignDevices}
                >
                Assign Device
                </Button>
              </PatchedTooltip>
            </Box>
        </div>
    )
}

export default Devices