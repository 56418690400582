import * as actionTypes from "./actionTypes";

const initialState = {
  notifications: [],
  unreadCount: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.FETCH_NOTIFICATIONS:
    return fetchNotifications(state, action);
  case actionTypes.SET_NOTIFICATIONS:
    return setNotifications(state, action);    
  default:
    return state;
  }
};

const fetchNotifications = (state, action) => {
  return {
    ...state,
    notifications: action.data.notifications,
    unreadCount: action.data.unreadCount
  };
};

const setNotifications = (state, action) => {
  const obj = {
      ...state,
      unreadCount: action.data.unreadCount,
  }
  if(action.data.notifications){
    obj.notifications = action.data.notifications
  }
 return obj
}

