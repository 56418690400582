/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable quote-props */
export default {
    topBox: {
      backgroundColor: "background.first",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2rem 3.5rem",
      height: "18.5%",
      border: ".1rem solid #DADADA",
      borderRadius: ".8rem",
    },
    bottomBox: {
      backgroundColor: "background.first",
      display: "flex",
      flexDirection: "column",
      rowGap: "2rem",
      padding: "2rem",
      height: (theme) => theme.breakpoints.down("sm") ? "100%" : "80%",
      border: ".1rem solid #DADADA",
      borderRadius: ".8rem",
      overflowY: "scroll"
    },
    detailHeading: {
      fontWeight: "500",
      width: "170px",
      textAlign: "end"
    },
    detailItemBox: {
      display: "flex",
      marginTop: "20px"
    },
    disabledDetail: {
      border: "none",
      marginLeft: "5rem",
      backgroundColor: "white"
    },
    passwordChange: {
      color: "#013366",
      opacity: "0.9",
      fontSize: "1.2rem",
      fontWeight: "700",
      padding: 0,
      marginTop: "-5px"
    },
    imageBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
    addMoreChip: {
      backgroundColor: "background.first",
      borderRadius: ".8rem",
      width: "fit-content",
      border: "1px dashed",
      borderColor: "text.heading",
      cursor: "pointer"
    },
    outerBox: {
      border: "1px solid",
     borderColor: "border.main",
      borderRadius: "0.8rem",
     backgroundColor: "#FAFAFA",
     display: "flex",
     flexFlow: "column",
  },
  innerBox: {
  height: "fit-content",
  //  padding: "1.25rem 1.5rem",
   display: "flex",
   flexFlow: "column",
   justifyContent: "space-between",
   rowGap: "1.1rem",
   overflowY: "hidden",
   transition: "height 0.6s ease-out"
  },
  accordionTopRow: {
      display: "flex",
   justifyContent: "space-between",
   alignItems: "center",
   width: "100%"
  },
  tableHeaderCell: {
    backgroundColor:"#f5f5f5",
    padding: "1.7rem 1.7rem",
    border: "none",
    borderTop: "1px solid #DADADA"
},
  loaderWrapper: {
    height: "25vh",
   width: "100%",
   display: "grid",
   placeItems: "center"
  }
  };
  