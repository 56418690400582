const styles = {
    dialogContent: {
        padding: "1.5rem 1.5rem",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2.75rem",
        width: "53rem",
        rowGap: "1.2rem",
        minHeight: "65vh"
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem",
        paddingTop: "0.8rem",
        paddingLeft: "1.5rem",
        marginBottom: "0.5rem"
      },
      outerBox: {
        border: "1px solid",
       borderColor: "border.main",
        borderRadius: "0.8rem",
       backgroundColor: "#FAFAFA",
       display: "flex",
       flexFlow: "column"
    },
    innerBox: {
    height: "45vh",
     padding: "1.25rem 1.5rem",
     display: "flex",
     flexFlow: "column",
     justifyContent: "space-between",
     rowGap: "1.1rem",
     overflowY: "hidden"
    },
    accordionTopRow: {
        display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
     width: "100%"
    },
    buttonsRow: {
      display: "flex",
     alignItems: "center",
     justifyContent: "flex-end",
     columnGap: "1rem"
    },
    candidateFieldsBox: {
      flex: 1,
       borderRadius: "0.8rem",
     border: "1px solid",
     borderColor: "border.main",
     padding: "1.2rem 1.3rem"
    },
    candidateImg: {
      height: "4.3rem",
      width: "4.3rem",
      borderRadius: "1rem",
      objectFit: "cover"
  },
    icon: {
      height: "1.7rem",
     width: "1.7rem",
     color: "text.heading"
    }
};

export default styles;