/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-duplicate-props */
// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import { Autocomplete, CircularProgress, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function Search({ label, updateSelectedKey,
    apiTrigger, getName, initialText = "", placeholder, value, sx = {}, disabled }) {
    const [searchText, setSearchText] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [timeoutInstance, setTimeoutInstance] = React.useState({ cancel: () => { } });
    const [seed, setSeed] = React.useState(0);
    const [total, setTotal] = React.useState(0);

    const callApi = async (searchThis, newSeedValue, actualOptions = []) => {
        if (timeoutInstance) {
            clearTimeout(timeoutInstance);
        }
        setTimeoutInstance(setTimeout(async () => {
            setLoading(true);
            const response = await apiTrigger(searchThis, newSeedValue);
            setOptions(["", ...actualOptions, ...response?.list]);
            setTotal(response.total);
            setSeed(newSeedValue);
            setLoading(false);
        }, 400));
    };

    React.useEffect(() => {
      if(!disabled) {
          callApi(searchText, 0, []);
      } 
    }, []);

    React.useEffect(() => {
        if (value === "")
            setSearchText('');
    }, [value]);

    return (
        <Autocomplete
            disabled={disabled}
            value={value}
            sx={{
                "zIndex": 999999999,
                "margin": "10px",
                width: "100%",
                "& .MuiInput-root": {
                    paddingLeft: "0.5em",
                },
                "& .MuiOutlinedInput-root": {
                    "& fieldset > legend > span": {
                        fontSize: 'var(--body-font-size-small)',
                        padding: '1px !important'
                    }
                },
                ...sx
            }}
            selectOnFocus
            id="select-autocomplete"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(option, value) => {
                // console.log("option, value", option, value);
                updateSelectedKey(value);
                setOpen(false);
                // handleClose();
                return option === value;
            }}
            getOptionLabel={(option) => getName(option).primary}
            options={options}
            loading={loading && open}
            loadingText={<p style={{}}>Loading...</p>}
            noOptionsText={<p style={{}}>No options</p>}
            ListboxProps={{
                sx: {
                    //   minHeight: 200,
                    "maxHeight": 200,
                    "textAlign": "left",
                    "& .MuiAutocomplete-popupIndicatorOpen": {
                        "zIndex": "99999 !important",
                        "textAlign": "left"
                    }
                },
                onScroll: async (event) => {
                    const listboxNode = event.currentTarget;
                    // console.log("====aaaa====", listboxNode.scrollTop, listboxNode.clientHeight, listboxNode.scrollHeight, parseInt(listboxNode.scrollTop + listboxNode.clientHeight));
                    if (parseInt(listboxNode.scrollTop + listboxNode.clientHeight) <= listboxNode.scrollHeight) {
                        if (options.length < total) {
                            callApi(searchText, seed + 5, options);
                        }
                    }
                }
            }}
            renderOption={(props, option, { inputValue }) => {
                if(option === "")
                return (
              <MenuItem key={option} value="" disabled sx={{typography: "h4", fontWeight: "400"}}>
              Select
              </MenuItem> )
                let matches; let parts;
                const name = getName(option);
                if (name.primary) {
                    matches = match(name.primary, inputValue, { insideWords: true });
                    parts = parse(name.primary, matches);
                }
                // console.log("parts", parts, name, option, inputValue);
                return (
                    <ListItemText
                        {...props}
                        style={{
                            "display": "flex",
                            "flexDirection": "column",
                            "alignItems": "flex-start",
                            // "alignItems": "center",
                            "overflowWrap": "anywhere",
                            "textAlign": "left",
                            padding: "0.5rem 1.3rem"
                        }}
                        primary={parts?.map((part, index) => (
                            <Typography
                                key={`${part.text}-${index}`}
                                variant="h4"
                                sx={{
                                    display: "inline",
                                    fontWeight: part.highlight ? 700 : 400,
                                    textAlign: "left",
                                }}
                            >
                                {part.text}
                            </Typography>
                        ))}
                        secondary={name.secondary}
                    />
                );
            }}
            renderInput={(params) => (
                <TextField
                    // autoFocus
                    {...params}
                    style={{
                        width: "webkit-fill-available",
                        // fontSize: "1.32rem",
                        // color: "#68A691",
                        // backgroundColor: "inherit",
                        // height: "1.9rem",
                    }}
                    placeholder={placeholder}
                    label={label}
                    variant="outlined"
                    value={searchText}
                    onFocus={(e) => {
                        callApi(e.target.value, 0, []);
                    }}
                    onChange={(e) => {
                        callApi(e.target.value, 0, []);
                        setSearchText(e.target.value);
                    }}
                    //   inputProps={{
                    //     style: {
                    //       padding: 5
                    //     }
                    //   }}
                    InputLabelProps={{
                        shrink: true,
                        sx: {
                            fontSize: 'var(--body-font-size-small)',
                            margin: 0,
                            fontWeight: 400,
                            paddingTop: '1px',
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            fontSize: 'var(--body-font-size-small)',
                        },
                        endAdornment: (
                            <>
                                {(loading && open) ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />

    );
}
