import axios from "axios";
import API from "../../../../api";
import { baseURLs } from "../../../../Axios";
import * as actionTypes from "./actionTypes";
import { getAccessToken } from "../../../../Utility/tokenUtils";
import { appName } from "../../../../Config/appConfig";

const fetchNotifications = (paramsObj = {}) => {


  const { seed, count, doNotDispatch, read, markAsRead } = paramsObj

  // console.info(paramsObj,'readInfo')
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    let queryString = ''
    if(seed !== undefined && count !== undefined) queryString += `&seed=${seed}&count=${count}`
    if(read == 1) queryString += `&read=1`
    else if(read == -1) queryString += `&read=-1`
    const response = await axios.get(
      `${baseURLs.events}${API.notifications.notifications}?${queryString}`,
      { 
        headers: {
            Authorization: `${token}`,
            app:appName
        },
     },
    );
    if (response?.data?.code === 0)
    {
      const events = response.data.data.list
      const unreadCount = response.data.data.unreadCount
      if(!doNotDispatch){
        dispatch({
          type: actionTypes.FETCH_NOTIFICATIONS,
          data: {
            notifications: events,
            unreadCount
          },
        });
      }
      else {
        if(unreadCount > 0 && markAsRead){
          const unreadEventIds = events.filter(record => record.read === false).map(record => record._id)
          if(unreadEventIds.length > 0) {
            dispatch(markNotificationsAsRead(unreadEventIds))
          }
        }
      }
      return response.data;
    }
  };
}


const markNotificationsAsRead = (eventIds) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch, getState) => {
    const unreadCount = getState().notifications.unreadCount
    const notifications = getState().notifications.notifications
    const response = await axios.put(
      `${baseURLs.events}${API.notifications.notifications}/read`,
      {
        eventIds
      },
      { 
        headers: {
            Authorization: `${token}`,
        },
     },
    );
    if (response?.data?.code === 0) {
      const actionObj = {
        type: actionTypes.SET_NOTIFICATIONS,
        data: {
          unreadCount: unreadCount >= eventIds.length ? unreadCount - eventIds.length : 0
        }
      }
      const index = notifications.findIndex(notification => eventIds.includes(notification._id))
      if(index >= 0){
        const updatedNotifications = notifications.map((notification) => {
          return {
            ...notification,
            read: eventIds.includes(notification._id) ? true : notification.read
          }
        })
        actionObj.data.notifications = updatedNotifications
      }
        dispatch(actionObj);
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export {
  fetchNotifications,
  markNotificationsAsRead
}


