export const styles={
    iconButton: {
    height: "2.8rem",
    width: "2.8rem",
    color: "#888888"
    },
    statusDot: {
        height: ".82rem",
        width: ".82rem",
        backgroundColor: "red",
        borderRadius: "50%"
      },
    icon: {
        height: "1.75rem",
        width: "1.75rem", 
     },
     pinWrapper: {
        display: "flex",
        alignItems: "center",
        columnGap: "1rem",
     },
     pinLeft: {
        display: "flex",
        columnGap: ".7rem",
        width: "5.5rem"
     }
}