import { styles } from "./styles";
import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
const Battery = ({value,status})=>{
    const batteryColor = value < 15 ? "error.regular" : "#AAAAAA";
    return(
        <div style={styles.batteryBox}>
            <Typography
            variant="h4"
            sx={{ fontWeight: value < 15 ? "700" : "400", flex: "1", maxWidth: "2.8rem", textAlign: "right", marginRight:'10px', color: value < 15 ? "error.regular" : "text.heading"}}
            >
                {(value == undefined || status == 'Unassigned') ?  "-" : `${parseInt(value)}%`}
            </Typography>
            {value !== undefined && status!== 'Unassigned' &&
                <div style={styles.batteryWrapper}>
                    <Box sx={{...styles.batteryTop, backgroundColor: batteryColor, }}/>
                    <Box sx={{...styles.batteryOuter, borderColor: batteryColor}}>
                    <Box
                    sx={{
                        ...styles.batteryInner,
                        backgroundColor: batteryColor,
                        height: `${value}%`
                    }}/>  
                    </Box>
                </div>
            }
        </div>
    )
}

export default Battery;