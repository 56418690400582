import {useState, useEffect, useCallback} from 'react'
import {
    Box,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import dashboard from '../../Assets/svg/dashboard.svg';
import DropdownTextfield from "../../Components/Input/Dropdown/index-new";
import Devices from './devices';
import Notifications from './notifications';
import PinGenerator from './pinGenerator';
import styles from "./styles";
import ZonesStats from './stats/zones';

function Dashboard() {
    const theme = useTheme();
    const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={styles.outerBox}>
            <Box
                sx={{
                    display: "flex",
                    columnGap: "1.75vmin",
                    flexFlow: smallerThanSMScreen ? "column" : "row",
                    rowGap: "1.75vmin",
                    height: "100%",
                }}
            >
                <Box sx={styles.leftMiddleBox}>
                    <Typography variant="h2" color="text.heading" sx={{
                        backgroundColor: "background.first",
                        width: "100%",
                        padding: "2.5rem",
                        border: ".1rem solid #DADADA",
                        borderRadius: ".8rem",
                    }}>
                        Dashboard
                    </Typography>
                    <ZonesStats />
                </Box>
                <Box sx={styles.rightMiddleBox}>
                    <Box sx={styles.notificationContainer} mb={1.5}>
                        <Notifications />
                    </Box>
                    <Box sx={styles.devicesContainer} mb={1.5}>
                        <Devices />
                    </Box>
                    {/* <Box sx={styles.devicesContainer}>
                        <PinGenerator />
                    </Box> */}
                </Box>
            </Box>
        </Box>
    )
}

export default Dashboard