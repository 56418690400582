const styles  = {
    dialogContent: {
        padding: "1.5rem 2.75rem",
        display: "flex",
        flexDirection: "column",
        rowGap: "3.25rem",
        paddingBottom: "2.75rem",
        width: "40rem",
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "background.third",
        alignItems: "center",
        padding: ".6rem",
        paddingLeft: "1.5rem",
        marginBottom: "1rem"
      },
      midBox: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        rowGap: "2rem",
      },
      deviceIdBox: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "flex-start",
        width: "100%",
        rowGap: ".8rem"
      },
      buttons: {
       display: "flex",
       columnGap: "2rem",
       justifyContent: "flex-end",
       width: "100%",
       marginTop: "3rem"
      },
      loaderBox: {
        display: "grid",
        placeItems: "center",
        height: "20rem"
      }
}

export default styles;