import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PillButton from "../../../Components/Buttons/PillButton";
import CrudListMultiSelectWithChip from "../../../Components/Input/CrudListMultiSelectWithChip";
import DateField from "../../../Components/Input/DateTimeField";
import RadioButton from "../../../Components/Input/RadioButton";
import StandardTextfield from "../../../Components/Input/TextField/index";
import TimeField from "../../../Components/Input/TimeField";
import { deleteFromErrors, scrollIntoViewHelper, getTimeInfo } from "../../../Helpers/helpers";
import actions from '../Store/actions';
import styles from "./styles";
import MultipleSelectChip from '../../../Components/Input/Multiselect';
import { getVisitsConfigs } from "../../Entries/Store/actions";
import CloseIcon from '@mui/icons-material/Close';

function Zones({ ...props }) {

    const dispatch = useDispatch()
    const errorArray = []
    const { enqueueSnackbar } = useSnackbar()
    const [purposeList, setPurposeList] = useState([])
    const [purposeListWithId, setPurposeListWithId] = useState([])
    const [selectedNames, setSelectedNames] = useState([]);

    const { page, data } = props.location.state
    const theme = useTheme();
    const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // console.info(data,"DATA INFO")

    const history = useHistory();
    const handleBackClick = () => {
        history.goBack();
    };
    const [errors, setErrors] = useState({});
    const [frequency, setFrequency] = React.useState(page == 'edit' ? data?.accessibility?.dates?.frequency ?? "daily" : "daily");
    const [initial, setInitial] = React.useState(0)

    const purposeAxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_ACCESS_CONTROL_BASE_URL
    });

    const groupItems = [
        {
            name: "daily",
            label: "Daily (All Days)",
            information: "",
            showInfo: false,
            disabled: false
        },
        {
            name: "weekdays",
            label: "Weekdays (Mon-Fri)",
            information: "",
            showInfo: false,
            disabled: false
        },
        {
            name: "weekends",
            label: "Weekends (Sat-Sun)",
            information: "",
            showInfo: false,
            disabled: false
        },
        {
            name: "custom",
            label: "Custom (Date Range)",
            information: "",
            showInfo: false,
            disabled: false
        }
    ];
    const [zone, setzone] = useState(
        {
            description: page == 'edit' ? data?.description : "",
            accessibility: {
                time: {
                    from: page == 'edit' ? new Date(`01/01/1970 ${data?.accessibility?.time?.from}`) ?? "" : "",
                    to: page == 'edit' ? new Date(`01/01/1970 ${data?.accessibility?.time?.to}`) ?? "" : "",
                },
                dates: {
                    frequency: page == 'edit' ? data?.accessibility?.dates?.frequency : "daily",
                    from: page == 'edit' ? data?.accessibility?.dates?.from ? new Date(data?.accessibility?.dates?.from) : '' : '',
                    to: page == 'edit' ? data?.accessibility?.dates?.to ? new Date(data?.accessibility?.dates?.to) : '' : '',
                }
            },
            checkpoints: page == 'edit' ? data?.checkpoints : [
                {
                    name: "",
                    description: ""
                }
            ],
            "visitPurposes": page === 'edit' ? data?.visitPurposes?.map((x) => x.id) : [],
            name: page == 'edit' ? data?.name : "",
        });

    // console.info(zone, new Date(data?.accessibility?.dates?.from) ,"DATA")
    const [customDate, setCustomDate] = useState({
        from: '',
        to: ''
    })

    const { description, accessibility, checkpoints, name } = zone;

    const addCheckpoint = () => {
        // if (arr[arr.length-1] == undefined) setArr([0])
        // else setArr((prev) => [...prev,arr[arr.length-1]+1])
        const tempCheckpoints = [...checkpoints];
        tempCheckpoints.push({ name: "", description: "" });
        setzone((prev) => ({ ...prev, checkpoints: tempCheckpoints }));
    }

    const removeCheckpoint = (i) => {
        // setArr(arr.filter(i => i !== value))
        if (checkpoints.length >= 1)
        {
            let tempCheckpoints = checkpoints.filter((val, index) => index !== i)
            // console.info(tempCheckpoints)
            setzone((prev) => ({ ...prev, checkpoints: tempCheckpoints }))
        } else
        {
            enqueueSnackbar("Atleast one checkpoint required", { variant: "error" });
        }
    }

    const handleCheckpointChange = (value, { index, key }) => {

        const tempCheckpoints = [...checkpoints];
        tempCheckpoints[index][key] = value

        if (errors?.checkpoints?.[index]?.[key])
        {
            const tempErrors = { ...errors };
            // if (Object.keys(tempErrors.attributes).length === 1) delete tempErrors.attributes;
            // else 
            delete tempErrors.checkpoints?.[index]?.[key];
            if (Object.keys(tempErrors?.checkpoints?.[index]?.length === 0))
            {
                delete tempErrors?.checkpoints?.[index]
            }
            if (Object.keys(tempErrors?.checkpoints)?.length === 0)
            {
                delete tempErrors?.checkpoints
            }
            setErrors(tempErrors);
        }
        setzone((prev) => ({ ...prev, checkpoints: tempCheckpoints }))
    }

    const handleChange = (value, name) => {
        // console.info(value)
        if (name == 'from')
        {
            setzone((prev) => ({
                ...prev, accessibility: {
                    ...prev.accessibility,
                    time: {
                        ...prev.accessibility.time,
                        from: value
                    }
                }
            }))
        } else if (name == 'to')
        {
            setzone((prev) => ({
                ...prev, accessibility: {
                    ...prev.accessibility,
                    time: {
                        ...prev.accessibility.time,
                        to: value
                    }
                }
            }))
        } else if (name == 'frequency')
        {
            if (initial !== 0)
            {
                setzone((prev) => ({
                    ...prev, accessibility: {
                        time: {
                            from: "",
                            to: ""
                        },
                        dates: {
                            frequency: value,
                            from: "",
                            to: ""
                        }
                    }
                }))
                setCustomDate({
                    from: "",
                    to: ""
                })
            }
            setInitial(1)
            deleteFromErrors(errors, setErrors, ['fromDate', 'toDate', 'from', 'to'], true);
        } else if (name == 'fromDate')
        {
            setCustomDate({ 
                ...customDate,
                from: value,
                to:''
            })
            setzone((prev) => ({
                ...prev, accessibility: {
                    ...prev.accessibility,
                    time: {
                        ...prev.accessibility.time,
                        from: value
                    },
                    dates: {
                        ...prev.accessibility.dates,
                        from: value,
                        to:''
                    }
                }
            }))
            deleteFromErrors(errors, setErrors, ['fromDate', 'from'], true);
        } else if (name == 'toDate')
        {
            setCustomDate({ ...customDate, to: value })
            setzone((prev) => ({
                ...prev, accessibility: {
                    ...prev.accessibility,
                    time: {
                        ...prev.accessibility.time,
                        to: value
                    },
                    dates: {
                        ...prev.accessibility.dates,
                        to: value
                    }
                }
            }))
            deleteFromErrors(errors, setErrors, ['toDate', 'to'], true);
        }
        else
        {
            setzone((prev) => ({ ...prev, [name]: value }));
        }
        if (errors?.[name]?.length > 0) deleteFromErrors(errors, setErrors, name);
    };

    const handleSave = () => {

        
        const tempErrors = { ...errors };
        console.info(zone, "ZONE-DATA")
        console.info(errors, zone, 'DATA')

        if (!zone['name'])
        {
            errorArray.push('name')
            tempErrors['name'] = "required";
        }
        if (zone?.visitPurposes?.length == 0)
        {
            errorArray.push('visitPurposes')
            tempErrors['visitPurposes'] = "required";
        }
        const fieldsData = [
            "from",
            "to"
        ];
        [...fieldsData].forEach((key) => {
            if (!accessibility.time[key])
            {
                if(frequency !== 'custom'){
                errorArray.push(key)
                }
                tempErrors[key] = "required";
            }
        }
        );
        [...fieldsData].forEach((key) => {
            if (frequency == 'custom')
            {
                errorArray.push(key+'Date')
                if (!accessibility.dates[key]) tempErrors[key + 'Date'] = "required";
            }
        }
        );

        checkpoints.forEach((checkpoint, i) => {
            const fieldsData = [
                "name",
            ];
            [...fieldsData].forEach((key) => {
                if (!checkpoint[key])
                {
                    if (!tempErrors.checkpoints) tempErrors.checkpoints = {};
                    tempErrors['checkpoints'][i] = { ...tempErrors['checkpoints'][i], [key]: "required" };
                    errorArray.push(`checkpoint${i}`)
                }
            })
        });

        console.info(tempErrors,'Errors')
        if (Object.keys(tempErrors).length > 0)
        {
            scrollIntoViewHelper(errorArray)
            return setErrors(tempErrors);
        }

        const actionToCall = page === "edit" ? actions.updateZone : actions.addZone
        let params = [{ ...zone }]
        if (page === "edit") params = [{ ...zone }, data._id]
        dispatch(actionToCall(...params)).then((res) => {
            if (res?.data?.code === 0)
            {
                enqueueSnackbar(`Zone ${page === "edit" ? "updated" : "created"}`, { variant: "success" });
                history.replace("/zones")
            }
        })
    }

    useEffect(() => {
        handleChange(frequency, 'frequency')
    }, [frequency])



    useEffect(() => {
        let arr = [];
        for (let i = 0; i < purposeListWithId?.length; i++)
        {
            // console.info(selectedNames, purposeListWithId[i], "DATA")
            if (selectedNames?.includes(purposeListWithId[i]?.name))
            {
                arr.push(purposeListWithId[i]?._id)
            }
        }
        console.info(arr)
        handleChange(arr, 'visitPurposes')
    }, [selectedNames])

    useEffect(() => {
        if (page === "edit")
        {
            // setFrequency(data.accessibility?.dates?.frequency)
            if (data.accessibility?.dates?.frequency == 'custom')
            {
                setCustomDate({
                    from: data?.accessibility?.dates?.from,
                    to: data?.accessibility?.dates?.to
                })
            }
        }
        dispatch(getVisitsConfigs("visitPurpose")).then((res) => {
            let arr;
            arr = res?.list.map((i) => i?.texts?.[0]?.text)
            setPurposeList(arr)
            setPurposeListWithId(res?.list?.map(item => {
                const {_id, configType} = item
                const name = item?.texts?.[0]?.text;
                return {name, _id, configType}
            }))
            setSelectedNames(data?.visitPurposes?.map((x) => x.name))
        })
    }, [])

    console.log('zone', zone)

    return (
        <Box sx={styles.outerBox}>
            <Box>
                <Box sx={styles.topRowAddZone}>
                    <IconButton
                        sx={styles.backIconButton}
                        onClick={handleBackClick}
                    >
                        <ArrowBackIcon sx={styles.backIcon} />
                    </IconButton>
                    <Typography variant="h2" color="text.heading" ml={1}>{page == 'edit' ? 'Edit Zone' : 'Create New Zone'}</Typography>
                </Box>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{
                        position: 'absolute',
                        top: '1.5rem',
                        left: {
                            xs: '2rem',
                            md: '6rem',
                            lg: '10rem'
                        },
                    }}>
                        <Typography variant="h2" color="text.darkBlue">{page == 'edit' ? data?.name : 'Zone'}</Typography>
                    </Box>

                    <Box sx={styles.addZoneContainer}>
                        <StandardTextfield
                            id='description'
                            name='description'
                            label={'Zone Tag'}
                            value={name}
                            onChange={(e) => handleChange(e.target.value, 'name')}
                            sx={{ width: '50%' }}
                            errorBool={errors?.name?.length > 0}
                            // maxlength={CHARACTER_LIMIT_SCHEMA}
                            // charCount={`${schemaName.length}/${CHARACTER_LIMIT_SCHEMA}`}
                            fullWidth
                            helperText={errors?.name}
                        />
                        <Box id='visitPurposes' sx={{ width: '50%' }} mt={2}>
                            <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>Set Purposes</Typography>
                        </Box>
                        {/* <CrudListMultiSelectWithChip
                            sx={{ width: '50%' }}
                            title={"Purpose"}
                            axiosInstance={purposeAxiosInstance}
                            apiRoutes={{ get: '/purposes', put: '/purposes', post: '/purposes', delete: '/purposes' }}
                            postBody={(x) => ({ name: x })}
                            menuItemFormat={(x) => x.name}
                            selectedItems={zone?.visitPurposes ?? []}
                            setSelectedItems={(newValue) => handleChange(newValue, 'visitPurposes')}
                            errorBool={errors?.visitPurposes?.length > 0}
                        /> */}
                        <Box sx={{ margin: '1em 0', width: "50%" }}>
                            <MultipleSelectChip names={purposeList ?? []} labelName={'Purpose'} selectedNames={selectedNames ?? []} setSelectedNames={setSelectedNames} errorBool={errors?.visitPurposes?.length > 0} />
                        </Box>
                        <Box sx={smallerThanSMScreen ? styles.frequencyBoxSmallScreen : styles.frequencyBox} mt={1}>
                            <Box sx={{ width: smallerThanSMScreen ? '50%' : '30%' }}>
                                <RadioButton
                                    groupItems={groupItems}
                                    group="Set Allowed Days"
                                    checkedDefault="daily"
                                    value={frequency}
                                    setValue={setFrequency}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%' }} mt={2}>
                            <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>Set Zone Timings</Typography>
                        </Box>
                        <Box sx={styles.timeBox} mt={1.4}>
                            {frequency == 'custom' ?
                                <>
                                    <DateField
                                        id='fromDate'
                                        label={"Start Date"}
                                        value={customDate?.from}
                                        handleChange={(val) => handleChange(val, 'fromDate')}
                                        sx={{ width: '48%' }}
                                        error={errors?.fromDate?.length > 0}
                                        minDateTime={new Date()}
                                        // maxDateTime={customDate?.to ?? customDate?.to}
                                        endIcon={customDate?.from && customDate?.from !== null && <CloseIcon />}
                                        showIconButton
                                        endIconOnClick={() => handleChange(null, 'fromDate')}
                                    />
                                    <DateField
                                        id='toDate'
                                        label={"End Date"}
                                        value={customDate?.to}
                                        handleChange={(val) => handleChange(val, 'toDate')}
                                        sx={{ width: '48%' }}
                                        error={errors?.toDate?.length > 0}
                                        disabled={!customDate?.from}
                                        minDateTime={(customDate?.from)}
                                        endIcon={customDate?.to && customDate?.to !== null && <CloseIcon />}
                                        showIconButton
                                        endIconOnClick={() => handleChange(null, 'toDate')}
                                    />
                                </>
                                :
                                <>
                                    <TimeField
                                        id='from'
                                        label={"Start Time"}
                                        value={accessibility.time.from}
                                        handleChange={(val) => handleChange(val, 'from')}
                                        sx={{ width: '48%' }}
                                        error={errors?.from?.length > 0}
                                        maxTime={accessibility.time.to ?? getTimeInfo(accessibility.time.to)}
                                        shrinkLabel
                                        endIcon={accessibility?.time?.from && accessibility?.time?.from !== null && <CloseIcon />}
                                        showIconButton
                                        endIconOnClick={() => handleChange(null, 'from')}
                                    />
                                    <TimeField
                                        id='to'
                                        label={"End Time"}
                                        value={accessibility.time.to}
                                        handleChange={(val) => handleChange(val, 'to')}
                                        sx={{ width: '48%' }}
                                        error={errors?.to?.length > 0}
                                        helperText={errors?.to}
                                        disabled={!accessibility.time.from}
                                        minTime={getTimeInfo(accessibility.time.from)}
                                        shrinkLabel
                                        endIcon={accessibility?.time?.to && accessibility?.time?.to !== null && <CloseIcon />}
                                        showIconButton
                                        endIconOnClick={() => handleChange(null, 'to')}
                                    />
                                </>
                            }
                        </Box>
                        {checkpoints.map((value, index) =>
                            <Box id={`checkpoint${index}`}
                            sx={{...styles.checkpointBox,
                                 height: data?.totalEntriesSoFar === 0 ? "24rem" : "20rem"
                                }}
                            mt={1.5} key={index}>
                                <Typography variant="h4" color="text.darkBlue" sx={{
                                    position: 'absolute',
                                    top: '1.5rem',
                                    left: '2rem',
                                }}>Checkpoint {index + 1}</Typography>
                                <StandardTextfield
                                    label={'Checkpoint Name'}
                                    value={value.name}
                                    sx={{ width: '50%' }}
                                    onChange={(e) => handleCheckpointChange(e.target.value, { index: index, key: 'name' })}
                                    errorBool={errors?.checkpoints?.[index]?.['name']?.length > 0}
                                />
                                <StandardTextfield
                                    label={'Checkpoint Details (optional tag)'}
                                    value={value.description}
                                    sx={{ width: '50%' }}
                                    onChange={(e) => handleCheckpointChange(e.target.value, { index: index, key: 'description' })}
                                    errorBool={errors?.checkpoints?.[index]?.['description']?.length > 0}
                                />
                                {
                                data?.totalEntriesSoFar === 0 && 
                                <Button sx={styles.textButton} onClick={() => removeCheckpoint(index)}>
                                  Remove
                                </Button>
                                }
                            </Box>)
                        }
                        <Box sx={{...styles.dateBox, ml: "15.6vw"}} mt={1.5}>
                            <Button sx={styles.textButton} startIcon={<AddIcon />} onClick={addCheckpoint}>Add Checkpoint</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: '4rem', display: 'flex', justifyContent: 'flex-end' }}>
                <Box mr={2} onClick={()=>{ history.goBack() }}>
                    <Button sx={styles.textButton} >Cancel</Button>
                </Box>
                <PillButton text={"Save"} onClick={() => handleSave()} />
            </Box>
        </Box>
    );
}

export default Zones;